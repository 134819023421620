@import "~nutrien-style-guide/src/lib/colors/_colors.scss";
@import "../../../styles/index.scss";

// !WRAPPERS-------------------------------------------------------------

.logistics__header {
  color: $nutrien-gray-3;
}
.logistics__address {
  color: $nutrien-dark-grey-2;
  display: inline-block;
  font-size: 0.625rem;
  font-weight: 600;
}

// !TEXT-------------------------------------------------------------
.logistics__headline {
  @include cart-sub-headline;
  color: #000;
}

.logistics__sub-header {
  font-weight: 600;
}
