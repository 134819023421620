.notifications-page {
  padding-bottom: 2em;
}

.notifications-page .notifications-list__error {
  margin: 5em auto;
}

.notifications-page__selection-status-bar {
  width: 100%;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  margin-bottom: 24px;
  font-size: 14px;

  &__text {
    padding: 6px 8px;
    line-height: 24.5px;

    span {
      font-weight: bold;
    }
  }
}

.notifications-page__title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  margin-top: -16px;
}

.notifications-page__preferences-selection-container {
  display: flex;
  align-items: center;
  margin-left: 32px;

  .notifications-page__preferences-checkbox {
    .MuiCheckbox-indeterminate {
      color: #70c200;
    }
  }
}

.notifications-page__preferences-selection-options {
  button {
    padding-left: 16px;
    color: #50a200;
    font-weight: 600;
    cursor: pointer;
  }
}

.notifications-page__preferences-link-container {
  margin-left: auto;

  .notifications-page__preferences-link {
    display: flex;
  }
}

@media screen and (max-width: '600px') {
  .notifications-page__title-container {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .notifications-page__preferences-selection-container {
    margin-left: 16px;
  }
  .notifications-page__preferences-link-container {
    align-self: flex-end;
  }
  .notifications-page__selection-status-bar {
    padding: 8px 0px;
    height: fit-content;
    flex-direction: column;
  }
}
