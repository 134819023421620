.invoices-table {
  &__no-invoices {
    text-align: center;
  }
  &__row-mobile {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  &__list-item-link {
    font-size: 16px;
  }
  &__list-item-mobile {
    flex: 1 0 31%;

    @media screen and (max-width: 480px) {
      flex: 1 0 44%;
    }
    @media screen and (max-width: 330px) {
      flex: 1 0 100%;
    }
  }
  &__mobile-heading {
    display: block;
    font-weight: 600;
  }
}
.invoices-list {
  &__select-all-checkbox-cell {
    padding-top: 40px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
