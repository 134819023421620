@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

.profile-component__top-content {
  padding: 16px 16px 0px;
}

.profile-component__top-hr {
  margin-top: 14px;
}

.profile-component__padded-content {
  padding: 0px 16px;
}

.profile-component__user-name-wrapper {
  font-size: 30px;
  margin: 30px 0px;
}

.profile-component__profile-tabs {
  margin-bottom: 22px;
  border-bottom: 1px solid #ccc;
}
