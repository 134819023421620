@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

*:focus {
  outline: none;
}

input::-ms-clear {
  display: none;
}

body,
html {
  overflow: hidden !important;
  overflow-y: scroll !important;
  background-color: $nutrien-background;
}

.App {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  height: 100%;
}

.content-container {
  flex: 1 0 auto;
  display: block;
  float: none;
  margin: auto;
  margin-top: 0;
  max-width: 1280px;
  padding: 16px;
  width: 100%;
}

.page-container,
.app__container,
.app-router-container {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  height: calc(100% - 60px);
}

table {
  border-collapse: collapse;
}

.app__container {
  margin-top: 7em;
  @media print {
    margin-top: 0;
  }
}

.app-router-container {
  margin-top: 0;
}

.app-external-router-container {
  margin-top: -3em;
  height: calc(100% + 60px);
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

@media only screen and (min-width: 1280px) {
  .content-container {
    padding: 24px;
  }
}
@media only screen and (min-width: 1280px) {
  .app__container {
    margin-top: 3em;
  }
}
