@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

.order-card__container {
  border-radius: 2px;
  display: flex;
  padding: 0 !important;
  width: 100%;
  min-width: 152px;

  .card-content {
    width: 100%;
  }

  &.card {
    &:hover {
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    }
  }
}

.order-card-wrapper {
  color: #000;
  width: 100%;

  &:hover {
    color: #000;
  }
}

.order-card-wrapper {
  color: #000;

  &:hover {
    color: #000;
  }
}

.order-card__mobile--no-display {
  @media (max-width: 480px) {
    display: none;
  }
}

// In mobile, the one card that displays
// has excessive margin bottom due to CCL Grid
.order-card__mobile {
  @media (max-width: 480px) {
    margin-bottom: -50px;
  }
}

.order-card__header-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #f2f2f2;
}

.order-card__content {
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 20px;
  min-height: 200px;
}

.order-card__product-name {
  font-size: 20px;
  font-weight: 600;
  width: 100%;
}

.order-card__order-number {
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 16px 0 0 16px;
}

.order-card__status-wrapper {
  margin: 10px 0 10px 16px;
  padding: 10px;
  width: 160px;
  background: $nutrien-white;
  border-radius: 50px 0 0 50px;
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  line-height: 1;
}

.order-card__status-icon {
  padding: 0 10px;

  svg {
    width: 30px;
    height: 30px;
  }
}

.order-card__status-value {
  font-weight: 600;
  font-size: 18px;
}

.order-card__content {
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 20px;
  min-height: 200px;

  .order-card__product-name {
    font-size: 20px;
    font-weight: 600;
  }

  .order-card__product-count {
    font-weight: 600;
  }
}

.order-card__order-number-value {
  color: $nutrien-green;
}
.order-card__product-count {
  color: $nutrien-green;
  font-weight: 600;
}
