@import '~nutrien-style-guide/src/lib/colors/_colors.scss';
@import '../../styles/index.scss';

.thank-you-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-page-wrapper {
  display: flex;
  flex-direction: column;

  @media print {
    display: none;
  }
}

.button-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 32px;

  @media print {
    display: block;
  }
}
.print-section-support {
  text-align: center;
  font-size: 0.625rem;
  margin-top: 24px;
  .print-section-support__email {
    font-weight: bold;
  }
}
.print-section-wrapper {
  display: none;
  font-size: 0.625rem;
  h2 {
    font-size: 0.875rem;
    line-height: normal;
    margin: 11px 0;
  }
  p {
    font-size: 0.625rem;
  }
  @media print {
    display: block;
  }
}
.delivery-details__header {
  color: #000;
  padding-bottom: 5px;
  .delivery-details__checkmark {
    &:after {
      content: '';
      display: inline-block;
      width: 6px;
      height: 12px;
      margin-right: 5px;
      border: solid $nutrien-green;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}
.shopping-cart-table-outer-wrapper {
  border: 0;
}
.inner-print-section {
  display: flex;
  justify-content: space-between;
}

%print-section {
  display: flex;
  padding: 24px 0;
}

.print-section--column {
  @extend %print-section;
  flex-direction: column;
}

.print-section--row {
  @extend %print-section;
  justify-content: space-between;
}

.cart-section-wrapper {
  padding-top: 24px;
  position: relative;
}

.print-section-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  h2 {
    font-weight: 600;
    font-size: 0.75rem;
    margin: 0;
    color: $nutrien-dark-grey-2;
  }
}
.print-section-account {
  background-color: '#666';
  display: flex;
  justify-content: space-between;
}

// !TEXT-------------------------------------------------------------
.services__headline--detail {
  font-size: 0.625rem;
}
.thank-you-page__headline {
  @include cart-headline;

  align-self: center;
  max-width: 300px;
  text-align: center;
}

.print-section_headline {
  @include cart-headline;

  font-size: 1.3rem !important;
  max-width: 300px;
}
.print-section-details {
  border: 1px solid #f3f3f3;
  border-radius: 2px;
  padding: 6px 14px;
  display: flex;
  justify-content: space-between;
  .detail-name {
    color: $nutrien-grey-3;
  }
}
.print-section-bottom {
  border: 1px solid #f3f3f3;
  padding: 11px 14px;
  margin-top: 19px;
  .print-section-bottom--sec-row {
    margin-top: 22px;
  }
  h2 {
    margin: 0 0 8px 0;
  }
  .print-section__headline {
    padding: 0;
  }
  > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .print-section--column {
      width: 50%;
      padding: 0;
    }
  }
}
.thank-you-page__blurb {
  align-self: center;
  color: #000;
  display: flex;
  background-color: $alert--warning-1;
  font-weight: 500;
  justify-content: center;
  margin: 0;
  padding: 15px;
  width: 75%;

  @media print {
    display: block;
  }
}
.print-section-header__right {
  text-align: right;
}

// !BUTTONS-------------------------------------------------------------
.button--print {
  color: $nutrien-green;
  height: 20px;
  width: 20px;
}

.button--confirm {
  color: $nutrien-green;
  background: transparent;
  border: none;
  font-size: 14px;
  outline: none;
  padding: 0px 0px 0px 8px;

  &:hover {
    cursor: pointer;
  }
}

// !IMAGES-------------------------------------------------------------
.print-section-header__left {
  display: flex;
  align-items: center;
  padding-right: 10px;
  margin-right: 10px;
  .print-section-header__logo {
    border-right: 1px solid $nutrien-dark-grey-2;
    padding-right: 8px;
    margin-right: 8px;
    img {
      max-width: 150px;
    }
  }
}
