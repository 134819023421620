.invoice-amount-due-modal {
  .mdc-dialog__surface {
    max-width: 375px;
  }
  .nutrien-label {
    font-weight: 600;
  }

  .nutrien-slider {
    margin: auto;
    width: 100%;

    &__domain-label {
      width: auto !important;
      margin-left: 0px !important;
      left: auto !important;
      &:nth-of-type(2) {
        right: 0px;
      }
    }

    &__handle {
      outline: none;
    }
  }

  .mdc-text-field {
    width: 100%;
  }
  .nutrien-slider-bar {
    margin-top: 40px;
    width: 100%;
  }
}

@media screen and (min-width: 640px) {
  .invoice-amount-due-modal {
    .mdc-dialog__surface {
      max-width: 70%;
    }
  }
}

@media screen and (min-width: 845px) {
  .invoice-amount-due-modal {
    .mdc-dialog__surface {
      max-width: none;
    }
  }
}
