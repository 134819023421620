@import "~nutrien-style-guide/src/lib/colors/_colors.scss";
@import "../../../styles/index.scss";

// !WRAPPERS-------------------------------------------------------------
.shopping-cart-table-outer-wrapper {
  border-top: 2px solid $nutrien-green;
  &.priced {
    padding-top: 14px;
  }
}

.shopping-cart-item-wrapper {
  @include inner-cart-container-bottom;
}

.shopping-cart-item__content {
  padding-top: 24px;

  @media (min-width: 750px) {
    display: flex;
    justify-content: space-between;
  }

  @media print {
    display: block;
  }
}

.shopping-cart-item__content--secondary {
  display: flex;
  flex-direction: column;
}

.shopping-cart-item__section {
  align-self: flex-end;
}

.shopping-cart-item__total-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 8px 0 0 0;
}

// !TEXT-------------------------------------------------------------
.shopping-cart-item__total {
  @include sub-total;
  font-size: 0.625rem;
  margin-bottom: 0;

  @media (min-width: 750px) {
    padding-top: 0;
  }
}

.shopping-cart-item__label {
  color: $nutrien-gray-3;
  padding-right: 40px;
}
