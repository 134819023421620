.page-notification {
  width: 100%;
  display: flex;
  padding: 0 20px;
}

.page-notification__no-padding {
  padding: 0;
}

.page-notification-icon {
  margin-right: 10px;
  align-self: start;
}

.page-notification__message__text {
  display: block;
}

.page-notification__message__text--mobile {
  display: none;
}

.danger-notification,
.standard-notification,
.warning-notification,
.success-notification {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid;
  margin-bottom: 10px;
  display: flex;
  font-weight: 600;
  justify-content: space-between;
}

.standard-notification {
  background: #eee;
  border-color: #bbb;
}

.success-notification {
  background: rgba(0, 180, 85, 0.1);
  border-color: #00b455;
  justify-content: space-between;
}

.warning-notification {
  background: #fefbed;
  border-color: #f1c02a;
}

.success-notification i {
  color: #0093e5;
  font-size: 1.5em;
  margin-right: 10px;
}

.danger-notification i {
  color: #d24242;
}

.warning-notification i {
  color: #f1c02a;
}

.danger-notification {
  background: #feeded;
  border-color: #d24242;
}

.page-notification__message {
  display: flex;
  font-weight: 600;
  font-size: 13px;
  align-items: center;
}

.arrow-link__right {
  font-weight: 600;
}

@media only screen and (max-width: 700px) {
  .page-notification__message__text {
    display: none;
  }
  .page-notification__message__text--mobile {
    display: block;
  }
  .warning-notification {
    flex-direction: column;
  }
  .danger-notification {
    flex-direction: column;
  }
}
