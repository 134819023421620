@import "../../styles/_index.scss";

.delivery-date {
  &__header {
    @include cart-sub-headline;
  }

  &__date-button-wrapper {
    display: flex;
    flex-direction: column;
    padding: 12px 0 32px 0;
  }

  &__legal-header {
    @include cart-legal-header;
  }
}
