
.delete-dialog-title .red {
  color: #d0021b;
  font-weight: 600;
}

.delete-dialog-description {
  color: #828282;
}

