.recurring-payment-section {
  margin-top: 1.25rem;

  .recurring-payment-inner-section {
    display: flex;

    label {
      margin-top: 0.9375rem;
    }

    label:first-of-type {
      margin-right: 10rem;
    }

    .recurring-payment-dropdown-override {
      height: 2.8125rem;
      min-width: 200px;
    }
  }
}
