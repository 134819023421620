@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

.product-history__h1-title {
  margin-left: 20px;
}

.product-history__line-break {
  margin: 20px 20px;
}

.product-history__period-cost-total {
  margin-left: 10px;
  margin-bottom: 10px;
}

.product-history__line-break-top {
  margin: 0 20px 20px 20px;
}

.product-history__span-change-filter {
  color: $nutrien-green;
  cursor: pointer;
  font-weight: bold;
}

.product-history__div-change-filter {
  margin-top: 10px;
  margin-left: 20px;
}

.product-history__p-change-filter {
  margin-left: 0;
  margin-bottom: 10px;
}

.product-history__div-filters-container {
  display: flex;
  @media only screen and (max-width: 625px) {
    display: flex;
    flex-direction: column;
  }
}

.product-history__div-download-csv {
  max-width: 65px;
  position: relative;
  float: right;
  margin: 10px 20px 20px 0;
  display: flex;
  align-items: center;
  color: $nutrien-green;
  cursor: pointer;
  @media only screen and (max-width: 625px) {
    display: none;
  }
}

.product-history__div-download-csv-disabled {
  max-width: 65px;
  position: relative;
  float: right;
  margin: 10px 20px 20px 0;
  display: flex;
  align-items: center;
  color: $nutrien-grey-1;
  @media only screen and (max-width: 625px) {
    display: none;
  }
}

.product-history__pagination {
  margin-bottom: 20px;
}

.product_history__no_content {
  margin: 10px 0 20px 20px;
}

.product-history__table {
  margin: 0 20px 20px 20px;
}

.product-history__table-checkbox {
  width: 50px;
}

.product-history__table-quantity {
  width: 100px;
}

.product-history__table-shelf {
  width: 100px;
}

.product-history__table-sdsAndLabels {
  width: 150px;
  @media only screen and (max-width: 1000px) {
    width: 100px;
  }
}

.product-history__table-date {
  width: 110px;
}

.product-history__table-product-number {
  width: 125px;
  @media only screen and (max-width: 1150px) {
    display: none;
  }
}

.product-history__table-button-column {
  width: 175px;
  @media only screen and (max-width: 625px) {
    width: 100px;
  }
}

.product-history__table-button,
.product-history__table-row-mobile .product-history__table-button {
  width: 143px;

  @media only screen and (max-width: 480px) {
    width: 100%;
    height: 32px;
    margin: 0 auto;
  }
}

.product-history__table-cost {
  width: 110px;
}

.product-history__table-cost-column {
  width: 110px;
  @media only screen and (max-width: 625px) {
    width: 90px;
  }
}

.product-history__table-product-number,
.product-history__table-sdsAndLabels,
.product-history__table-checkbox,
.product-history__table-shelf,
.product-history__table-quantity,
.product-history__table-date,
.product-history__table-cost,
.product-history__table-cost-column,
.product-history__table-button-column {
  @media only screen and (max-width: 480px) {
    width: 115px;
  }
}

.product-history-table__sortable-column {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.product-history__material-chip-individual-filter > svg {
  fill: $nutrien-white;
}

.product-history__shelf-selector-modal {
  z-index: 300;
}

.product-history__shelf-filter-dialog-content {
  padding-bottom: 0 !important;
}

.product-history__shelf-filter-dialog-actions {
  padding-top: 0 !important;
}

.product-history__timeframe-custom-chip {
  margin-left: 130px;
}

#add-to-cart.product-history__button-reorder {
  margin: 5% 0;
  width: 157px;
  height: 32px;
}

.product-history__button-icon {
  width: 16px !important;
  height: 16px !important;
  margin-right: 10px;
  object-fit: contain;
  @media only screen and (max-width: 625px) {
    display: none !important;
  }
}

.product-history__button-text {
  @media only screen and (min-width: 625px) {
    white-space: nowrap;
  }
}

.product-history__div-change-filter > div {
  top: 30px;
}

.product-history__shelf-selector-accept {
  outline-color: $nutrien-white !important;
}

.product-history__timeframe {
  z-index: 300;

  .mdc-dialog__surface.mdc-dialog__surface {
    max-width: 400px;

    @media (max-width: 480px) {
      max-width: fit-content;
      margin: 0 16px;
    }
  }
}

.product-history__shelf-selector-close,
.product-history__timeframe-selector-close {
  background: transparent;
  border: 1px solid $nutrien-green;
  color: $nutrien-green;
  outline-color: $nutrien-white !important;

  &:focus,
  &:hover {
    background: #e9f3e0;
  }
}

.product-history__timeframe-selector-accept {
  outline-color: $nutrien-white !important;
}

.product-history__timeframe-selector-content {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0;

  .product-history__datepicker-container {
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
  }
}
.product_history__container {
  .mdc-dialog__surface.mdc-dialog__surface {
    max-width: 560px;
    @media only screen and (max-width: 850px) {
      max-width: fit-content;
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}

.product-history__table-row-mobile {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px 0;
}

.product-history__table-link {
  font-size: 16px;
}

.product-history__table-list-item-link {
  font-size: 16px;
}

.product-history__table-list-item-mobile {
  flex: 1 0 31%;

  @media screen and (max-width: 480px) {
    width: 100%;
    display: block;
  }
  @media screen and (max-width: 330px) {
    flex: 1 0 100%;
  }
}

.product-history__table-mobile-heading {
  display: inline-block;
  width: 115px;
  font-weight: 600;
}

.product-history__table-mobile-value {
  display: inline-block;
  width: 100%;

  @media only screen and (max-width: 480px) {
    width: 50%;
  }
  @media only screen and (max-width: 360px) {
    width: 40%;
  }
  @media only screen and (max-width: 320px) {
    width: 100%;
  }
}

.product-history__table-button-container {
  @media only screen and (max-width: 1280px) {
    text-align: right;
  }
  @media only screen and (max-width: 768px) {
    padding-left: 0;
  }
  @media only screen and (max-width: 480px) {
    padding: 8px;
    flex: 1 0 100%;
  }
}
