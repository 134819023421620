@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

.product-price__price-text {
  color: $nutrien-dark-grey-2;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  font-weight: 600;
  height: 50px;
  min-height: 37px;
  padding: 8px 0;
}

.product-price__unit-text {
  color: #888;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-left: 8px;
}

.product-price__no-price-wrapper {
  text-align: left;
  .product-price__no-price-message-pdp {
    @media (min-width: 700px) and (max-width: 1071px) {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.product-price__no-price-message-pdp {
  color: $nutrien-dark-grey-2;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  display: flex;

  @media (min-width: 913px) {
    white-space: nowrap;
  }
}

.product-price__no-price-message-plp {
  color: $nutrien-dark-grey-2;
  display: flex;
  font-weight: 600;
  justify-content: center;
  padding-bottom: 5px;
  position: relative;
}

.product-price__price-wrapper {
  margin-bottom: 12px;
  justify-content: right;

  @media (min-width: 700px) {
    min-height: 60px;
  }
}
