.country-label {
  font-size: 24px;
  border-bottom: 1px solid #cccccc;
  padding-top: 40px;
  padding-bottom: 15px;
}

.image-icon {
  height: 14px;
  width: 24px;
  margin-left: 10px;
}

.payment-method-rows {
  list-style: none;
  padding-left: 0;
}