@import "~nutrien-style-guide/src/lib/colors/_colors.scss";

.total-volume-dropdown-wrapper {
  width: 100%;
}

.total-volume-dropdown__label {
  font-size: 1.2rem;
  font-weight: 600;
}

.total-volume-dropdown__label {
  font-size: 1.2rem;
  font-weight: 600;
}

.total-volume-dropdown__input-label {
  color: #222;
  display: block;
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.total-volume-dropdown__input {
  border-radius: 4px;
  border: 1px solid #ccc;
  display: block;
  height: 2rem;
  margin-top: 0;
  padding-left: 7px;
  width: 100%;

  &:focus {
    border-color: #0098d1;
  }
}

.text-field-root {
  margin-top: 4px;
}

.total-volume-dropdown__input-error {
  border: 1px solid $brand--danger;
}

.total-volume-dropdown__select {
  margin-left: 8px;
  @media (min-width: 1200px) {
    margin-left: 0px;
    margin-top: 8px;
  }
}

.total-volume-dropdown {
  font-size: 1.2rem;
  width: 100%;
  margin-top: 0px;
}
