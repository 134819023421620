.activity-row {
  background: #fff;
  transition: background 0.4s;
}

.activity-row:hover {
  background: #f7f7f7;
  transition: background 0.4s;
}

.accounting-list tr {
  border-bottom: 1px solid #ccc;
}

.overview__type__max-space > .link-spacer {
  width: 430px;
  padding: 10px 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.accounting-list__header__inner {
  padding: 10px 50% 10px 20px;
  white-space: nowrap;
  font-weight: 400;
  font-size: 0.9em;
}

.accounting-list__header:first-child .accounting-list__header__inner {
  padding-left: 20px;
}

.accounting-list__header:last-child .accounting-list__header__inner {
  padding-right: 20px;
}

.account-list__filter-label {
  font-weight: 600;
  color: #004538;
  padding-right: 0.5rem;
}

.accounting-list__header__right__inner > .accounting-list__header__inner {
  white-space: nowrap;
  text-align: right;
  padding: 0;
}

.accounting-list-item__link__min-space > .link-spacer,
.accounting-list-header__link__min-space > .accounting-list__header__inner {
  padding: 10px 20px;
}

.link-spacer {
  padding: 10px 0 10px 20px;
  text-transform: capitalize;
  font-size: 0.8rem;
  font-weight: 300;
}

.accounting-list td:first-child .link-spacer {
  padding-left: 20px;
}

.accounting-list td:last-child .link-spacer {
  padding-right: 20px;
}

.accounting-list__link {
  color: #4c9e00;
  font-weight: 600;
}

.accounting-list__link:hover {
  text-decoration: underline;
}

.accounting-list-wrapper {
  margin-bottom: 1rem;
  width: 100%;
}

.accounting-list-title {
  font-weight: normal;
  font-size: 1.7em;
  margin: 0;
}

.accounting-list {
  list-style: none;
  width: 100%;
  border-collapse: collapse;
}

.header-bar {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.accounting-list-item__link__no-padding {
  padding: 10px 0 10px 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 260px;
  overflow: hidden;
}

.accounting-list-header__link__min-space {
  width: 1px;
}

.accounting-list__header__right__inner.accounting-list-header__link__min-space {
  width: initial;
  padding: 0;
}

.accounting-list-item__noWrap {
  white-space: nowrap;
}

.accounting-list-item__link__right-align {
  text-align: right;
}

@media screen and (max-width: 1000px) {
  .accounting-list__header__right__inner > .accounting-list__header__inner {
    text-align: right;
    padding: 10px 20px;
    white-space: nowrap;
  }

  .header-bar {
    padding: 20px 10px;
    flex-direction: column;
  }

  .filter-line-items {
    width: 100%;
    margin: 0 10px 16px 10px;
  }

  .accounting-list__header__inner {
    padding: 10px 20px;
    white-space: nowrap;
  }
  .accounting-list__header__right__inner {
    text-align: right;
    padding: 10px 20px;
    white-space: nowrap;
  }
  .link-spacer {
    padding: 10px 20px;
  }
}

@media screen and (min-width: 500px) {
  .accounting-list__scroll-indicator__visible {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .accounting-list thead {
    display: none;
  }
  .activity-row-mobile {
    padding-top: 12px;
    padding-bottom: 12px;
    height: inherit;
    display: flex;
    flex-wrap: wrap;
    flex: auto;
  }
  .activity-row-mobile td {
    flex: 1 0 50%;
  }
  .activity-row-mobile td div {
    text-align: left;
  }
  .accounting-list-item-header-mobile-only {
    display: block;
    font-weight: 600;
    padding: 0px 0px 0px 20px !important;
  }

  .link-spacer {
    padding-top: 0px !important;
    width: auto !important;
  }
}

@media screen and (max-width: 375px) {
  .activity-row-mobile td {
    flex: 1 0 100%;
  }
}

@media screen and (min-width: 769px) {
  .accounting-list-item-header-mobile-only {
    display: none;
  }
}
