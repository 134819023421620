.BreadCrumbContainer {
  margin: 18px 20px 5px 20px;
  padding-bottom: 10px;
  display: flex;
  font-size: 0.875rem;
  align-items: center;
}

.BreadCrumbContainer__no-padding {
  margin: 0;
}
