.account-statements {
  flex: 100% 1;
}

.account-statements--loading-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.statements__pdf-link {
  color: #4c9e00;
  cursor: pointer;
  display: inline-flex;
  font-weight: 600;
}

.statements__pdf-link__disabled {
  font-weight: 600;
  color: #aaa;
}

.statements__pdf-link__loading {
  color: #4c9e00;
  font-weight: 600;
  display: inline-flex;
}
