@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

.product-wrapper {
  $grey-border: 1px solid #cccccc;
  $transparent-border: 1px solid transparent;
  $promotional-product-border: 1px solid green;

  padding: 24px 12px;

  width: 100%;
  @media (min-width: 1100px) {
    width: 50%;
  }
  @media (min-width: 1300px) {
    width: 33%;
  }

  .promotional-product-icon {
    align-self: center;
    height: 50px;
    width: 50px;
    border-radius: 4px 0 0 0;
    position: absolute;
  }

  .promotional-product-label {
    color: $nutrien-denim;
    font-size: 10px;
    font-weight: bold;
  }

  .product-wrapper__inner-wrapper {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    border-bottom: $grey-border;
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 4px;
    padding: 24px;
    position: relative;
    text-align: center;
    &:hover {
      border-left: none;
      border-right: none;
      border-top: none;
    }

    &.promotional-product {
      border: $promotional-product-border;
      padding-top: 8px;

      .product-price__price-wrapper {
        margin-bottom: 14px;
      }

      .product-price__price-text {
        margin-bottom: 0px !important;
      }

      .product-variant-options__container {
        padding-top: 0px;
        margin-top: 10px;
      }

      .product-wrapper__manufacturer-name {
        margin-bottom: 0px;
      }
    }

    @media (min-width: 720px) {
      border-bottom: $grey-border;
      border-left: $transparent-border;
      border-right: $transparent-border;
      border-top: $transparent-border;
      &:hover {
        border-left: $grey-border;
        border-right: $grey-border;
        border-top: $grey-border;
      }
    }

    .product-wrapper__manufacturer-name {
      color: $nutrien-grey-3;
      height: 24px;
      font-family: 'Open Sans', 'sans-serif';
      font-size: 14px;
      font-weight: 600;
      margin: 0 0 24px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .product-wrapper__product-name {
      color: $nutrien-green;
      font-family: 'Open Sans', 'sans-serif';
      font-size: 20px;
      font-weight: 600;
      margin: 4px 0 0 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .product-wrapper__preferred-flag {
      height: 62px;
      left: 0;
      position: absolute;
      top: 0;
      width: 62px;
      z-index: 100;
    }

    .product-wrapper__image-container {
      align-items: center;
      background: $nutrien-white;
      display: flex;
      height: 140px;
      justify-content: center;
    }

    .product-variant-options__container {
      padding-top: 15px;
    }
  }
}
