.arrow-link {
    color: #464646;
    font-size: 1em;
    height: 27px;
    font-weight: 400;
    text-align: left;
    margin: 15px 5%;
}

.arrow-link__link, .arrow-link__link:hover, .arrow-link__link:link, .arrow-link__link:active, .arrow-link__link:visited {
    font-size: .85em;
	  line-height: 1.15;
	  text-align: left;
	  color: #4c9e00;
    font-weight: 500;
    text-decoration: none;
    white-space: nowrap;
}

.arrow-link__link > img {
    width: .5em;
    margin: 5px;
}
