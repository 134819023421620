@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

.no-price__wrapper {
  display: inline;
}

.no-price__message-button,
.no-price__message-button--hover {
  position: relative;
  padding: 0;
  margin: 0;
  top: 7px;
  right: -7px;
  height: 24px;
  background: transparent;
  border: none;
}

.no-price__message-button {
  cursor: pointer;
}

.no-price__message-icon {
  fill: $nutrien-grey--icon;
}

.no-price__message-popper-z {
  z-index: 99999;
}

.no-price__message-popper-paper {
  max-width: 250px;
  padding: 16px;
}

$arrow-color: #fff;
$arrow-shadow-color: rgba(0, 0, 0, 0.12);
.no-price__message-popper,
.no-price__message-popper-z {
  &[x-placement*='bottom'] .no-price__message-popper-arrow {
    width: 0;
    height: 0;
    border-left: 1em solid transparent;
    border-right: 1em solid transparent;
    border-bottom: 1em solid $arrow-color;
    margin-top: -0.9em;
    filter: drop-shadow(0px -3px 2px $arrow-shadow-color);
  }

  &[x-placement*='top'] .no-price__message-popper-arrow {
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 1em solid transparent;
    border-right: 1em solid transparent;
    border-top: 1em solid $arrow-color;
    margin-bottom: -0.9em;
    filter: drop-shadow(0px 3px 2px $arrow-shadow-color);
  }

  &[x-placement*='right'] .no-price__message-popper-arrow {
    left: 0;
    width: 0;
    height: 0;
    border-top: 1em solid transparent;
    border-bottom: 1em solid transparent;
    border-right: 1em solid $arrow-color;
    margin-left: -0.9em;
    filter: drop-shadow(-3px 0px 4px $arrow-shadow-color);
  }

  &[x-placement*='left'] .no-price__message-popper-arrow {
    right: 0;
    width: 0;
    height: 0;
    border-top: 1em solid transparent;
    border-bottom: 1em solid transparent;
    border-left: 1em solid $arrow-color;
    margin-right: -0.9em;
    filter: drop-shadow(3px 0px 4px $arrow-shadow-color);
  }

  .no-price__message-popper-arrow {
    position: absolute;
    font-size: 7px;
    width: 3em;
    height: 3em;
  }
}
