@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

.cart-stepper__root {
  display: none;
  margin: 0 auto;
  width: 90%;

  @media (min-width: 750px) {
    display: block;
  }
}

.cart-stepper__stepper {
  display: flex;
  justify-content: center;
}

.cart-stepper__step-connector {
  max-width: 60px;
}

.cart-stepper__step-button {
  border: none;
  cursor: default !important;
  outline: none;

  &:hover,
  &:active,
  &:focus {
    outline: none;
    border: none;
  }
}

.cart-stepper__bubble--root {
  color: $nutrien-white !important;

  & text {
    fill: #000;
  }
}

.cart-stepper__bubble--active {
  color: $brand--info !important;

  & text {
    fill: $nutrien-white !important;
  }
}

.cart-stepper__bubble--completed {
  color: $nutrien-green !important;
}
