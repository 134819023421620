@import "~nutrien-style-guide/src/lib/colors/_colors.scss";

.consultant-info__consultant-info-container {
  min-width: 360px;
  min-height: 150px;
  padding: 8px 24px 24px;
  background-color: $brand--info;
}

.consultant-info__consultant-info-wrapper {
  display: flex;
}

.consultant-info__crop-consultant {
  padding-top: 24px;
}

.consultant-info__consultant-name {
  font-size: 20px;
  font-weight: 600;
  color: $nutrien-white;
  padding-bottom: 2px;
}

.consultant-info__consultant-info {
  font-size: 12px;
  color: $nutrien-white;
  padding-bottom: 12px;
  font-weight: 500;
}

.consultant-img__link {
  color: $nutrien-white;
}

.consultant-img__link:hover {
  text-decoration: underline;
  color: $nutrien-white;
}

.consultant-info__consultant-contact-container {
  display: inline-flex;
  margin-left: 8px;
}

.consultant-info__consultant-email {
  font-size: 14px;
  font-weight: 600;
  color: $nutrien-white;
  padding: 5px;
}

.consultant-info__consultant-phone {
  font-size: 14px;
  font-weight: 600;
  color: $nutrien-white;
  padding: 5px;
}

.consultant-info__consultant-info-icon {
  font-size: 20px;
  color: $nutrien-white;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 2) {
  .consultant-info__consultant-info-container {
    min-width: 280px;
    min-height: 150px;
    padding: 8px 24px 24px;
    background-color: $brand--info;
  }
}
