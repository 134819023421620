@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

.single-variant__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid $nutrien-gray-1;
  height: 39px;
}

.single-variant__text-wrapper {
  text-align: center;
  color: $nutrien-dark-grey-2;
  font-size: 0.875rem;
  font-weight: 400;
  padding-top: 7px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  overflow: hidden;
}
