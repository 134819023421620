@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

.invoice-cards-container {
  padding-left: 20px;
  padding-right: 20px;
}

.invoice-card {
  height: 100%;
  color: $nutrien-white;
  background: $nutrien-green;

  &__amount-and-action {
    display: flex;
  }

  &__title {
    color: inherit;
    margin: 0;
    font-size: 32px;
    line-height: 48px;
    font-weight: normal;
  }

  &__subtitle {
    margin: 0;
    font-size: 13px;
    color: inherit;
    font-weight: 400;
  }
}

.paid-invoice-stamp {
  position: absolute;
  top: 0;
  right: 15px;
  height: 200px;
  width: 200px;
}

.invoice-card-paid {
  position: relative;
  background: #ffffff;
  border: 1px solid $nutrien-grey--inactive-outline;
  color: #000000;
}

.invoice-card__subtitle-green {
  color: $nutrien-green;
}

.invoice-card-actions {
  justify-content: flex-end;
  align-items: center;
  display: flex;
  width: 100%;
}

.invoice-card-actions button {
  width: 92px;
  height: 19px;
}

@media only screen and (max-width: 1135px) {
  .invoice-card {
    &__amount-and-action {
      display: block;
    }
  }
}

@media screen and (max-width: 930px) {
  .paid-invoice-stamp {
    position: absolute;
    top: 0;
    right: 5px;
    height: 150px;
    width: 150px;
  }
}
