.invoices-pagination-container {
  margin-bottom: 1rem;
  overflow-x: scroll;
}

.downloadLinkText {
  padding: 0px 0px 0px 5px;
}

.downloadLinkText:hover {
  text-decoration: underline;
}

.invoices__pdf-link-text {
  padding: 0px 0px 0px 9px;
}

.invoice-status__discount-available {
  color: #465f70;
}

.invoice-status__not-yet-due {
  color: #a8a7a8;
}

.invoice-status__paid {
  color: #4c9e00;
}

.invoice-status__due,
.invoice-status__due-rebate-available {
  color: #476071;
}

.invoice-status__pastDue {
  color: #d34242;
}

.nutrien-table__cell i {
  position: relative;
  right: 5px;
  top: 5px;
}

.nutrien-list-item__graphic {
  margin-right: 10px;
}
