.employee-notification-bar__wrapper {
  background-color: #fefcf6;
  border: 1px solid #f7e2aa;
  border-radius: 4px;
  display: flex;
  margin: 12px 0 24px;
  padding: 9px 9px 9px 15px;
}

.employee-notification-bar__employee-warning-icon {
  height: 1em;
  flex-shrink: 0;
  font-size: 24px;
  margin-right: 12px;
  width: 1em;
}
