.login-switch-button {
  &__mobile-container {
    height: 4em;
    display: flex;
    width: 200px;
    margin: 0 auto 2em;
    flex-direction: column;

    @media only screen and (min-width: 450px) {
      height: 2em;
      visibility: hidden;
      margin: -2em 0 0;
      width: 100%;
    }
  }

  &__container {
    height: 2em;
    display: none;

    @media only screen and (min-width: 450px) {
      display: flex;
    }
  }

  &__button {
    margin: 0;
    align-self: center;
  }
}
