@import "~nutrien-style-guide/src/lib/colors/_colors.scss";
@import "../../../styles/index.scss";

// !TEXT-------------------------------------------------------------
.delivery__headline {
  color: $nutrien-grey-3;
}

.delivery__headline--detail {
  font-weight: 600;
}

.delivery__blurb {
  font-weight: 600;
  line-height: 1.3rem;
  margin: 0;
  max-width: 600px;
  padding: 8px 0 24px 0;
}
