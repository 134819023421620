@import "~nutrien-style-guide/src/lib/colors/_colors.scss";
@import "../../styles/index.scss";

// !WRAPPERS------------------------------------------------------------
.custom-crop-care-wrapper {
  padding-top: 24px;
}

.crop-care-checkbox {
  align-items: center;
  display: flex;
}

// !TEXT---------------------------------------------------------------
.custom-crop-care__headline {
  @include cart-sub-headline;
  margin-bottom: 0px;
}

.custom-crop-care__blurb {
  color: #000;
  font-weight: 400;
  line-height: 1.5;
  margin: 0px;
  max-width: 400px;
  padding: 8px 0px 16px 0px;
}

.crop-care-checkbox__label {
  @include total-items;
  color: #000;
  font-weight: 600;
  padding: 8px;
}
