@import "~nutrien-style-guide/src/lib/colors/_colors.scss";

.product-detail__ingredient-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-detail__ingredient-name:after {
  content: " ......................................................................................................................";
}

.product-detail__ingredient {
  display: flex;
  justify-content: space-between;
}

.product-detail__ingredient-percentage {
  flex-shrink: 0;
}

.product-detail__pack-size-label {
  margin-left: 0;
  margin-right: 0;
  color: $nutrien-dark-grey-2;
  font-weight: 600;
}

.product-detail__notification-container {
  height: 84px;
  min-height: 84px;
  @media (min-width: 700px) {
    height: 72px;
    min-height: 72px;
  }
}

.product-detail__notification--error {
  padding: 8px;
  width: 100%;
}

.product-options__input-message-container {
  margin-top: 12px;
  margin-bottom: 12px;
}

.product-options__input-message--error {
  color: $brand--danger;
  font-size: 0.8rem;
}

.product-detail__single-variant {
  border-bottom: 1px solid #999;
  color: #222;
  font-size: 16px;
  font-weight: 400;
  min-width: 100px;
  padding: 8px 0px;
  margin: 0;

  @media (max-width: 425px) {
    width: 75%;
  }
}

.product-detail__back-to-results {
  align-items: center;
  color: $nutrien-green;
  display: inline-flex;
  font-size: 12px;
  font-weight: 400;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.product-detail__back-to-results-icon {
  transform: rotate(90deg);
}

.product-detail__employee-wrapper {
  padding-top: 70px;
}
