.close-button {
  display: flex;
  align-self: flex-end;
}

@media only screen and (min-width: 325px) {
  .container {
    padding-top: 50px;
  }
  .dialog-content {
    margin-top: 0px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .title {
    color: var(--Greys-Grey-800, #222);
    text-align: center;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  .sub-title {
    color: var(--Greys-Grey-800, #222);
    text-align: center;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .image {
    display: flex;
    width: 126px;
    height: 282px;
  }
}

@media only screen and (min-width: 430px) {
  .dialog-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .title {
    color: var(--Greys-Grey-800, #222);
    text-align: center;
    font-family: 'Open Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
  .sub-title {
    color: var(--Greys-Grey-800, #222);
    text-align: center;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .image {
    display: flex;
    width: 176px;
    height: 382px;
  }
}
