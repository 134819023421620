@import "~nutrien-style-guide/src/lib/colors/_colors.scss";

$fontSize: 0.875rem;
$boldWeight: 600;

.account-fulfillment__container {
  align-items: flex-start;
  background-color: #f2f2f2;
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
  width: 100%;
}

.account-fulfillment__header {
  margin: 0 0 12px 0;
  flex: 0 0 75%;
  font-size: $fontSize;
  font-weight: $boldWeight;
}

.account-fulfillment__change-button {
  flex: 0 0 25%;
  padding: 0 0.5rem;
}

.account-fulfillment__account-info {
  color: $nutrien-dark-grey-2;
  flex: 0 0 100%;
  font-size: $fontSize;
  font-weight: $boldWeight;
  margin: 0;
}

.account-fulfillment__account-location-info {
  margin: 0 0 12px 0;
  color: $nutrien-dark-grey-2;
  flex: 0 0 100%;
  font-size: $fontSize;
}

.account-fulfillment__account-link-button {
  font-size: $fontSize;
}

@media (min-width: 720px) {
  .account-fulfillment__container {
    width: 20rem;
  }

  .account-fulfillment__header {
    margin: 0 0 6px 0;
  }

  .account-fulfillment__account-location-info {
    margin: 0 0 6px 0;
  }
}
