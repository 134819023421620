@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

.product-list-grid__no-results-wrapper {
  padding: 24px;
  width: 100%;

  @media (min-width: 590px) {
    width: 75%;
  }
}

.product-list-grid__products-disabled-wrapper {
  padding: 24px;
  width: 100%;

  .product-list-grid__products-disabled-notification {
    margin-top: 20px;

    .product-list-grid__products-disabled-link {
      text-align: end;
      display: block;
      margin-top: 5px;
    }
  }

  @media (min-width: 590px) {
    width: 75%;
  }
}
.product-list-grid__outer-wrapper {
  background-color: $nutrien-white;
  width: 100%;

  @media (min-width: 590px) {
    width: 75%;
  }
}
.product-list-grid__results-header-wrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 24px 24px 0 24px;
}
.product-list-grid__results-header {
  margin-bottom: 8px;
}

.product-list-grid__outer-shop-wrapper--employee {
  overflow: hidden;
  padding-top: 70px;
}
.product-list-grid__outer-plp-wrapper {
  display: flex;
}
.product-list-grid__product-list-wrapper {
  background-color: $nutrien-white;
  width: 100%;

  @media (min-width: 720px) {
    display: flex;
    flex-wrap: wrap;
  }
}
.product-list-grid__loading-wrapper {
  width: 100%;
}
.product-list-grid__loading-more-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.product-list-grid__category-name {
  font-size: 24px;
  font-weight: 600;
  margin-right: 8px;
}

.product-list-grid__mobile-filter {
  width: 100%;
  margin: 16px 0 0 0 !important;
  @media (min-width: 590px) {
    display: none !important;
  }
  &:hover {
    cursor: pointer;
  }
}
