@import "../../styles/_index.scss";

.services-page__outer-cart-page-wrapper {
  @include outer-cart-page-wrapper;
}

.services-page__cart-headline {
  @include cart-headline;
  margin: 0;
  padding-bottom: 0;
}

.services-page__cart-footer {
  @include cart-footer;
}

.services-page__cart-button {
  @include cart-button;
  display: none;
  width: 200px;

  @media (min-width: 750px) {
    display: block;
  }
}
