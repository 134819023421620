@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

.mobile-header__content {
  width: 100%;
  display: flex;
  align-items: center;
}

.mobile-section-container {
  display: flex;
  font-size: 0.5em;
}

.mobile-section-header {
  display: none;
}

.mobile-header__icon-link {
  padding-right: 4px;
  color: $nutrien-dark-grey-2;

  @media (min-width: 500px) {
    padding-right: 22px;
  }
}

.mobile-header__icon-link.selected {
  color: $nutrien-green;
}

.mobile-header__links-container {
  border-bottom: 1px solid $nutrien-grey-3;
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.mobile-header__submenu-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mobile-header__sign-out-container {
  @extend .mobile-header__links-container;
  border-bottom: none;
}

.mobile-header__link-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 12px;
}

.mobile-header__inner-link-container {
  display: flex;
  align-items: center;
}

.mobile-header__profile-container {
  align-items: center;
  border-bottom: 1px solid $nutrien-grey-3;
  color: $nutrien-grey-3;
  display: flex;
  font-weight: normal;
  height: 80px;
  padding-left: 12px;
  text-transform: capitalize;
  width: 100%;
}

.mobile-header__icon-container {
  background-color: $nutrien-grey-3;
  display: inline-flex;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
}

.mobile-header__user-icon {
  transform: scale(1.5);
}

.mobile-header__user-details {
  display: inline-flex;
  justify-content: left;
  padding: 1em;
  font-size: 14px;
  font-weight: 600;
  color: rgba(34, 34, 34, 1);
  padding-left: 8px;
}

#mobile-header__hamburger-menu {
  display: flex;
  align-items: center;
  height: 60px;
  width: 60px;
  cursor: pointer;
  position: relative;
  padding-left: 12px;
  padding-right: 12px;
}

#mobile-header__hamburger-menu:focus {
  outline: none;
}

.mobile-header__drawer-menu {
  background-color: $nutrien-white;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
  left: -320px;
  max-height: inherit;
  max-width: 300px;
  overflow: scroll;
  position: fixed;
  transform: translateX(-320px);
  -ms-transform: translateX(-320px);
  -webkit-transform: translateX(-320px);
  transition: transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  top: 60px;
  width: 300px;
  z-index: 200;
}

.header.mirroring .mobile-header__drawer-menu {
  top: 110px;
}

.mobile-header__drawer-menu > div > a:hover {
  filter: brightness(90%);
  text-decoration: none;
}

.mobile-header__drawer-menu.open-menu {
  transform: translateX(320px);
  -webkit-transform: translateX(320px);
  -ms-transform: translateX(320px);
  transition: transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
}

.mobile-header__links-container .mobile-header__menu-item,
.mobile-header__submenu-item,
.mobile-header__sign-out-container .mobile-header__menu-item {
  color: $nutrien-dark-grey-2;
}

.header__notification-link-icon,
.header-content .menu__item.mobile-header__icon-link {
  color: $nutrien-dark-grey-2;
}

.header-content {
  .nav-tab__bar {
    .mobile-header__menu-item {
      display: none;
    }
  }
}

.mobile-header__crop-planning-container,
.mobile-header__menu-item,
.mobile-header__menu-item-container,
.mobile-header__submenu-item {
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  padding: 12px;
}

.mobile-header__submenu-item {
  border-radius: 4px;
  font-weight: 400;
  padding: 12px 12px 12px 44px;
}

.mobile-header__menu-item-container {
  @extend .mobile-header__menu-item;
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.mobile-header__sign-out-container {
  .mobile-logout-button {
    border: none;
    text-align: left;
  }
}

.mobile-header__crop-planning-container {
  @extend .mobile-header__menu-item;
  padding: 0;
  display: none;
}

.mobile-header__crop-planning-container--open {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
}

.mobile-header__menu-backdrop {
  display: none;
}

.mobile-header__menu-backdrop.open-menu {
  background-color: $nutrien-dark-grey-2;
  display: block;
  position: absolute;
  height: calc(100vh - 2em);
  max-height: inherit;
  opacity: 0.32;
  overflow: scroll;
  width: 100%;
  z-index: 100;
}

.mobile-section__button-container {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  left: 12px;
  position: absolute;
  top: 0;
}

.mobile-section__button-container:focus {
  outline: none;
}

.mobile-header__notification-button {
  margin-right: 22px;
  margin-left: 22px;
  position: relative;
}
