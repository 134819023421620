@import '~nutrien-style-guide/src/lib/colors/_colors.scss';
@import '../../../../../src/shared/styles/index.scss';

.nutrien-orders__table-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 15px;
  margin-top: 0px;

  .nutrien-label {
    padding: 0;
  }

  .order__status-filter-container {
    width: 180px;

    .order__status-filter {
      width: 100%;
      margin-top: 8px;

      .mdc-select__native-control {
        font-size: 16px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin: 16px 8px;
    margin-top: 0;
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;

    .nutrien-orders__table-details--left h3 {
      margin-bottom: 8px;
    }
    .order__status-filter-container {
      padding: 8px 8px;
      width: 100%;
    }
  }
}

.orders-table__no-filtered-orders {
  text-align: center;

  svg {
    margin: auto 8px;
  }
  svg,
  .orders-table__no-filtered-orders-text {
    vertical-align: middle;
  }
}

.orders-list__account-description {
  font-size: 2em;
  font-weight: 600;
  color: #222222;
  margin: 0;
}

.orders-list__account-description__text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.orders-list__empty-message-container {
  margin: 0 15px;
  text-align: center;

  @media screen and (max-width: 480px) {
    text-align: left;
    margin: 0 8px;
  }
}

.orders-list__note-container {
  color: #929292;
  font-size: 12px;
  text-align: center;
  padding: 8px;
}

.orders-list__products-link-wrapper {
  display: flex;
  text-align: left;
  justify-content: flex-start;
  justify-content: center;
  align-items: center;
  margin: 15px;

  .orders-list__products-link-button {
    margin: 0;
  }

  @media only screen and (max-width: 768px) {
    margin: 16px 8px;
  }
  @media only screen and (max-width: 480px) {
    .orders-list__products-link-button {
      width: 100%;
    }
  }
  @media only screen and (max-width: 320px) {
    .orders-list__products-link-button {
      font-size: 12px;
    }
  }
}

.orders-list__table-header-cell {
  @include grey-border-top;
  font-weight: 600;
}

.orders-list__loading-wrapper {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.orders-list__table-orderId-column {
  .orders-list__table-orderId-link {
    font-size: 16px;
    font-weight: 600;
    color: $nutrien-green;
  }
}

.orders-list__status-icon {
  display: inline-block;
  padding-right: 10px;
  position: relative;
  top: 0.125rem;
  vertical-align: middle;
}

.orders-list__status-text {
  display: inline-block;
  font-weight: 700;
  margin-bottom: 0;
}

.orders-list__table-date-column {
  width: 20%;
}

.orders-list__table-order-id-column {
  width: 30%;
}

.orders-list__table-subtotal-column {
  width: 20%;
}

.orders-list__table-status-column-header {
  padding-left: 42px;
}

.orders-list__table-status-column {
  width: 25%;
}

.orders-list__table-button-column {
  text-align: center;
  width: 25%;
}

.orders-list__table-button {
  width: 70%;
}

.orders-list__table-item-mobile-heading {
  display: none;
}

@media only screen and (max-width: 768px) {
  .orders-list__table-date-column {
    width: 17%;
  }
  .orders-list__table-order-id-column {
    width: 17%;
  }
  .orders-list__table-status-column {
    width: 25%;
  }
  .orders-list__table-button-column {
    width: 20%;
  }
  .orders-list__table-button {
    width: 100%;
  }
}

@media only screen and (max-width: 568px) {
  .orders-list__status {
    display: inline-block;
  }
  .orders-list__table-date-column,
  .orders-list__table-order-id-column,
  .orders-list__table-subtotal-column,
  .orders-list__table-status-column,
  .orders-list__table-button-column {
    width: 100%;
  }
  .orders-list__table-button {
    width: 100%;
  }
  .orders-list__table-item {
    display: block;
  }
  .orders-list__table-item-mobile-heading {
    display: inline-block;
    width: 115px;
    font-weight: 600;
    color: #222;
  }
  .orders-list__table-mobile-hidden {
    display: none;
  }
}

@media only screen and (max-width: 310px) {
  .orders-list__status {
    display: block;
  }
  .orders-list__table-item-mobile-heading {
    display: block;
  }
}
