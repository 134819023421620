@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

// TODO: Refactor further into search input and menu dropdown style files
.header-search__textfield {
  margin-top: 8px;
  border: 1px solid $nutrien-table-border;
  border-radius: 2px 0 0 2px;
  width: calc(100% - 50px) !important;

  input {
    border-right: 1px solid $nutrien-table-border !important;
    background-color: white !important;
    margin-right: 0 !important;
    border-radius: 2px;
    font-size: 16px;
  }

  input::-webkit-search-cancel-button {
    display: none;
  }

  svg {
    fill: $nutrien-dark-grey-2 !important;
  }

  @media screen and (min-width: 1280px) {
    margin-top: 10px;

    svg {
      border-left: none;
      fill: $nutrien-grey-1;
    }

    input {
      border-right: none !important;
    }

    .header-search__textfield-search-icon {
      display: none;
    }
  }
}

.header-search-field {
  width: 100%;
  height: 56px;
  background-color: rgb(241, 240, 242);
  text-align: center;
  margin-top: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.header-search-field .mdc-text-field {
  width: 95%;
}

.header-search-field .mdc-notched-outline,
.header-search-field .mdc-notched-outline__idle,
.category-nav .desktop-plp-search-field,
.search-clear-icon {
  display: none;
}

.header-search-field .mdc-text-field__input {
  display: block;
  margin: 0 auto;
  margin-right: 7px;
  padding-left: 10px;
  padding-right: 0px;
}

.search-icon__mobile {
  display: flex;
  background-color: white;
  width: 40px;
  height: 40px;
  margin-top: 8px;
  border: 1px solid $nutrien-table-border;
  border-radius: 0 2px 2px 0;
  border-left: 0;

  svg {
    margin: auto;
  }

  @media only screen and (min-width: 1280px) {
    display: none;
  }
}

@media only screen and (min-width: 1280px) {
  .header__search--desktop {
    max-width: 338px;
    margin-right: 20px;
    overflow: hidden;
    position: relative;
    width: 80px;
    margin-top: 7px;

    &-expanded {
      width: 338px;
      bottom: 5px;
    }

    .header-search__autofill-container {
      position: fixed;
      width: 340px;
    }

    input.mdc-text-field__input.hide {
      width: 10px;
    }

    .header-search-field {
      background-color: transparent;
    }

    .header-search-field {
      display: inherit;
    }

    .header-search-field {
      background-color: transparent;
      display: inherit;
      width: 325px;

      .mdc-text-field input.mdc-text-field__input {
        padding-right: 18px;
      }
    }

    .header-search-field.show {
      margin-left: 0px;
      transition: all 0.3s ease;
    }

    .header-search-field.hide {
      margin-left: 305px;
      transition: all 0.3s ease;
    }

    .search-icon {
      background-color: $nutrien-white;
      border: 1px solid $nutrien-table-border;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 10px;
      width: 40px;
      height: 40px;
      margin-bottom: 10px;
    }

    .search-icon.show {
      border-left: 1px solid $nutrien-table-border;
      border-radius: 0 2px 2px 0;
      transition: all 0.3s ease;
      padding-top: 5px;
      padding-left: 5px;
    }

    .search-icon.hide {
      border: 1px solid transparent;
      transition: all 0.3s ease;
    }

    .search-clear-icon {
      position: absolute;
      border: 1px solid $nutrien-table-border;
      border-radius: 40px;
      top: 17px;
      padding: 5px;
      margin-left: 275px;
      display: block;

      svg {
        height: 12px;
        display: block;
      }
    }

    .search-clear-icon.hide {
      display: none;
    }
  }

  .header-search-field {
    display: none;
  }

  .icon-nav-section-container {
    margin-left: auto;
  }

  .header-search-field.show {
    .mdc-text-field {
      width: 325px;
      -webkit-transition: width 0.4s ease-in-out;
      transition: width 0.4s ease-in-out;
      padding-right: 7px;
    }

    .header-search-input {
      align-items: center;
      justify-content: center;
      line-height: 40px;
      padding: inherit;
    }
  }

  .header-search-field.hide {
    .mdc-text-field {
      -webkit-transition: width 0.4s ease-in-out;
      transition: width 0.4s ease-in-out;

      padding-right: 7px;
      border: none;
    }

    .mdc-text-field__input {
      display: none;
    }

    svg {
      text-align: right;
      margin-right: 0;
      margin-left: auto;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .header-search__form {
    vertical-align: middle;
    box-sizing: border-box;
    width: calc(100% - 50px);
  }
  .header-search__textfield {
    width: 100% !important;
    margin-top: 5px;
  }
}
