@import "~nutrien-style-guide/src/lib/colors/_colors.scss";

@mixin bold-label {
  font-weight: 600;
  color: black;
  margin-bottom: 14px;
}

@mixin grey-border-top {
  border-top: 1px solid $nutrien-grey--inactive-outline !important;
}

.control-label {
  color: #222;
  display: block;
  font-size: 0.9rem;
  font-weight: 600;
}

@mixin unstyled-button {
  background-color: transparent;
  border-radius: 0;
  box-sizing: content-box;
  border: none;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  padding: 0;
  text-align: start;
}
