@import "../../../../../App/colors.scss";

.nutrien-snackbar__dismiss {
  box-sizing: content-box;
}

.password-change-form {
  padding-top: 12px;

  .password-change-form__error {
    color: $nutrien-error;
    margin: 0;
    padding: 0 0 12px 0;
  }

  .password-change-form__error-notification {
    padding-bottom: 12;
  }

  .password-change-form__common-password-modal-content-title {
    color: $nutrien-text-tertiary;
    display: block;
    font-size: 13px;
    font-weight: 200;
    line-height: 1.15;
    margin: 20px 10px;
  }
}
