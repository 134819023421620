@import "~nutrien-style-guide/src/lib/colors/_colors.scss";

.po__wrapper {
  display: flex;
}

.po__label {
  flex-wrap: wrap;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 24px 0 0 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media (min-width: 435px) {
    flex-wrap: column;
  }
}

.po__label__text {
  margin-right: 16px;
  padding: 20px 0 0 0px;
  width: 100%;
  @media (min-width: 435px) {
    width: auto;
  }
}

.po__number-input {
  border-color: #ccc;
  border-radius: 3px;
  border-style: solid;
  border-width: 0.5px;
  font-size: 14px;
  height: 40px;
  margin-top: 12px;
  padding: 16px;
  touch-action: manipulation;
  width: 100%;

  @media (min-width: 435px) {
    max-width: 181px;
  }
}
