@import "~nutrien-style-guide/src/lib/colors/_colors.scss";

.account-menu {
  font-size: 0.5em;
  position: absolute;
  right: 1.25em;
  top: 0.2em;
  padding: 0em;
  min-width: 23em;
}

.account-menu-container {
  max-width: 1280px;
  display: block;
  margin: auto;
  width: 100%;
}

// This exists because the account menu needs box shadow, but the z-index
// of the arrow needs to be higher than the box shadow on the menu,
// and the arrow needs to be below the menu itself.
.account-menu__box-shadow {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
}

.account-menu--white {
  background-color: $nutrien-white;
  -webkit-box-shadow: 0 2px 4px -4px rgba(0, 0, 0, 0.25);
  transform: rotate(0);
}

.open-account-menu {
  transition: 1s ease;
  transform: translateY(0);
}

.account-menu-section {
  padding: 1em;
  border-top: 1px solid #cccccc;
}

.account-menu-user {
  display: flex;
  justify-content: left;
  padding: 1em;
}

.account-menu a,
.account-menu__link {
  cursor: pointer;
  line-height: 1.36;
  color: $nutrien-green;
}

.account-menu__link:hover {
  filter: brightness(85%);
}

.arrow-up {
  width: 30px;
  height: 30px;
  transform: rotate(45deg);
  background-color: white;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5);
  margin: 10px 30px -20px auto;
}

@media only screen and (max-width: 840px) {
  .account-menu-container {
    display: none;
  }
}
