.hub-payments-modal__container {
  padding-top: 50px;

  .mdc-dialog__surface {
    min-width: 850px;

    .hub-payments-modal__close-button {
      display: flex;
      align-self: flex-end;
    }

    .hub-payments-modal__dialog-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .hub-payments-modal__title {
      color: var(--Greys-Grey-800, #222);
      text-align: center;
      font-family: 'Open Sans';
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }
    .hub-payments-modal__sub-title {
      color: var(--Greys-Grey-800, #222);
      text-align: center;
      font-family: 'Open Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 16px;
    }
    .hub-payments-modal__image {
      display: flex;
      width: 176px;
      height: 382px;
    }

    .hub-payments-modal__link-button {
      margin-bottom: 8px;
    }

    @media (max-width: 950px) {
      min-width: 680px;
    }

    @media (max-width: 800px) {
      min-width: 250px;
    }
  }
}
