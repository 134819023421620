.invoice-status__pending {
  display: inline-flex;
  align-items: center;

  svg {
    height: 40px;
    width: 40px;
    position: relative;
    left: -15px;
  }
}

.pending-invoice-text {
  position: relative;
  left: 0;
  top: 5px;
}
