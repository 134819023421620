.top-products__error-message {
  min-width: 275px;
}

.top-products__error-message .api-error-message__icon {
  width: 2em;
  height: 2em;
}

.top-products__no-products-message {
  display: block;
  text-align: left;
  width: 100%;
}

.top-products__link-container {
  margin-top: 15px;
}

.top-products__table {
  width: 100%;
}

.product-history-container__title {
  display: flex;
  justify-content: space-between;
}

.product-history-container__title-timeframe {
  padding: 0 10px;
  padding-top: 5px;
  top: 10px;
  font-size: 14px;
  color: #666; /* :the_horns: */
}

.top-products__snackbar-checkcircle {
  flex-shrink: 0;
  margin-right: 16px;
}

.top-products__snackbar-content {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .top-products__table {
    font-size: 16px;
  }
  .product-history-container__title {
    display: block;
  }
  .product-history-container__title-timeframe {
    padding-left: 3px;
    padding-top: 0;
  }
  .top-products__snackbar-mobile {
    padding: 20px;
  }
  .top-products__table-row {
    display: flex;
    flex-direction: column;
    height: inherit;
    width: 100%;
    padding: 20px 16px;
  }
  .top-products__table-row-item {
    padding-bottom: 8px;
  }
  .top-products__table-button {
    padding-top: 8px;
  }
}
@media only screen and (max-width: 320px) {
  .top-products__table-row {
    padding: 15px 0px;
  }
  .top-products__table-row-item {
    padding-bottom: 8px;
  }
  .top-products__table-button {
    padding-top: 8px;
  }
}
