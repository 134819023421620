.error-icon {
  fill: #e1af2e;
  margin-right: 12px;
}

.payment-methods-container {
  margin-top: 15px;
}

.payment-methods-header {
  display: flex;
  margin-bottom: 10px;
}

.payment-methods-header div {
  height: 35px;
}

.payment-methods-header-title {
  font-size: 22px;
  margin-bottom: 10px;
  width: 70%;
}

.payment-method-header-buttons {
  display: inline-flex;
  justify-content: flex-end;
  margin: 0;
  width: 30%;
}

.add-bank-account-button {
  display: flex;
  align-items: center;
  border: 0;
  min-width: 170px;
  color: #50a200;
  font-size: 14px;
  font-weight: 600;
}

.add-bank-account-button:hover {
  cursor: pointer;
}

.add-bank-icon {
  margin-right: 5px;
}


.payment-methods-separator {
  min-height: 1px;
  background: #ccc;
  border: 0;
  margin: 0 -15px;
}

@media (max-width: 992px) {
  .payment-methods-header-title {
    width: 60%;
  }

  .payment-method-header-buttons {
    width: 40%;
  }

  .payment-methods-header {
    min-height: 65px;
  }
}

@media (max-width: 460px) {
  .payment-methods-header-title {
    width: 100%;
  }

  .payment-method-header-buttons {
    width: 100%;
  }

  .payment-methods-header {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .payment-method-header-buttons {
    justify-content: flex-start;
  }

  .add-bank-account-button {
    padding: 0;
  }
}