.invoice-date-range-modal {
  z-index: 300;
  .mdc-dialog__surface.mdc-dialog__surface {
    max-width: 375px;
  }

  .invoice-date-range-modal__datepicker-container {
    min-height: 400px; // ensures month size doesn't alter height of modal.
  }
}
