@import "../../../styles/_index.scss";

// NOTE: the important rules in these styles can be removed when older material components are removed from the jsx
.address-form {
  &__dialog-header {
    display: flex;
    justify-content: space-between;
    min-height: 65px;
    pointer-events: all;
  }

  &__dialog-header--close-icon {
    margin-right: 18px;
    margin-top: 20px;
    font-size: 27px !important;
  }

  &__dialog-label--optional {
    margin-left: 5px;
    font-weight: initial;
    font-size: 12px;
  }

  &__dialog-content {
    padding-top: 0;
    pointer-events: all;
  }

  &__dialog {
    pointer-events: none;
  }

  &__outer-dialog-wrapper {
    padding-top: 32px;
  }

  &__add-button {
    background-color: transparent;
    border: none;
    color: #4c9e00;
    font-size: 0.8rem;
    line-height: 1.25;
    cursor: pointer;
    display: flex;
    margin: 0 0 24px -8px;
  }

  &__add-icon {
    height: 0.4em !important;
    margin: 2px 4px 0 0;
    width: 0.4em !important;
  }

  &__overall-error-container {
    border: 1px solid #d24242;
    border-radius: 5px;
    background-color: #feeded;
    display: flex;
    align-items: center;
  }

  &__feedback-warning-icon {
    color: #d24242;
    height: 24px;
    width: 24px;
    margin: 10px;
  }

  &__feedback-error-message {
    align-self: center;
    margin: 10px 10px 10px 0;
  }

  &__form-control {
    @include form-control;
    margin-top: 10px !important;
  }

  &__form-error {
    @include form-error;
  }

  &__form-label {
    @include form-label;
  }

  &__fieldset-col {
    @include fieldset-col;
  }

  &__fieldset {
    @include fieldset;
  }

  &__inner-fieldset-wrapper {
    @include inner-fieldset-wrapper;
  }

  &__input {
    @include input;
  }

  &__input-error {
    @include input-error;
  }

  &__button-wrapper {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding-top: 24px;
    @media (min-width: 500px) {
      flex-direction: row;
    }
  }

  &__dialog--last-name,
  &__dialog--first-name {
    padding-top: 0 !important;
  }

  &__address-button-wrapper {
    display: flex;
    padding: 24px 0 0;
    justify-content: flex-end;
  }

  &__input-error {
    @include input;
    color: #d24242;
    border-color: #d24242;
  }

  &__address-button {
    height: 40px;
    margin-bottom: 32px;
    margin: 0px;

    @media (min-width: 500px) {
      margin-bottom: 0px;
    }
  }

  &__address-button-cancel {
    height: 40px;
    margin: 0px 12px;
    min-width: 100px;
  }

  &__dropdown-wrapper {
    display: flex !important;
    margin-top: 24px !important;
  }
}
