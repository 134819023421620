@import '~nutrien-style-guide/src/lib/colors/_colors.scss';
@import '../../../Cart/styles/index.scss';

// !LOCAL VARS-------------------------------------------------------------
$break-small: 1000px;
$break-mid: 1350px;

// !WRAPPERS-------------------------------------------------------------
.orders-page__header {
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.orders-page__section {
  background-color: $nutrien-light-gray-1;
  border: 1px solid $nutrien-table-border;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 12px;

  @media (min-width: $break-mid) {
    flex-direction: row;
  }

  @media print {
    background-color: transparent;
    flex-direction: row;
    font-size: 0.8rem;
  }
}

.orders-page__print-wrapper {
  cursor: pointer;
  display: flex;

  @media print {
    display: none;
  }
}

.orders-page__section--inner {
  display: flex;
  flex-direction: column;
  padding: 16px 0;

  @media (min-width: $break-small) {
    padding: 0;
  }
}

.orders-page__info-wrapper {
  + .orders-page__info-wrapper {
    @media (min-width: $break-mid) {
      padding-left: 24px;
    }
  }

  @media print {
    display: flex;
    flex-direction: column;
    width: 25%;
  }
}

.orders-table-outer-wrapper {
  padding: 24px 0 0;

  + .orders-table-outer-wrapper {
    @media (min-width: $break-small) {
      margin-top: 54px;
    }
  }
}

.orders-item-wrapper {
  @include inner-cart-container-bottom;
}

.orders-item__content {
  padding-top: 24px;

  @media (min-width: $break-small) {
    display: flex;
    justify-content: space-between;
  }

  @media print {
    display: block;
  }
}

.orders-item__content--secondary {
  display: flex;
  flex-direction: column;
}

.orders-item__section {
  align-self: flex-end;
}

.orders-item__total-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 8px 0 12px 0;
}

.order-page__status-wrapper {
  display: inline-block;
  padding: 0 0 12px 0;
}

.order-page__loading-wrapper {
  display: flex;
  justify-content: center;
}

.order-page__contact-wrapper {
  display: none;
  font-size: 0.8rem;

  @media print {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 56px 0;
  }
}

.orders-page__header-inner-wrapper {
  display: flex;
}

.orders-page__consultant-wrapper {
  display: none;

  @media print {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
  }
}

.orders-table__total-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
}

.orders-table__headline-wrapper {
  display: flex;
  justify-content: space-between;
}

// TEXT-------------------------------------------------------------
.orders-page__info-headline {
  font-size: 0.9rem;
  font-weight: 600;

  @media print {
    font-size: 0.8rem;
    font-weight: 400;
  }
}

.orders-page__print-tout {
  color: $nutrien-green;
  padding-left: 8px;
}

.orders-page__header__headline {
  font-size: 1.4rem;
  font-weight: 600;
  max-width: 200px;

  @media (min-width: $break-small) {
    font-size: 2rem;
    max-width: none;
  }

  @media print {
    font-size: 0.9rem;
    max-width: none;
    padding-left: 12px;
  }
}

.orders-page__blurb {
  font-style: italic;
  max-width: 360px;
}

.orders-page__info {
  font-size: 0.9rem;
  padding: 0 12px;

  @media print {
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0;
  }
}

.orders-page__info--hide-print {
  font-size: 0.9rem;
  padding: 0 12px;

  @media print {
    display: none;
  }
}

.orders-page__info--print {
  display: none;

  @media print {
    display: inline-block;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0;
  }
}

.orders-item__total {
  @include sub-total;

  padding-top: 24px;

  @media (min-width: $break-small) {
    padding-top: 0;
  }
}

.orders-item__label {
  color: $nutrien-gray-3;
  padding-right: 40px;
}

.orders-table__headline {
  font-size: 1.3rem;
  font-weight: 600;

  @media print {
    font-size: 1rem;
  }
}

.order-page__status {
  font-size: 0.9rem;

  @media print {
    display: none;
  }
}

.orders-page__consultant-detail__headline {
  font-size: 0.8rem;
}

.orders-page__consultant-detail__info {
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 12px;
}

.orders-table__price {
  display: none;
  font-weight: 600;

  @media (min-width: $break-small) {
    display: block;
  }

  @media print {
    display: block;
  }
}

.orders-table__price--mobile {
  display: block;

  @media (min-width: $break-small) {
    display: none;
  }

  @media print {
    display: none;
  }
}

// IMAGES-------------------------------------------------------------
.order-page__logo {
  border-right: 1px solid $nutrien-dark-gray-2;
  display: block;
  height: 30px;
  padding-right: 12px;
}

.order-page__logo-wrapper {
  display: none;

  @media print {
    display: block;
  }
}
