@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

@mixin green-outline {
  border: solid 1px $nutrien-green;
}

$dropDownOptionsFontSize: 0.875rem;

.dropdown__item {
  width: 100%;
  min-height: 40px;
  padding: 12px;

  // For IE10+ ignore the height style.
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    height: initial;
    padding-bottom: 30px;
  }

  &:hover {
    background-color: #f6f6f6;
    cursor: pointer;
  }
}

.dropdown__item--selected {
  background-color: #e9e9e9;
}

.dropdown__option-name {
  display: flex;
  flex: 1;
}

.dropdown__error {
  border-color: #d24242 !important;
  margin-bottom: 10px !important;
}

.dropdownButton {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border: solid 1px #cccccc;
  border-radius: 4px;
  height: 39px;
  margin-bottom: 3px;
  padding: 0px 12px;
  &:active {
    @include green-outline;
  }
  &:focus {
    @include green-outline;
  }
}

.dropdownOptions {
  font-size: $dropDownOptionsFontSize;
  background-color: #ffffff;
  border-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  z-index: 100;
  position: absolute;
  right: 0;
  left: 0;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
}

.dropdownButtonIcon {
  color: #a0a0a0;
  font-size: 1.5rem !important;
  bottom: 0;
  margin: 0;
}

.dropdownButtonPlaceholder {
  color: $nutrien-dark-gray-2;
  margin: 0;
  font-size: $dropDownOptionsFontSize;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.dropdownOptionsClosed {
  display: none;
}

.dropdownContainer {
  position: relative;
  width: 100%;
  height: 40px;
}
