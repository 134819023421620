.payment-method-name-container {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.payment-method-name {
  margin: 0;
  font-size: 14px;
  color: #222222;
  word-break: break-all;
}

.payment-method-details {
  font-size: 12px;
  color: #666666;
}

.payment-method-name-container img {
  margin-right: 15px;
}

.payment-method-edit-container {
  padding-top: 15px;
}

.payment-row-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.payment-row-buttons button {
  -webkit-appearance: none;
}

.payment-row-buttons button:hover {
  cursor: pointer;
}

.edit-save {
  margin-right: 15px;
}

.payment-method-edit {
  color: #4C9E00;
  border: none;
  font-size: 14px;
}

.payment-method-edit:hover {
  color: #6FC202;
  outline: none;
}

.payment-method-row {
  display: flex;
  min-height: 50px;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

.payment-method-row.open {
  flex-direction: column;
}

.payment-method-edit-form {
  display: flex;
  flex-direction: column;
  margin-left: 60px;
}

.payment-method-edit-form label,
.payment-method-edit-form input,
.payment-method-edit-form button {
  margin-right: 15px;
}

.payment-method-edit-form input {
  background-color: white;
  height: 40px;
  max-width: 400px;
}

.edit-form-buttons {
  display: flex;
  margin-top: 15px;
}

.edit-form-buttons button {
  width: 77px;
  height: 40px;
}

@media (max-width: 460px) {
  .payment-method-name-container {
    flex-grow: 2;
  }

  .payment-row-buttons {
    display: flex;
    flex-grow: 1;
  }

  .payment-row-buttons button {
    width: 35px;
    height: 30px;
    padding: 0;
    margin: 0;
  }

  .payment-method-edit-form {
    display: flex;
    flex-direction: column;
    margin-left: 55px;
  }

  .payment-method-edit-form input {
    width: 100%;
    background-color: white;
  }

  button.edit-save {
    margin: 10px 15px 10px 0;
  }

  button.edit-cancel {
    margin: 10px 0;
  }
}
