@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

// TODO: Refactor into multiple files that live next to the relevant components
.bottom-white {
  display: none;
}

.checkout {
  font-size: 1.7rem;
  font-weight: 400;
  align-self: center;
}

#desktop-hamburger-menu {
  display: flex;
  align-items: center;
  height: 0;
  width: 2em;
  cursor: pointer;
}

#desktop-hamburger-menu:focus {
  outline: none;
}

.dismiss-menu {
  display: none;
}

.editcart {
  align-self: center;
  color: $nutrien-green;
  cursor: pointer;
  font-size: 0.5em;
}

.editcart:hover {
  filter: brightness(85%);
}

.header__notification-link-icon {
  color: $nutrien-grey-3;
}

.menu__item.selected
  > .mobile-header__notification-button
  > .header__notification-link-icon {
  color: $nutrien-green;
}

button.header__notification-button:hover {
  /* extra specificity is required to override materialui. */
  background-color: transparent;
}

.header__notification-button:hover .header__notification-link-icon,
.menu__item:hover
  > .mobile-header__notification-button
  > .header__notification-link-icon,
.header-content .menu__item.mobile-header__icon-link:hover {
  color: $nutrien-green;
}

.header-title {
  display: flex;
  -ms-align-items: center;
  align-items: center;
  font-size: 0.9em;
  color: $nutrien-grey-2;
  font-weight: 600;
}

.header-title__divider {
  background-color: $nutrien-grey-2;
  height: 1.1em;
  width: 2px;
}

.header-title__subtitle {
  color: $nutrien-green;
}

.header-title-container {
  height: 2em;
  display: flex;
}

.header__user-button__name {
  margin: 0 1.75em;
}

.header__user-button__caret {
  margin: 0 1em;
}

.header-user__info {
  display: flex;
  flex-direction: column;
}

.header-user__info__name {
  font-size: 1.2em;
}

.header-user__info__email {
  font-weight: normal;
  color: $nutrien-grey-2;
  font-size: 0.9em;
}

.header-user-container--desktop {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 23px;

  @media only screen and (min-width: 500px) {
    width: 87px;
  }
}

.header-user-container--desktop:focus {
  outline: none;
}

.header-user-container--desktop > .responsive-menu__button {
  background: none;
  border: none;
  height: 0.5em;
  outline: none;
  padding: 0;
  position: relative;
  right: 1.2em;
  top: 0.05em;
  width: 1.2em;
  z-index: 250;
  -webkit-tap-highlight-color: transparent;
}

.header-wrapper {
  font-size: 30px;
  left: 0;
  position: relative;
  right: 0;
  top: 0;
  z-index: 200;

  @media print {
    display: none;
  }
}

.invisible-tab {
  padding: 0 !important;
  width: 0 !important;
}

.menu__item--mobile-only {
  display: none;
}

#responsive-hamburger-menu {
  display: none;
}

.responsive-menu__button:before,
.responsive-menu__button:after,
.responsive-menu__button span {
  background: $nutrien-dark-grey-2;
  position: absolute;
  width: 1em;
  height: 3px;
  border-radius: 1px;
}

.responsive-menu__button:before,
.responsive-menu__button:after {
  content: '';
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  transition: transform 0.25s;
}

.responsive-menu__button:before {
  transform: translate3d(0, -9px, 0);
}

.responsive-menu__button:after {
  transform: translate3d(0, 9px, 0);
}

.responsive-menu__button span {
  overflow: hidden;
  text-indent: 200%;
  transition: opacity 0.25s;
}

.responsive-menu__button.open span {
  opacity: 0;
}

.responsive-menu__button.open:before {
  transform: rotate3d(0, 0, 1, 45deg);
}

.responsive-menu__button.open:after {
  transform: rotate3d(0, 0, 1, -45deg);
}

.signout {
  align-self: center;
  font-size: 0.875rem;
  font-weight: 400;
  cursor: pointer;
  color: $nutrien-green;
}

.signout:hover {
  filter: brightness(85%);
}

span.beta {
  position: relative;
}

span.beta > img {
  top: -9px;
  position: absolute;
  right: -17px;
}

span.beta_fms {
  position: relative;
}

span.beta_fms > img {
  top: -9px;
  position: absolute;
  right: -17px;
}

@media only screen and (max-width: 900px) {
  .bottom-white {
    background-color: $nutrien-white;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    width: 100%;
  }

  #desktop-hamburger-menu {
    display: none;
  }

  .dismiss-icon {
    color: $nutrien-white;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0.5em;
    height: 2em;
    width: 2em;
  }

  .dismiss-icon-container {
    height: 2em;
    width: 2em;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
  }

  .header__notification-button--mobile-only {
    position: relative;
  }

  .header-title {
    margin-left: 0;
  }

  .header-user__image {
    margin: 0 1.5em 0 0.5em;
  }

  .nav-tab__bar {
    margin-left: 0;
  }

  .open-menu {
    max-height: none;
  }

  #responsive-hamburger-menu {
    display: flex;
    align-items: center;
    height: 2em;
    width: 2em;
    margin-right: -20px;
    cursor: pointer;
    position: relative;
  }

  #responsive-hamburger-menu:focus {
    outline: none;
  }

  .responsive-menu__button {
    top: 5px;
    right: 0;
  }

  .responsive-menu__button:before,
  .responsive-menu__button:after,
  .responsive-menu__button span {
    width: 1em;
  }
}

@media only screen and (max-width: 600px) {
  .header-title {
    font-size: 0.7em;
  }
}

@media only screen and (max-width: 720px) {
  .checkout {
    font-size: 1.2rem;
  }

  .header-content {
    justify-content: flex-start;
  }

  .responsive-menu__icon {
    order: -1;
  }

  .signout {
    font-size: 0.875rem;
  }
}

@media only screen and (max-width: 300px) {
  .header-user__image {
    display: none;
  }
}

/**************************** new styles ******************************/

.employee-menu {
  align-items: center;
  cursor: pointer;
  height: 2em;
  flex-direction: row;
  float: right;
  position: relative;
  padding: 0;
  transform: translateY(0);
  -webkit-transform: translateY(0);
}

.header {
  background-color: $nutrien-white;
  color: $nutrien-dark-grey-2;
  height: auto;
  font-weight: 600;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.header--browser-not-supported {
  height: 8.5em;
}

.header-content {
  display: none;
}

.header-content .employee {
  display: flex;
}

.header-content .menu__item.selected > div > svg {
  fill: $nutrien-green;
}

.header-content .menu__item,
.mobile-header__menu-item,
.mobile-header__submenu-item {
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  padding: 12px;
  cursor: pointer;
}

.header-content .menu__item {
  padding: 0 12px;
  margin: auto 0;
  color: $nutrien-grey-3;
}

.header-content > a.menu__item {
  border-bottom: 3px solid $nutrien-white;
  border-radius: unset;
  padding: 16px;
}

.header-content .menu__item:hover {
  color: $nutrien-green;
}

.header-content--all-widths {
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 0.5em;
  background: $nutrien-white;
  height: 60px;

  .logo--medium {
    display: none;
  }
}

.header-content__cart-services {
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  padding: 0 12px;
  width: 100%;
  .header-content__cart--review {
    display: none;
  }
}

.header-content-wrapper {
  position: relative;
  background: $nutrien-white;
}

.header__links--desktop {
  display: flex;
  font-size: 0.5em;
  margin-right: 8px;
}

.header-vertical-line {
  align-self: flex-start;
  border-left: 1px solid $nutrien-table-border;
  display: block;
  height: 60px;
  padding: 0;
}

.icon-nav-section-container {
  display: flex;
  margin-right: 0;
  margin-left: auto;
  align-items: center;
  justify-content: space-between;
  margin-left: 4px;
  margin-right: 4px;

  @media only screen and (min-width: 500px) {
    width: 145px;
  }

  @media only screen and (min-width: 700px) {
    margin-right: 20px;
    .menu__item {
      padding: 0 14px 0;
    }
  }
}

.profile-icon {
  color: #646464;
  width: 40px;
  height: 40px;
  font-size: 40px;
}

///////////////////////////////// DESKTOP VERSION : START /////////////////////////////////

@media only screen and (min-width: 1280px) {
  .category-nav .desktop-plp-search-field {
    display: block;
    width: 317px;
  }

  .header-wrapper {
    &.mirroring {
      height: 5vh;
      max-height: 54px;
    }

    .header-content--all-widths {
      padding: 0 1em;
    }

    .header {
      border: none;
      border-bottom: 2px solid $nutrien-green;
    }
  }

  .header-wrapper--browser-not-supported {
    height: 236px;
  }

  .header-content {
    width: 100%;
    margin: auto;
    display: inherit;
    flex-direction: column;

    .nav-tab__bar {
      border: none;

      .nutrien-tab {
        height: 59px;
        letter-spacing: normal;
        outline: none;
      }

      .menu__item {
        border-radius: 0;
        margin-right: 0;
        padding: 17px 17px 18px;
        overflow: hidden;
        will-change: unset;
      }

      .menu__item:hover {
        background-color: rgba(76, 158, 0, 0.12);
        padding-bottom: 21px;
      }

      .menu__item.selected {
        border-radius: unset;
        border-bottom: 3px solid $nutrien-green;
        color: $nutrien-green;
        will-change: unset;
      }

      .menu__item.selected:hover {
        padding-bottom: 18px;
      }
    }
  }

  .header-content-wrapper {
    display: -ms-flexbox;
    -ms-flex-pack: justify;
    display: flex;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1280px;
    width: 100%;
    max-height: 60px;
    &.mirroring {
      display: block;
    }
  }

  .header-content__cart-services {
    display: reset;
    padding: reset;
    width: reset;
    .header-content__cart--review {
      display: block;
    }
  }

  .header-logo {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 24px;

    .logo--medium {
      display: flex;
    }

    .logo--small {
      display: none;
    }
  }

  .header-logo--flagged {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 1.5rem;

    .logo--medium {
      display: flex;
    }

    .logo--small {
      display: none;
    }
  }
}

///////////////////////////////// DESKTOP VERSION : END /////////////////////////////////

@media only screen and (min-width: 300px) {
  .employee .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.5em;
    max-width: 1280px;
    margin: auto;
    width: 100%;
  }

  .employee .menu {
    display: flex;
  }

  .employee .mobile-section__button-container {
    left: unset;
  }
}
