.support-fab__container {
  position: fixed;
  z-index: 99999;
  display: block;
  bottom: 35px;
  right: 35px;
  padding: 0;
}

@media (max-width: 768px) {
  .support-fab__container {
    bottom: 18px;
    right: 18px;
  }
}
