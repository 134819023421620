.accounts-tab {
  &__button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }
  &__sectionHeader {
    font-size: 22px;
    padding-bottom: 20px;
  }
  &__table {
    width: 100%;
  }
  &__table-header {
    font-weight: 600;
  }
  &__mobile-display {
    display: none !important;
  }
  &__desktop-hidden {
    display: none !important;
  }
  &__table-row {
    border-bottom: 0.0625rem solid #cccccc;
  }
  &__nickname-input {
    margin: 0.25rem 0;
    width: auto;
    height: 2rem;
    border-radius: 0.25rem;
    background-color: #ffffff;
    border: solid 0.0625rem #cccccc;
    padding-left: 1rem;
    width: 100%;
  }

  @media only screen and (max-width: 480px) {
    &__button {
      width: 100%;
    }
    &__button-container {
      padding: 0 16px;
      flex-direction: column;
      align-items: center;
    }
    &__desktop-hidden {
      display: block !important;
    }
    &__mobile-hidden {
      display: none !important;
    }
    &__mobile-display {
      display: block !important;
    }
    &__mobile-heading {
      font-weight: 600 !important;
    }
  }
}
.errorContainer {
  margin-bottom: 20px;
  float: right;
}
