.invoice-search-container {
  position: relative;
  right: 290px;
  transition: opacity 0.5s ease-in-out;
}

.invoice-search-absolute {
  position: absolute;
  border: 1px solid gray;
  width: 400px;
  border-radius: 4px;
  margin-top: 5px;
  background-color: white;
}

.invoice-search-field-wrapper {
  background: #f0f0f0;
  height: 60px;
  margin-top: 5px;
  padding-left: 10px;
}

.invoice-search-option-container {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.invoice-search-sku {
  font-size: 12px;
  color: #7b7b7b;
}

.invoice-search-text-field {
  border: 1px solid lightgray;
  width: 85%;
  margin-top: 15px;
}

.invoice-search-title {
  margin-left: 10px;
  margin-top: 5px;
  font-size: 12px;
  font-weight: bold;
}

@media (max-width: 825px) {
  .invoice-search-absolute {
    width: 100%;
  }
  .invoice-search-container {
    right: 0;
  }
}
