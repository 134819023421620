.breadcrumb_link_item {
  padding: 0 3px;
  font-weight: 400;
  color: #4c9e00;
}

.breadcrumb_link_item:hover {
  text-decoration: underline;
}

.breadcrumb_current-location {
  padding: 0 3px;
  color: #464646;
  font-weight: 200;
}

.breadcrumb__link__separator {
  padding: 0 3px;
  font-weight: 200;
  color: #828282;
}

.breadcrumb {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #23c068;
}

.breadcrumb:first-child .breadcrumb__link__separator {
  display: none;
}

.breadcrumb a:hover {
  text-decoration: none;
}
.breadcrumb__link__wrapper
.breadcrumb__link__back-caret {
  font-weight: 400;
  vertical-align: middle;
  margin: -2px -5px 0 0;
}

@media only screen and (max-width: 768px) {
  .breadcrumb__link__back-caret {
    display: inherit;
  }

  .breadcrumb__mobile__hide {
    display: none;
  }

  .breadcrumb__link__separator {
    display: none;
  }
}
