.full-page {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  background: #fff;
}

.full-page__card {
  box-shadow: 0 2px 5px #bbb;
}

.full-page-header {
  flex: 100%;
  display: block;
  padding: 0;
}

.full-page-title {
  font-weight: normal;
  margin: 5px 20px;
}
