table.invoices-list {
  border-top: none;
  margin: 0;
}

.accounting-list-title {
  margin: 0;
}

.invoice-list-container {
  padding: 0 20px;
}

.account-actions__container {
  display: flex;
  position: relative;
  padding: 0 10px;
  width: 300px;
}
.account__download-link-container {
  width: 100%;
}

.account__invoiced-products-search-container {
  position: absolute;
  top: -75px;
  left: 50px;
  z-index: 1;
}

.invoice-header-bar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 20px 0;
}

.invoice-actions {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.invoice-action-discount {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  color: #757575;
  font-size: 12px;
  opacity: 0.87;
}

.invoice-sort-label {
  margin-right: 25px;
  font-size: 14px;
  font-weight: 600;
}

.invoice-period-label {
  margin-right: 10px;
  font-size: 14px;
  font-weight: 600;
}

th.header-label {
  color: #828282;
}

button.invoice-action-button {
  height: 32px;
  min-height: 30px;
  width: 157px;
  margin: 0 0 0 16px;
  animation: slide-down 0.5s ease;
}

.invoices-list th {
  border: none;
}

.invoice-header tr {
  border-top: none;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.invoice-selection-options {
  display: flex;
  justify-content: space-around;
  padding: 0 10px;
}

.invoice-OFX-Dialog-Actions-Container {
  display: flex;
  width: 350px;
}

button.invoice-OFX-Dialog-Actions-Cancel {
  margin-right: 10px;
  width: 200px;
}

.invoice-error-notification {
  margin-bottom: 15px;
}

@media screen and (max-width: 1000px) {
  table.invoices-list {
    display: block;
    white-space: nowrap;
  }

  .invoices-desktop-pagination {
    display: none;
  }
}

@media screen and (max-width: 825px) {
  .invoice-header-bar {
    margin-top: 30px;
  }
  .invoice-sort-label {
    display: none;
  }

  .invoice-period-label {
    display: none;
  }

  button.invoice-OFX-Dialog-Actions-Cancel {
    margin-right: 10px;
  }

  button.invoice-action-button {
    margin: 0 0 0 5px;
    width: 100%;
  }

  .invoice-OFX-Dialog-Actions-Container {
    width: 100%;
    flex-direction: column;
  }

  button.invoice-OFX-Dialog-Actions-Confirm {
    order: 0;
    width: 100%;
    margin: 0;
  }

  button.invoice-OFX-Dialog-Actions-Cancel {
    order: 1;
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .account-actions__container {
    width: 100%;
    margin: 10px 0;
  }
  .account__invoiced-products-search-container {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
  }
  .invoice-header-bar,
  .invoice-actions {
    flex-direction: column;
  }
  .invoice-header-bar {
    justify-content: space-between;
    align-items: flex-start;
    margin: 10px 0;
  }
  .invoice-actions {
    width: 100%;
  }
  .invoice-selection-options {
    padding: 0 20px;
    width: 100%;
  }
}

.invoices-list .nutrien-table__cell.header-label {
  vertical-align: top;
}

.no-account-invoices {
  font-size: 1rem;
}
