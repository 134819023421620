@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

.payments-tab {
  &__container {
    margin-top: -1.875rem;
    margin-bottom: 2rem;
    padding: 0 1.25rem;
  }

  &__link {
    position: absolute;
    right: 0;
  }

  &__link-container {
    height: 2.5rem;
    display: flex;
    padding: 0;
    position: relative;
    align-items: center;
    border-bottom: 0.0625rem solid $nutrien-grey-60;
  }

  &__no-payments {
    border: 0.0625rem solid $nutrien-grey-60;
    display: flex;
    width: 100%;
    min-height: 18.75rem;
    align-items: center;
    justify-content: center;
  }

  &__table-container {
    padding: 0;
    display: flex;
    overflow: auto;
  }

  &__section-header {
    margin: 0;
    font-weight: 400;
  }

  &__section-link {
    color: $brand--success;

    .payments-tab__hub-button {
      font-size: 0.875rem;
      font-weight: 400;
      color: $nutrien-green;
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
    }
  }

  &__section-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 0 2.5rem 0;
    margin: 0 8px;
  }

  &__invalid-account-warning {
    font-size: 14px;
    margin-top: 10px;
  }

  &__delete-warning {
    margin-bottom: 10px;
  }
}

.payments-table {
  &__delete-button {
    background: none;
    border: none;
    color: $brand--success;
  }

  &__payment-details {
    text-align: left;
  }

  &__final-col {
    width: 12.5rem;
    text-align: right;
  }

  &__header {
    font-size: 1rem;
    font-weight: 600;
    padding-bottom: 0.5rem;
    text-align: left;
  }

  &__amount-column {
    float: right;
    margin: 0;
  }

  &__row {
    border-bottom: 0.0625rem solid $nutrien-gray-60;
    line-height: 3;
  }

  &__wrapper {
    width: 100%;
    border-bottom: 0.0625rem solid $nutrien-gray-60;
  }

  &__right-align {
    text-align: right;
  }

  .nutrien-table__cell {
    &.nutrien-table__cell__fixed {
      width: 18%;
    }
  }
}

@media (max-width: 950px) {
  .payments-tab {
    &__table-container {
      display: none;
    }

    &__link-container {
      border-bottom: none;
      padding: 2.5rem 0;
      height: 4.0625rem;
    }

    &__section-title {
      border-bottom: 0.0625rem solid $nutrien-grey-60;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
    }

    &__section-link {
      margin: 2.5rem 0;
      display: flex;
      align-self: flex-end;
    }

    &__link {
      margin-right: 0;
    }
  }
}
