.invoiced-product-rollup__container {
  .total-price__container {
    max-width: 400px;
    min-width: 200px;
    background-color: #4c9e00;
    border: 1px solid #438b00;
    border-radius: 4px;
    color: white;
    padding: 0px 15px;
    margin-bottom: 25px;

    .total-price__title {
      margin-top: 11px;
      font-size: 14px;
    }

    .total-price__amount {
      font-size: 30px;
    }

    .invoiced-product-rollup__details {
      float: right;
      font-size: 13px;
      cursor: pointer;
    }
  }
}

.invoiced-product-chips__container {
  display: flex;
  justify-content: left;
  align-items: center;
  width: fit-content;
  margin-bottom: 25px;
  flex-wrap: wrap;
  padding-top: 5px;
}

.invoice-product-details-modal__container {
  .align-text-right {
    text-align: right;
  }

  .invoiced-product-name-desktop {
    display: none;
  }

  .mdc-dialog {
    z-index: 9999;
  }
  .mdc-dialog__surface {
    max-width: 95% !important;

    .nutrien-dialog__content {
      padding: 0 10px 20px 10px;
    }
    .nutrien-table__head,
    .nutrien-table__body {
      font-size: 12px;
    }
  }
  .close-button {
    float: right;
    margin-top: 15px;
    margin-left: 15px;
    cursor: pointer;
  }

  @media only screen and (min-width: 768px) {
    .invoiced-product-name-mobile {
      display: none;
    }
    .invoiced-product-name-desktop {
      display: table-cell;
    }
    .mdc-dialog__surface {
      max-width: 70% !important;

      .nutrien-dialog__content {
        padding: 0 20px 20px 20px;
      }
      .nutrien-table__head,
      .nutrien-table__body {
        font-size: 14px;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .mdc-dialog__surface {
      max-width: 60% !important;

      .nutrien-table__head,
      .nutrien-table__body {
        font-size: 16px;
      }
    }
  }
}
