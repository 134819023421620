.product-financial-banner {
  margin: 36px auto 0 auto;
  max-width: 290px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12) !important;

  p {
    padding: 0 4px;
    font-weight: 600;
  }

  @media (max-width: 590px) {
    margin-top: 24px;
    max-width: 319px;
  }
}

.product-financial-banner__img {
  display: block;
  margin: 12px auto;
}

.product-financial-banner__button {
  width: 100%;
  margin-bottom: 24px !important;
}
