.payment-disabled-notification {
    align-items: center;
    background-color: #ffeaa0;
    border-radius: 4px;
    display: inline-flex;
    height: 60px;
    padding: 0 10px;
    position: relative;
    width: 388px;
}

.with-arrow::before {
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #ffeaa0;
    top: -10px;
    right: 55px;
    position: absolute;
    content: "";
    height: 0;
    width: 0;
}

.payment-disabled-notification p {
    color: #222222;
    font-size: 14px;
}

@media screen and (max-width: 768px) {
    .payment-disabled-notification {
        order: 0;
        width: 100%;
        margin-bottom: 15px;
    }
}