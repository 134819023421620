.full-page-background {
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  min-width: 100%;
  min-height: 100%;
  bottom: 0;
  position: fixed;
  -webkit-transition: all 2s; /* Safari */
  transition: all 2s, width 1s;
}

.full-page-background-container {
  left: 0;
  right: 0;
  top: 3.5em;
  bottom: 0;
  z-index: -1;
  position: fixed;
  min-width: 100%;
  min-height: 100%;
}

/* image for employee login pages */
.full-page-background--farmers-in-field {
  right: 0px;
  left: auto;
  top: auto;
}

/* image for customer registration pages */
.full-page-background--farmers-pointing {
  left: -1100px;
  top: 0;
}

@media screen and (min-width: 1420px) {
  .full-page-background--farmers-pointing {
    left: 0;
    top: 0;
  }
}
