.info-card-inner{
    height: 100%;
    border-radius: 3px;
    padding: 10px 15px;
    color:#fff;
    border: 1px solid #4c9e00;
    background: #4c9e00;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b455', endColorstr='#009c49',GradientType=0 );
}

.info-card{
    padding: 10px;
    width: 33.33%;
}

.info-card-title{
    color:inherit;
    margin:0;
    font-size: 13px;
}

.info-card__container{
    display: flex;
    flex-direction: row;
    align-content: stretch;
    width: 100%;
    padding: 10px;
    flex-wrap: nowrap;
    align-items: flex-start;
    align-items: stretch;
}

.info-card-h1{
    font-weight: normal;
    margin: 0;
    color:inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.info-card-subtitle{
    margin:0;
    font-size:.8em;
    color:inherit;
}


@media only screen and (max-width: 1135px){
    .info-card-h1{
        align-items: initial;
        flex-direction: column;
    }
}

@media only screen and (max-width: 768px){


    .info-card__container{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: wrap;
        height: 100%;
    }

    .info-card{
        width: 100%;
        margin:auto;
    }
}
