@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

.featured-partner__container {
  padding-top: 2.25rem !important;
}

.featured-partner__card--prominent {
  height: 100%;
}

.featured-partner__header {
  margin-bottom: 1rem;
}

.featured-partner__card-header {
  padding: 1rem;
}

.featured-partner__card-header__h4 {
  font-size: 1rem;
  margin: 0;
}

.featured-partner__logo-container {
  align-items: center;
  background: $nutrien-white;
  display: flex;
  height: auto;
  justify-content: center;

  img {
    display: block;
    max-width: 200px;
    padding-bottom: 1rem;
    width: 55%;
  }
}

.featured-partner__card-body {
  padding: 0 1rem;

  strong {
    display: block;
  }
}

.featured-partner__card-media {
  height: 9rem;
  background-size: 50% !important;
  max-width: 200px;
  margin: 0 auto;
}

.featured-partner__card-footer {
  padding: 1rem;
  display: flex;
  align-items: center;
}
