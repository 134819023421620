@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

$breakpoint-min: 320px;
$breakpoint-max: 768px;

.hero-header__text {
  color: $nutrien-white;
  font-weight: 300;
  font-size: 26px;

  @media screen and (min-width: $breakpoint-max) {
    font-size: 40px;
  }
}

.hero-header__name {
  display: block;
  @media screen and (min-width: $breakpoint-max) {
    display: inline;
  }
}
