@import "~nutrien-style-guide/src/lib/colors/_colors.scss";

.filter-wrapper--inner {
  background-color: transparent !important;
  box-shadow: none !important;
  font-size: 24px !important;
}

.filter-list-wrapper {
  flex-direction: column;
}
.filter-wrapper--outer {
  padding: 12px 0px;
  position: relative;

  &:after {
    border-top: 1px solid $nutrien-table-border;
    bottom: 0;
    content: "";
    height: 2px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    width: 90%;
  }
}
.filter-list-item {
  display: flex;
}
.filter-list--scroll {
  max-height: 480px;
  overflow-y: scroll;
  padding: 0;
}

.filter-list {
  padding: 0;
}

// !TEXT------------------------------------------------------------
.filter-wrapper__header {
  color: $nutrien-dark-grey-1;
}
.filter-list-item__title {
  align-items: center;
  display: flex;
  font-size: 0.75rem;
}

// !OTHER------------------------------------------------------------
.filter-wrapper--icon {
  color: $nutrien-dark-grey-1;
}
.filter-list-item__checkbox {
  margin-right: 8px;
  width: auto;
}
