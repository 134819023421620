$breakpoint-max: 768px;

.accounts-header__container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  h3 {
    margin-bottom: 0;

    .accounts-header__container-preferences {
      margin-left: 8px;
    }
  }
}

.accounts-card__fuel-warning {
  font-size: 14px;
}

.accounts-header__container > div > button {
  margin: 0;
}

.ecomm-promotion__container {
  margin-top: 13em;
  padding-bottom: 1em;
  z-index: 2;

  @media (min-width: 1280px) {
    margin-top: 12em;
  }
}

.landing__container {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
}

.no-ecommerce-banner {
  width: 1em;
  height: 14em;

  @media (min-width: 1280px) {
    height: 16em;
    width: 1em;
  }
}

.landing__container--browser-not-supported {
  margin-top: 175px;
}

.landing__link-arrow {
  height: 0.6em;
}

.landing__search-sds-link {
  font-size: 0.8em;
  padding: 0 10px;
}

.link__account-link {
  display: flex;
  align-items: center;
}

.link__account-link-text {
  padding-left: 8px;
}

.notifications__container h3 {
  display: inline;
}

.notifications__container .recent-products__container {
  @media screen and (min-width: $breakpoint-max) {
    flex-basis: 100%;
  }
}

.notifications__container .preferences-link__text {
  position: relative;
  top: -7px;
}

.notifications__container,
.accounts__container,
.orders__container,
.recent-orders__container,
.recent-products__container,
.product-history__container,
.featured-partner__container {
  display: block;
  flex-basis: 100%;
  padding: 0 0 1em 0;
}

@media screen and (max-width: 1080px) {
  .product-history__container,
  .accounts__container {
    border-bottom: 0.5px solid #c4c4c4;
    margin: 0 0 2em 0;
  }
  .product-history__container {
    padding: 0 0 2em 0;
  }
}

.preferences-link__text {
  display: flex;
}

@media screen and (max-width: 599px) {
  .weather-button {
    display: none !important;
  }
}

@media only screen and (max-width: 330px) {
  .accounts-header__container {
    display: inline-block;
    width: 100%;
  }
}
