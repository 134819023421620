@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

.mini-cart__item {
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  width: 100%;

  @media (max-width: 480px) {
    padding: 16px;
  }
}

.mini-cart__item__row {
  display: flex;
}

.mini-cart__item__row--centered {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mini-cart__item-header {
  font-size: 0.8rem;
  width: 50%;
  text-align: right;
  margin: 0 12px 4px 0;
}

.mini-cart__item-input,
.mini-cart__item-data {
  font-size: 0.8rem;
  font-weight: 600;
  padding-bottom: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 50%;
}
.mini-cart__item-data {
  overflow: hidden;
}
.mini-cart__item-input--input {
  display: inline-block;
  width: 75%;
}

.mini-cart__total-volume {
  display: flex;
  align-items: center;
}

.mini-cart__unit-of-measure-text {
  height: 16px;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: $nutrien-dark-grey-2;
  padding-left: 10px;
}

.mini-cart__input {
  border-style: solid;
  border-width: 0.5px;
  border-radius: 4px;
  border-color: #ccc;
  color: #464646;
  max-width: 100px;

  &:active,
  &:focus {
    border: 1px solid #0093e5;
    padding: 11.5px;
  }

  @media (min-width: 950px) {
    padding: 12px;
  }
}

.mini-cart__input-message-container {
  @extend .mini-cart__item__row--centered;
  height: 20px;
  min-height: 20px;
}

.mini-cart__input-message--error {
  @extend .mini-cart__item-data;
  color: $brand--danger;
  font-weight: 400;
  text-align: center;
  width: 100%;
}

.mini-cart__total-volume-input.mini-cart__total-volume-input {
  width: 100%;
}
