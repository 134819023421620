.top-products__cart-icon {
  width: 16px !important;
  height: 16px !important;
  margin-right: 10px;
  object-fit: contain;
}

.top-products__button-reorder {
  min-height: 32px !important;
  height: 32px !important;
  min-width: 148px !important;
  margin: 0 !important;
}

.top-products__button-column {
  width: 148px;
}

.top-products__table-columns {
  font-weight: 600;
  border-bottom: 0.5px solid #c4c4c4;
  height: 56px;
}

.top-products__table-row {
  height: 48px;
  border-bottom: 0.5px solid #c4c4c4;
}

.top-products__table-item--mobile-display {
  display: none;
}

@media only screen and (max-width: 840px) {
  .top-products__table {
    font-size: 14px;
  }
  .top-products__table-mobile-display-none {
    display: none;
  }
  .product-history__table-button,
  .top-products__button-column {
    width: 120px;
  }
}

@media only screen and (max-width: 640px) {
  .top-products__table-columns {
    display: none;
  }
  .top-products__table-sm-mobile-display-none {
    display: none;
  }
  .top-products__table-item--mobile-display {
    display: inline-block;
    font-weight: 600;
    width: 130px;
  }
  .product-history__table-button {
    width: 100%;
    margin: auto 0;
  }
}
@media only screen and (max-width: 480px) {
  .top-products__table-item--mobile-display {
    width: 115px;
  }
}
@media only screen and (max-width: 320px) {
  .top-products__table-item--mobile-display {
    width: 90px;
  }
  .product-history__table-button {
    width: 100%;
  }
}
