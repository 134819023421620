.password-suggestion__modal__helper__content ul {
  list-style: none;
  margin-left: -25px;
}

.password-suggestion__modal__helper__content li::before {
  color: #000;
  content: "•";
  font-weight: bold;
  margin-right: 10px;
  width: 1em;
}

.password-suggestion__modal__helper__content span {
  margin-left: 15px;
}
