@import "~nutrien-style-guide/src/lib/colors/_colors.scss";
@import "../../styles/_index.scss";

@mixin process-button {
  background-color: transparent;
  border: none;
  color: $nutrien-green;
  font-size: 0.8rem;
  line-height: 1.25;
}

.delivery-address {
  &__detail-section-wrapper {
    @include detail-section-wrapper;
    margin-bottom: 24px;
  }

  &__cart-headline {
    @include cart-headline;
    padding: 12px 24px;
  }

  &__address-wrapper {
    //TODO
  }
  &__default-address-wrapper {
    //TODO
  }

  &__checkout-button {
    @include process-button;
  }

  &__text {
    color: #000;
    font-weight: 200;
    font-size: 0.8rem;
  }

  &__blurb {
    @include cart-blurb;
  }

  &__text--emphasized {
    color: #000;
    display: block;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 12px;
  }

  &__form-error {
    @include form-error;
  }

  &__fieldset {
    @include fieldset;
  }

  &__fieldset-col {
    @include fieldset-col;
  }

  &__input {
    @include input;
  }

  &__form-error {
    @include form-error;
  }

  &__input-error {
    @include input-error;
  }

  &__inner-fieldset-wrapper {
    @include inner-fieldset-wrapper;
  }

  &__form-label {
    @include form-label;
  }

  &__secondary-radio-label {
    @include cart-sub-headline;
    font-size: 0.9rem;
  }

  &__cart-sub-headline {
    @include cart-sub-headline;
  }

  &__radio-button--secondary {
    @include cart-sub-headline;
  }

  &__header-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 24px;
    position: relative;
    &:after {
      bottom: 0;
      border-bottom: $nutrien-grey--inactive-outline;
      content: "";
      height: 1rem;
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      width: 90%;
    }
  }

  &__inner-detail-section-wrapper {
    display: flex;
    padding: 0 0 32px 32px;
  }

  &__loading-wrapper {
    width: 100px;
  }

  &__button-wrapper {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding-top: 24px;
    @media (min-width: 500px) {
      flex-direction: row;
    }
  }

  &__radio-wrap {
    display: flex;

    label {
      font-weight: 600;
    }
  }

  &__pickup-radio {
    align-self: flex-start;
  }

  &__change-button-outer-wrapper {
    padding: 0 12px;
  }

  &__outer-section-wrapper {
    padding: 8px 0;
  }

  &__radio-wrapper--secondary {
    display: flex;
    flex-direction: row !important;
    padding: 24px 0;
    @media (min-width: 750px) {
      padding: 0 0 24px 0;
    }
  }

  &__addressRadio {
    height: inherit;
    align-self: flex-start;
    width: 24px;
    margin: 0 12px;
  }

  &__change-button {
    @include process-button;
  }

  &__checkout-button {
    @include process-button;
  }

  &__address-button {
    @media (min-width: 500px) {
      margin: 0 !important;
    }
  }

  &__address-button-cancel {
    margin-top: 12px;
    min-width: 100px;

    @media (min-width: 500px) {
      margin: 0 12px;
    }
  }

  &__radio {
    align-self: flex-start;
    margin: 0 12px;
  }

  &__close-icon {
    margin-top: 10px;
  }
}
