.customer-list {
  min-width: 300px;
  width: 100%;
}

.customer-list-header {
  height: 79px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
}

.customer-list-title {
  font-weight: 600;
  margin-bottom: 0px;
}

.customer-filter {
  width: 250px;
  height: 39px;
  border-radius: 2px;
  border: solid 1px #cccccc;

  font-size: 14px;
  color: #464646;
  padding-left: 8px;
}

.header-hr {
  width: 0px;
}

.pagination {
  margin-top: 16px;
}

@media only screen and (max-width: 576px) {
  .customer-list {
    padding-left: unset;
    padding-right: unset;
  }

  .customer-list-header {
    flex-direction: column;
    height: 92px;
    padding-bottom: 10px;
  }

  .customer-list-title {
    font-size: 20px;
    align-self: flex-start;
    margin-left: 17px;
  }

  .customer-filter {
    align-self: stretch;
    margin-left: 17px;
    margin-right: 17px;
    width: unset;
  }
  
  .header-hr {
    padding: 0px;
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;
  }
}
