.hub-intercept-modal__container {
  padding: 24px 16px;
  text-align: center;

  @media screen and (min-width: 1024px) {
    padding: 40px 36px;
    text-align: left;
  }

  .hub-intercept-modal__close-container {
    position: absolute;
    right: 4px;
    top: 4px;
  }

  .hub-intercept-modal__content-container {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1023px) {
      max-width: 300px;
    }

    @media screen and (min-width: 1024px) {
      flex-direction: row;
      height: 280px;
    }

    .hub-intercept-modal_app-icon {
      height: 72px;
      object-fit: contain;
      filter: drop-shadow(0px 2px 21px rgba(0, 0, 0, 0.2));
      margin-bottom: 16px;
    }

    .hub-intercept-modal_platform-image {
      height: 100%;
      object-fit: contain;
      margin-right: 24px;
    }

    .hub-intercept-modal__message-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media screen and (min-width: 1024px) {
        height: 100%;
        max-width: 370px;
      }

      .hub-intercept-modal__message-copy {
        h2 {
          font-size: 21px;
          line-height: 28px;

          @media screen and (max-width: 1023px) {
            font-size: 18px;
            margin-bottom: 16px;
          }
        }

        .hub-intercept-modal__message {
          font-size: 12px;

          @media screen and (min-width: 1024px) {
            font-size: 16px;
            line-height: 24px;
          }
        }

        .hub-intercept-modal__message__cxh-kill-date {
          font-size: 12px;
          font-weight: 700;

          @media screen and (min-width: 1024px) {
            font-size: 16px;
            line-height: 24px;
            padding-bottom: 16px;
          }
        }
      }

      .hub-intercept-modal__native-badge {
        height: 44px;
        object-fit: contain;

        @media screen and (max-width: 1023px) {
          align-self: center;
          margin-top: 16px;
        }
      }

      .hub-intercept-modal__web-button {
        height: 40px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        width: 100%;

        @media screen and (max-width: 1023px) {
          width: 235px;
          align-self: center;
          margin-top: 16px;
        }
      }
    }
  }
}
