@import "~nutrien-style-guide/src/lib/colors/_colors.scss";
@import "../../styles/_index.scss";

$greyBorder: 1px solid #ccc;
$lightGrey: #eee;

.review-footer__top-text {
  display: block;
  font-size: 0.8rem;
  font-weight: 600;
}

.review-footer__top-text--small {
  @extend .review-footer__top-text;
  padding-top: 24px;
  width: 245px;

  @media (min-width: 1100px) {
    margin-right: 4%;
    padding-top: 0;
    text-align: center;
  }
}

.review-cart__headline {
  @include cart-headline;
}

.review-cart__sub-headline {
  @include cart-sub-headline;
  color: #000;
}

.review-cart__sub-headline--inline {
  @include cart-sub-headline;
  color: #000;
  display: inline-block;
  padding-bottom: 12px;
}

.review-cart__sub-total {
  @include sub-total;
  display: inline-block;
  font-size: 1rem;
  margin-bottom: 0;
  padding-bottom: 6px;
}

.review-cart__sub-total-label {
  font-size: 1rem;
  display: inline-block;
  font-weight: 600;
  padding-bottom: 6px;
}

.review-cart__inner-cart-container-bottom {
  @include inner-cart-container-bottom;
  border-top: 1px solid $nutrien-grey--inactive-outline;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 12px;
}

.review-cart__review-detail-headline {
  font-size: 0.9rem;
  font-weight: 600;
}

.review-cart__cart-paragraph {
  color: $nutrien-grey-3;
  font-size: 0.95rem;
  font-weight: 400;
  line-height: 1.3rem;
  margin: 0;
  max-width: 600px;
  padding: 8px 0 24px 0;
}

.review-cart__confirmation-detail-header {
  color: $nutrien-grey-3;
  display: inline-block;
  font-size: 0.9rem;
  width: 85px;
}

.review-cart__confirmation-detail-data {
  font-size: 0.9rem;
  font-weight: 600;
}

.review-cart__confirmation-data--inline {
  color: $nutrien-grey-3;
  display: inline-block;
  font-size: 0.9rem;
  padding-left: 8px;
}

.review-cart__details-blocked {
  color: $nutrien-grey-3;
  display: block;
  font-size: 0.9rem;
}

.review-cart__inner-content-label {
  color: $nutrien-grey-3;
  display: inline-block;
  font-size: 0.9rem;
  padding-bottom: 6px;
}

.review-cart__inner-content-data {
  display: inline-block;
  font-size: 0.9rem;
  font-weight: 600;
  padding-bottom: 6px;
}

.review-cart__details-blocked-wrapper {
  padding: 12px 0;
}

.review-cart__outer-cart-page-wrapper {
  @include outer-cart-page-wrapper;
}

.review-cart__cart-footer {
  @include cart-footer;
}

.review-cart__detail-section-wrapper {
  @include detail-section-wrapper;

  @media (min-width: 980px) {
    display: flex;
    justify-content: space-between;
  }
}

.review-cart__detail-section-wrapper-double {
  @include detail-section-wrapper;
  border-top: $greyBorder;

  @media (min-width: 980px) {
    display: flex;
    justify-content: space-between;
  }
}

.review-cart__detail-section-wrapper-col {
  @include detail-section-wrapper;
  display: flex;
  flex-direction: column;
  position: relative;
}

.review-cart__inner-detail-section-wrapper {
  padding-bottom: 32px;

  @media (min-width: 750px) {
    padding-bottom: 0;
  }
}

.review-cart__outer-account-section-wrapper {
  padding-top: 24px;

  @media (min-width: 980px) {
    padding: 0 48px 0 0;
  }
}

.review-cart__cart-section-wrapper {
  height: 100%;
  margin-top: 12px;
  position: relative;

  @media (min-width: 1100px) {
    overflow: scroll;
  }
}

.review-cart__cart-section-wrapper {
  height: 100%;
  margin-top: 12px;
  position: relative;

  @media (min-width: 1100px) {
    overflow: scroll;
  }
}

.review-cart__test {
  background-color: #e3e3e3;
  overflow: hidden;

  @media (min-width: 1100px) {
    width: 30%;
  }
}

.review-cart__inner-cart-section-wrapper {
  padding: 12px 12px 96px 12px;

  @media (min-width: 1100px) {
    left: 12px;
    position: absolute;
    right: 0;
    top: 12px;
  }
}

.review-cart__cart-container-content-top {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.review-cart__cart-container-content-bottom {
  display: flex;
  flex-direction: column;
  padding-left: 24px;
}

.review-cart__application-instructions-wrapper {
  padding-top: 12px;
}

.review-cart__section-header-wrapper {
  padding-bottom: 12px;
}

.review-cart__section-container {
  position: relative;

  @media (min-width: 1100px) {
    display: flex;
  }
}

.review-cart__outer-detail-section-wrapper {
  @media (min-width: 1100px) {
    padding-right: 12px;
    width: 70%;
  }
}

.review-cart__top-cart-footer {
  padding-top: 12px;

  @media (min-width: 1100px) {
    display: flex;
    justify-content: space-between;
  }
}

.review-cart__inner-cart-footer {
  background-color: #e4bd69;
  bottom: 0;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 12px;
  position: absolute;
  right: 0;
  text-align: center;
  width: 100%;

  @media (min-width: 1100px) {
    width: 30%;
  }
}

.review-cart__cart-button {
  @include cart-button;
  display: none;

  @media (min-width: 750px) {
    display: block;
  }
}

.review-cart__cart-button-back {
  @include cart-button;
  background-color: $lightGrey;
  display: none;
  margin-right: 24px;
  width: 200px;

  @media (min-width: 750px) {
    display: block;
  }
}

.review-cart__edit-button-outer-wrapper {
  position: absolute;
  right: 0;
  top: 12px;
}

.review-cart__edit-button-outer-wrapper-inner {
  position: absolute;
  right: 12px;
  top: 12px;
}

calendarIcon {
  height: 20px;
  margin: 0 0 0 12px;
  width: 20px;
  border: 1px solid orange;
}
