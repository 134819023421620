@import "~nutrien-style-guide/src/lib/colors/_colors.scss";
@import "../../../styles/index.scss";

// !TEXT-------------------------------------------------------------
.branch-wrapper {
  > div {
    padding-bottom: 3px;
  }
}

.branch-details__subheader {
  color: $nutrien-grey-3;
}

.branch-details__detail {
  font-weight: 600;
}
