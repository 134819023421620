@import "~nutrien-style-guide/src/lib/colors/_colors.scss";

.permits-card__card-content {
  padding-bottom: 14px;
  position: relative;
  height: 100%;
}

.permits-card__content-centered {
  width: 100%;
  text-align: center;
}

.permits-card__icon-huge {
  font-size: 68px;
  color: $nutrien-green;
  fill: $nutrien-green;
  &:hover {
    color: $nutrien-light-green;
    fill: $nutrien-light-green;
  }
}

.permits-card__permit-count-wrapper {
  padding: 30px 0;
}

.permits-card__permit-count-content {
  padding-bottom: 30px;
}

.permits-card__permit-count-text {
  font-size: 28px;
  font-weight: 600;
  margin: 0;
  vertical-align: middle;
  line-height: 1em;
  color: #4f4f4f;
}
.permits-card__permit-count-description {
  font-size: 14px;
  color: #828282;
}

.permits-card__warning-icon-wrapper {
  color: $brand--warning;
  margin-right: 2px;
  & svg {
    padding-top: 3px;
    margin-left: -1em;
  }
}

.permits-card__view-all-link {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  position: absolute;
  bottom: 0;
  left: 0;
  color: $nutrien-green;
  &:hover {
    filter: brightness(85%);
  }
}
