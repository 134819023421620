.invoiced-products-search__container {
  width: 100%;
  font-size: 13px;
  margin: 0;
  background: white;
  padding: 10px;
}

.invoiced-products-search__container--expanded {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.invoiced-products-search__header {
  padding: 3px 0;
  display: block;
}

.invoiced-products-search__action-container {
  margin-top: 5px;
  display: flex;
  padding: 0;
}

.invoiced-products-search__input {
  padding: 7px 25px 7px 7px;
  width: 100%;
  border: none;
  border: solid 1px #cccccc;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.invoiced-products-search__search-input-container {
  width: 100%;
  position: relative;
}

.invoiced-products-search__clear-icon-container {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  cursor: pointer;
}

.invoiced-products-search__search-icon-container {
  padding: 4px;
  display: flex;
  border: solid 1px #cccccc;
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.invoiced-products-search__search-results-list-container {
  background: white;
  overflow-y: auto;
  max-height: 250px;
}

.invoiced-products-search__search-results-not-found {
  padding: 0 0 5px 0;
  text-align: center;
}

.invoiced-products-search__loading-spinner-container {
  padding: 15px 0 0 0;
  overflow-y: hidden;
}

.invoiced-products-search__search-results-item {
  padding: 0;
  line-height: 1.2rem;
  display: flex;
  cursor: pointer;
  &:hover {
    background: none;
  }
}

.invoiced-products-search__search-results-item-checkbox {
  width: 10px;
  height: 10px;
  margin: 5px;
}

.invoiced-products-search__search-results-item-text-container {
  padding: 6px 0;
}

.invoiced-products-search__product-name {
  font-size: 13px;
  display: block;
}

.invoiced-products-search__product-id {
  font-size: 10px;
  display: block;
  color: gray;
}
