
.processing-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.78;
  background-color: #f0eeee;
  z-index: 100;
}

.loading-icon-container {
  position: relative;
  height: 100%;
}

.loading-icon-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  margin: auto;
  text-align: center;
}