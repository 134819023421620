.invoice-order-section {
  padding: 10px;
}

.invoice__title {
  margin-left: 0;
  font-weight: 300;
  font-size: 2em;
  height: 1em;
  margin-bottom: 2em;
}

.invoice-order-section-text {
  font-weight: 400;
  padding: 0;
}

.invoice-account-info-container {
  padding: 0 20px;
  width: 100%;
}

.accounting-total {
  display: flex;
  width: 100%;
  padding: 10px 30px;
}

.invoice__line-items-missing {
  margin: 20px;
}

.accounting-total > table {
  margin-left: auto;
  font-size: 14px;
}

.accounting-total > table > tbody > tr > th {
  text-align: right;
}

.accounting-total > table > tbody > tr > td {
  text-align: right;
  padding-left: 60px;
}

.invoices__download-link {
  color: #4c9e00;
  cursor: pointer;
  display: inline-flex;
}

.downloadLinkContainer_icon {
  margin-right: 3px;
}

.downloadLinkContainer {
  font-weight: normal;
  padding: 20px;
}

.invoices__pdfDownload__margin {
  margin-top: 15px;
}

.invoices__download-link-spacer {
  margin-bottom: 10px;
}

.downloadLinkContainer > div {
  display: block;
}

.downloadLinkContainer a {
  color: #4c9e00;
}

.invoices__download-link-text:hover {
  text-decoration: underline;
}

.invoices__download-link-text.invoices__download-link-text__disabled:hover {
  text-decoration: none;
}
.invoices__download-link-text__disabled {
  color: #ccc;
}
