.recent-orders__container {
  display: block;
  flex-basis: 100%;
  padding-bottom: 1em;
  .mdc-layout-grid {
    padding: 0;
  }
  .card {
    padding: 24px;
  }
}

.recent-orders__orders-link-wrapper,
.recent-orders__products-link-wrapper {
  display: flex;
  width: 100%;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
}

.recent-orders__products-link-wrapper {
  justify-content: right;

  @media only screen and (max-width: 480px) {
    justify-content: center;

    .recent-orders__products-link-button {
      width: 100%;
    }
  }
}

.recent-orders__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.recent-orders__no-orders-message {
  display: flex;
  align-items: center;

  .recent-orders__no-orders-gnome {
    padding-right: 12px;
  }
}
