@import '../../styles/_index.scss';
@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

@mixin green-outline {
  border: solid 1px $nutrien-green;
}

@mixin red-outline {
  border: solid 1px $brand--danger;
}

.cart-item__table-row {
  @include cart-table-row;
  vertical-align: top;
  display: flex;
  flex-wrap: wrap;
}

.cart-item__table-row--undo-remove {
  @include cart-table-row;
  background-color: $nutrien-datepicker-hover;

  &.fade-out {
    opacity: 0;
    transition: opacity 2s linear 5.5s;
  }
}

.cart-item__close {
  color: #464646;
  display: block;
  overflow-wrap: break-word;

  @media (min-width: 950px) {
    text-align: right;
  }
  @media print {
    text-align: right;
  }
}

.cart-item__close-icon {
  @include close-icon;
  position: static;
  fill: #464646;
  margin: 6px;
}

.cart-item__inner-table-data--wide {
  @include inner-table-data-wide;
  @media (min-width: 950px) {
    display: flex;
    height: 77px;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
  }
}

.cart-item__no-wrap {
  @media (min-width: 410px) {
    white-space: nowrap;
  }
}

.cart-item__undo-wrapper {
  display: flex;

  @media (min-width: 410px) {
    @include table-data-standard;
    padding-right: 0;
    text-align: end;
    vertical-align: middle;
  }

  button {
    margin-left: auto;
    width: auto;

    &:focus {
      background-color: $list-selected-green;
      outline-color: $nutrien-green;
    }
  }
}

.cart-item__header--mobile {
  @include mobile-header;
}

.cart-item__header--mobile-slim {
  @include mobile-header-slim;
  display: block;
}

.cart-item__table-data {
  @media (min-width: 410px) {
    @include table-data-standard;
    vertical-align: middle;
    display: table-cell;
  }
  width: 50%;
}

.cart-item__table-data--expanded-text {
  @include table-data-standard;
  display: table-cell;
  font-weight: 600;
  margin-right: auto;
  vertical-align: middle;
  width: 60%;

  p {
    font-size: inherit;
    font-weight: inherit;
    margin-bottom: 0;
    padding-left: 1rem;
  }
}

.cart-item__input-container {
  display: flex;
  align-items: center;

  .cart-item__input {
    width: 100px;
    @media (min-width: 410px) {
      width: 100px;
    }
  }
}

.cart-item__table-data--full {
  @include table-data-full;
  vertical-align: middle;
}

.cart-item__table-data--wide {
  @include table-data-wide;
}

.cart-item__text--highlight {
  @include highlight-text;

  span {
    color: black;
  }
}

.cart-item__input-data {
  @include green-outline;
  @include input-data;
  width: 100%;

  &:active {
    @include green-outline;
  }
  &:focus {
    @include green-outline;
  }
}

.cart-item__numeric-data {
  @include numeric-data;
}

.cart-item__input-error {
  @include red-outline;
  @include input-error;

  &:active {
    @include red-outline;
  }
  &:focus {
    @include red-outline;
  }
}

.cart-item__input-warning {
  @include input-error;
  display: block;
  font-size: 0.8rem;
}
.cart-item__close-wrapper {
  @include close-wrapper;
}

.cart-item__unavailable {
  @include unavailable;
}

.cart-item__input-data-wrapper--no-wrap {
  white-space: nowrap;
}

.cart-item__unit-of-measure {
  padding-left: 0.4rem;
}

.cart-item__invalid-input-warning {
  display: block;
  white-space: normal;
  color: $brand--danger;
}

.cart-item__no-price-message-span {
  white-space: nowrap;
}
