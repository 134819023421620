@import "~nutrien-style-guide/src/lib/colors/_colors.scss";
@import "src/shared/styles/index.scss";

.active-ingredients {
  .active-ingredients__label {
    @include bold-label;
    font-size: 1.1rem;
    padding-left: 0;
    margin: 16px 0 14px;
  }

  .active-ingredients__list {
    list-style-type: none;
    padding: 0;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.15;
    margin: 0;
    font-family: "Open Sans", "sans-serif";
    color: $nutrien-dark-grey-2;
  }
}
