@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

.request-info-modal__modal-container {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 100;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
}
.request-info-modal__request-info-modal-wrapper {
  height: auto;
  padding: 1rem !important;
  width: 100% !important;
}
.request-info-modal__request-info-title-area {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 12px;
}
.request-info-modal__application-icon {
  width: 200px;
  height: 140px;
  object-fit: contain;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.request-info-modal__modal-header-wrapper {
  align-content: flex-end;
  display: flex;
  flex-direction: row-reverse;
}
.request-info-modal__close-modal {
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
  align-content: flex-end;
  align-items: flex-end;
}
.request-info-modal__request-info-title {
  width: 100%;
  height: auto;
  font-size: 22px;
  font-weight: 600;
  color: $nutrien-dark-grey-2;
  margin-left: 0;
  text-align: center;
  margin: 16px 0;
  display: block;
}
.request-info-modal__request-info-description {
  width: 100%;
  height: auto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: $nutrien-dark-grey-2;
  padding: 0 40px;
  align-items: center;
}
.request-info-modal__request-info-checkbox {
  align-items: center;
  color: $nutrien-dark-grey-2;
  display: flex;
  flex: 1;
  font-size: 14px;
  justify-content: center;
  letter-spacing: 0px;
  padding-top: 1.25rem;
  text-align: center;
}
.request-info-modal__request-info-HR {
  height: 1px;
  margin-bottom: 30px;
  margin-top: 30px !important;
}
.request-info-modal__request-info-bottom-wrapper {
  display: flex;
}
.request-info-modal__crop-consultant-info-wrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
  color: $nutrien-dark-grey-2;
  font-weight: 700;
}
.request-info-modal__crop-consultant-info {
  padding: 20px 0;
}
.request-info-modal__request-info-button-wrapper {
  display: flex;
  width: 50%;
  justify-content: flex-end;
  align-items: flex-end;
}
.request-info-modal__request-info-button {
  margin: 0.5rem !important;
}
.request-info-modal__ok-button {
  width: 80px;
  height: 48px;
  margin-bottom: 6px;
  border-radius: 2px !important;
  outline: none !important;
}
.request-info-modal__ok-button:disabled {
  background-color: $nutrien-table-border !important;
  color: $nutrien-grey-3 !important;
  outline: none !important;
}
.request-info-modal__cancel-button {
  width: 80px;
  height: 48px;
  margin-bottom: 6px;
  border-radius: 2px !important;
  background-color: $nutrien-white;
  color: #50a200;
  outline: none !important;
}
.request-info-modal__contact-icon--left {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  object-fit: contain;
  margin-bottom: -2px;
}

.request-info-modal__request-info-bottom-wrapper--outer {
  padding: 0 20px;
}

.request-info-modal__your-crop-consultant {
  font-size: 20px;
  font-weight: 600;
  color: $nutrien-dark-grey-2;
  padding-bottom: 0;
  margin-bottom: 0;
}
.request-info-modal__consultant-name {
  font-size: 16px;
  font-weight: 600;
  color: $nutrien-dark-grey-2;
  margin-bottom: 0;
}
.request-info-modal__contact-info {
  color: $nutrien-green;
  padding-bottom: 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.6;
  margin-bottom: 50px;
}
.request-info-modal__hubspot-call-failed-notification {
  flex-flow: row wrap;
  margin-top: 12px;
}
.request-info-modal__consultant-contact {
  display: flex;
  align-items: center;
}
.request-info-modal__consultant-contact a:focus {
  text-decoration: underline;
}

@media (max-width: 480px) {
  .request-info-modal__request-info-description {
    padding: 0 5px;
  }
  .request-info-modal__request-info-bottom-wrapper {
    flex-wrap: wrap;
  }
  .request-info-modal__crop-consultant-info-wrapper {
    width: 100%;
  }
  .request-info-modal__request-info-button-wrapper {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  .request-info-modal__request-info-checkbox {
    text-align: left;
    font-size: 12px;
  }
  .request-info-modal__request-info-modal-wrapper {
    height: 100%;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    overflow-y: auto !important;
    padding-bottom: 32px !important;
  }
  .request-info-modal__modal-container {
    padding-top: 0;
  }
  .request-info-modal__contact-info {
    padding-bottom: 0;
    line-height: 0;
  }
  .request-info-modal__contact-icon--left {
    width: 16px !important;
    height: 16px !important;
  }
  .request-info-modal__consultant-contact a {
    font-size: 12px;
  }
}
