@import "~nutrien-style-guide/src/lib/colors/_colors.scss";
@import "../../styles/_index.scss";

// !WRAPPERS------------------------------------------------------------
.application-instructions__header-wrapper {
  align-items: center;
  display: flex;
}
.custom-applied__wrapper {
  @include detail-section-wrapper;
  display: flex;
  flex-direction: column;
}

// !TEXT------------------------------------------------------------
.custom-applied__text-area {
  @include text-area;
  height: 160px;
  @media (min-width: 408px) {
    height: 120px;
  }
  @media (min-width: 760px) {
    height: 100px;
  }
}
.custom-applied__description {
  color: #000;
  line-height: 1.5;
  font-weight: 400;
  max-width: 400px;
  margin: 0px;
  padding: 8px 0px 16px 0px;
}
.custom-applied__preference_subheader {
  @include total-items;
  color: #000;
  font-weight: 600;
}
.custom-applied__preference-label {
  @include total-items;
  color: #000;
  font-weight: 600;
  padding: 8px 8px 8px;
}
.application-unavailable__text {
  color: #000;
  line-height: 1.5;
  font-weight: 400;
  max-width: 400px;
  margin: 0px;
  padding: 8px 0px 16px 0px;
}
.application-unavailable__instructions {
  color: #000;
  line-height: 1.5;
  font-weight: 400;
  max-width: 400px;
  margin: 0px;
  padding: 8px 0px 16px 0px;
}
.application-unavailable__mail-to {
  color: $nutrien-green;
}

// !HEADERS-------------------------------------------------------------
.application-instructions__subheader {
  @include cart-sub-headline;
  margin-bottom: 0px;
}

.application-instructions__text {
  @include total-items;
}

.custom-applied__header {
  @include cart-sub-headline;
}
