@import "~nutrien-style-guide/src/lib/colors/_colors.scss";
@import "../../../styles/index.scss";

// !TEXT-------------------------------------------------------------
.order-memo__header {
  font-size: 0.9rem;
  font-weight: 600;
}

.order-memo__blurb {
  color: $nutrien-gray-3;
  font-size: 0.95rem;
  font-weight: 400;
  line-height: 1.3rem;
  margin: 0;
  max-width: 600px;
  padding: 0;
}
