.accounts__table {
  margin: 0;
  width: 100%;
}

.accounts-header__container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  .accounts-header__container-preferences {
    display: inline-block;
    vertical-align: middle;

    svg {
      color: #a1a1a1;
    }
  }
}

.accounts-header__container > div > button {
  margin: 0;
}

.accounts__button-make-payment,
.accounts__button-view-details {
  min-height: 32px !important;
  height: 32px !important;
  min-width: 148px !important;
  font-size: 14px;
  margin: 5px 0 !important;
  line-height: 0 !important;
}

.accounts__button-column {
  width: 188px;
}

.accounts__table-description {
  font-weight: 600;
  font-size: 16px;
}

.accounts__table-row {
  height: 48px;
  border-bottom: 0.5px solid #c4c4c4;
}

.accounts__table-item--mobile-display {
  display: none;
}

.accounts__table-item-desktop-display-none {
  display: none;
}

.accounts__table-button-block {
  padding: 5px;
  display: table-cell;
  text-align: center;
}

.accounts__button-block-first {
  padding-left: 30px;
}

.accounts__table-paid-invoice-stamp {
  display: block;
  position: relative;
  margin: 0 auto;
  height: 40px;
}

@media only screen and (max-width: 1080px) {
  .accounts__table {
    &-body {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  .accounts__table-mobile-display-none {
    display: none;
  }
  .accounts__button-make-payment {
    width: 100%;
  }
  .accounts__table-item-desktop-display-none {
    display: block;
    width: 50%;
  }
  .accounts__table-button-block {
    padding: 8px;
    display: block;
    text-align: left;
    width: 50%;

    .accounts__button-make-payment,
    .accounts__button-view-details {
      width: 100%;
    }
  }
  .accounts__table-button-block-first {
    padding: 0;
  }
  .accounts__table-currentlyDue-column,
  .accounts__table-description-column {
    width: 100%;
  }
  .accounts__table-description {
    font-size: 18px;
  }
  .accounts__table-row {
    padding: 12px 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: inherit;
    width: calc(50% - 8px);
  }
  .accounts__table-row__card-wrapper {
    margin-bottom: 16px;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  }
  .accounts__table-item-mobile-display {
    display: block;
    font-weight: 600;
  }
  .accounts__button-make-payment,
  .accounts__button-view-details {
    margin: 0 !important;
    width: 148px;
  }
  .paid-invoice-stamp {
    margin: 0;
  }
}

@media only screen and (max-width: 840px) {
  .accounts__table-mobile-display-none {
    display: none;
  }
  .accounts__button-make-payment {
    width: 100%;
  }
  .accounts__table-item-desktop-display-none,
  .accounts__table-currentlyDue-column {
    width: 33%;
  }
  .accounts__table-description-column {
    width: 100%;
  }
  .accounts__table-row {
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: inherit;
    width: 100%;
  }
  .accounts__table-row:last-child {
    margin-bottom: 1em;
  }
  .accounts__table-item-mobile-display {
    display: block;
    font-weight: 600;
  }
  .accounts__button-make-payment,
  .accounts__button-view-details {
    margin: 0 !important;
    width: 148px;
  }
}

@media only screen and (max-width: 600px) {
  .accounts__table-item-desktop-display-none {
    width: 50%;
  }
  .accounts__table-currentlyDue-column,
  .accounts__table-description-column {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .accounts__table-sm-mobile-display-none {
    display: none;
  }
  .accounts__table-row {
    flex-direction: column;
    width: 100%;
  }
  .accounts__table-button-block {
    width: 100%;
  }
}

@media only screen and (max-width: 320px) {
  .accounts__table-row {
    padding: 12px 0;
    padding-bottom: 20px;
  }
  .accounts__table-item-desktop-display-none {
    padding-top: 8px;
    padding-bottom: 0;
  }
  .accounts__table-button-block {
    padding-top: 8px;
    padding-bottom: 0;
  }
}
