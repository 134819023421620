@import "~nutrien-style-guide/src/lib/colors/_colors.scss";
@import "../../../styles/index.scss";

// !TABLE-------------------------------------------------------------
.print-section-wrapper {
  .shopping-cart-table {
    @include cart-table;
  }

  .shopping-cart-table__body {
    @include cart-table-body;
  }

  .shopping-cart-table__row {
    @include cart-table-row;
  }
  .receipt-item__table-data {
    text-align: center;
    padding: 5.5px 24px 5.5px 0;
  }
  .receipt-item__table-data--first {
    text-align: left;
  }
  .receipt-item__product-text,
  .receipt-item__table-data,
  .receipt-item__tabla-data--last {
    font-size: 0.5625rem;
  }
  .receipt-item__tabla-data--last {
    padding: 5.5px 0 5.5px 24px;
  }
  .shopping-cart-table__header {
    font-weight: 600 !important;
    font-size: 0.625rem;
    text-align: center;
    padding: 1.5px 24px 1.5px 0;
  }
  .shopping-cart-table__header--first {
    text-align: left;
  }

  .shopping-cart-table__header--last {
    font-weight: 600 !important;
    text-align: right;
  }
}
