.restricted-warning {
  align-items: center;
  display: flex;
  font-size: 12px;
  margin-top: 4px;

  .restricted-warning__icon {
    display: block;
    margin-right: 8px;
    height: 24px;
    width: 24px;
  }
}
