.overall-error-container {
  display: flex;
  margin-right: 20px;
  margin-left: 5px;
  margin-top: 20px;
  height: 50px;
  border-radius: 5px;
  border: '1px solid #D24242';
  background-color: '#feeded';
}

.feedback__confirmation-card {
  min-height: 100vh;
}

.feedback__home-link {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.feedback__overall-error-message {
  align-self: center;
  margin-left: 5px;
}

.feedback__overall-warning-icon {
  color: #d24242;
  height: 24px;
  width: 24px;
  margin-left: 10px;
  margin-top: 12px;
}

.feedback__phone-label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 0.5;
}

.feedback__optional-span {
  font-weight: 400;
  color: #828282;
  font-size: 12px;
}

.feedback__message-container {
  display: flex;
  flex-direction: column;
}

.feedback__message-label {
  font-weight: 600;
  font-size: 14px;
}

.feedback__message-textarea {
  border-color: #ccc;
  max-width: 682px;
  min-height: 135px;
  padding-left: 12px;
  padding-top: 9px;
  resize: none;
}

.feedback__submit-button {
  height: 40px;
}

.feedback__submit-button-container {
  display: flex;
  justify-content: flex-end;
  max-width: 682px;
}

.feedback__title-container {
  display: flex;
  margin-right: 20px;
  margin-left: 5px;
  align-items: flex-start;
  flex-direction: column;
}

.feedback__subtitle {
  margin-left: 0;
  padding-top: 8px;
}

.feedback__description {
  margin-left: 0;
  padding-bottom: 6px;
}

.feedback__page {
  margin-left: 20px;
}

.feedback__form {
  margin-right: 40px;
}

.feedback__form > form {
  margin-left: 10px;
}

.message-error {
  color: #d24242;
  font-size: 13px;
  font-weight: 200;
  line-height: 19.5px;
  font-weight: 200;
  padding-top: 5px;
}

.feedback__red-border {
  border-color: #d24242 !important;
}
