.pre-pay-form-wrapper {
    width: 100%;
    padding-left: 20px,
}

.no-account {
    height: 300px;
}

.pre-pay-divider {
    display: block;
    position: relative;
    padding: 0;
    margin: 15px 0 20px 0;
    height: 0;
    width: 100%;
    max-height: 0;
    font-size: 1px;
    line-height: 0;
    clear: both;
    border-bottom: 1px solid #ffffff;
}

.next-button-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
