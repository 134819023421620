.product-detail__image {
  align-self: center;
  display: block;
  max-width: 200px;
  max-height: 200px;
  padding: 12px;
  @media (min-width: 700px) {
    max-height: 395px;
    max-width: 395px;
    width: 100%;
  }
}
.product-card__image {
  display: block;
  max-width: 200px;
  max-height: 200px;
  padding: 12px;
  width: 100%;
}
