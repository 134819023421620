.data-list-container{
    display: flex;
    width:100%;
    flex-wrap: wrap;
    font-size: 12px;
}

.data-list-item{
    width: 33.33%;
    padding: 10px 0;
}

.data-list-item-title{
    font-size: 1.2em;
    font-weight: normal;
    color: #777;
    margin: 0;
}

.data-list-item-data{
    font-size: 1.4em;
    margin:0;
    color: #000;
    text-transform: capitalize;
}

@media screen and (max-width: 768px) {
    .data-list-item{
        width: 50%;
    }

    .data-list-item__mobile__full{
        width: 100%;
    }
}