.header-left {
  display: flex;
  position: absolute;
  justify-content: flex-end;
  height: 100%;
}

.header-right {
  display: flex;
  justify-content: flex-end;
  height: 62px;
}
