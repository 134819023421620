@import "~nutrien-style-guide/src/lib/colors/_colors.scss";

.payment-datepicker-container {
  position: relative;

  .Cal__Container__root {
    position: absolute;
    top: 4.375rem;
    z-index: 200;
  }

  .payment-datepicker-input {
    border-radius: 0.125rem;
    border: 0.0625rem solid $nutrien-grey--inactive-outline;
    padding: 0.625rem;
    width: 200px;
  }

  .payment-datepicker-input:hover {
    cursor: pointer;
  }

  .payment-datepicker-input.open {
    position: absolute;
    z-index: 200;
  }

  .datepicker-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(240, 238, 238, 0.78);
    z-index: 100;
  }

  .error {
    border-color: $brand--danger;
  }
}
