@import "~nutrien-style-guide/src/lib/colors/_colors.scss";

// !DEFAULTS -------------------------------------------------------------
@mixin default-mobile-header {
  display: block;
  font-size: 0.8rem;
  font-weight: 600;
  padding-bottom: 12px;
}

@mixin table-data {
  color: #464646;
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 33.33%;
}

@mixin default-table-header {
  display: table-cell;
  font-weight: 400;
  padding: 12px 24px 12px 0;
  font-size: 0.875rem;
}

@mixin default-cart-text {
  color: #000;
  font-weight: 200;
}

// !TABLE -------------------------------------------------------------
@mixin cart-table {
  margin: 0;
  width: 100%;
}

@mixin cart-table-header {
  display: none;

  @media (min-width: 950px) {
    @include default-table-header;
  }
  @media print {
    @include default-table-header;
  }
}

@mixin cart-table-row {
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media (min-width: 950px) {
    display: table-row;
  }
  @media print {
    display: table-row;
  }
}

@mixin cart-table-body {
  width: 100%;
}

@mixin mobile-header {
  @include default-mobile-header;

  @media (min-width: 950px) {
    display: none;
  }
  @media print {
    display: none;
  }
}

@mixin mobile-header-slim {
  @include default-mobile-header;
  padding: 2px 0 0;

  @media (min-width: 950px) {
    display: none;
  }
  @media print {
    display: none;
  }
}

@mixin table-header-numeric {
  display: none;

  @media (min-width: 950px) {
    @include default-table-header;
    text-align: right;
  }
  @media print {
    @include default-table-header;
    text-align: right;
  }
}

@mixin table-header-last {
  display: none;

  @media (min-width: 950px) {
    @include default-table-header;
    padding: 12px 0 12px 0;
    text-align: right;
  }
  @media print {
    @include default-table-header;
    padding: 12px 0 12px 0;
    text-align: right;
  }
}

// !TEXT -------------------------------------------------------------
@mixin highlight-text {
  font-size: 0.875rem;
}

@mixin cart-paragraph {
  @include default-cart-textText;
}

@mixin highlight-text-numeric {
  color: $nutrien-green;
  display: block;
  overflow-wrap: break-word;

  @media (min-width: 950px) {
    text-align: right;
  }
  @media print {
    text-align: right;
  }
}

@mixin numeric-data {
  color: #464646;
  display: block;
  overflow-wrap: break-word;

  @media (min-width: 950px) {
    text-align: right;
  }
  @media print {
    text-align: right;
  }
}

@mixin input-data {
  border-style: solid;
  border-width: 0.5px;
  border-radius: 4px;
  border-color: $nutrien-grey--inactive-outline;
  color: #464646;
  max-width: 100px;
  padding: 6px;

  &:active,
  &:focus {
    border: 1px solid $brand--info;
    padding: 11.5px;
  }

  @media (min-width: 950px) {
    padding: 12px;
  }
}

@mixin table-data-standard {
  @include table-data;
  font-size: 0.875rem;

  @media (min-width: 950px) {
    display: table-cell;
    padding: 12px 24px 12px 0;
    width: auto;
  }
  @media print {
    display: table-cell;
    padding: 12px 24px 12px 0;
    width: auto;
  }
}

@mixin table-data-last {
  @include table-data;
  font-size: 0.875rem;

  @media (min-width: 950px) {
    display: table-cell;
    padding: 12px 0 12px 0;
    width: auto;
  }
  @media print {
    display: table-cell;
    padding: 12px 0 12px 0;
    width: auto;
  }
}

@mixin table-data-full {
  @include table-data;
  font-weight: 600;
  width: 100%;

  @media (min-width: 950px) {
    display: table-cell;
    padding: 12px 24px 12px 0;
    width: auto;
  }
  @media print {
    display: table-cell;
    padding: 12px 24px 12px 0;
    width: auto;
  }
}

@mixin table-data-wide {
  @include table-data;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  @media (min-width: 950px) {
    display: table-cell;
    padding: 12px 24px;
    width: auto;
  }

  @media print {
    display: table-cell;
    padding: 12px 24px;
    width: auto;
  }
}

@mixin inner-table-data-wide {
  display: flex;
  @media print {
    display: block;
  }
}

@mixin table-data-right {
  color: #464646;
  padding: 12px;

  @media (min-width: 950px) {
    padding: 12px 24px;
    text-align: right;
  }
  @media print {
    padding: 12px 24px;
    text-align: right;
  }
}

@mixin unavailable {
  color: $nutrien-dark-grey-2;
}

@mixin null-data {
  text-align: right;
  width: 60%;

  @media (min-width: 750px) {
    width: 25%;
  }

  @media (min-width: 950px) {
    width: 100%;
  }
}

@mixin close-wrapper {
  cursor: pointer;

  @media (min-width: 950px) {
    cursor: auto;
    padding: 0;
  }
}

@mixin close {
  cursor: pointer;
  display: inline;
}

@mixin cart-link {
  display: inline-flex;
  font-size: 0.8rem;
  font-weight: 400;
}

@mixin cart-blurb {
  color: #000;
  font-size: 0.8rem;
  font-weight: 400;
  margin: 0;
  max-width: 450px;
  padding: 0 0 24px 0;
}

@mixin cart-link-text {
  padding-top: 2px;
}

@mixin sub-total {
  align-self: flex-end;
  display: block;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 24px;
}

@mixin cart-legal-list {
  font-size: 0.8rem;
  font-weight: 400;
  max-width: 450px;
}

@mixin total-items {
  color: #666;
  font-size: 0.8rem;
  font-weight: 400;
  padding: 8px;
}

@mixin text-area {
  border-color: $nutrien-grey--inactive-outline;
  border-radius: 3px;
  font-size: 14px;
  min-height: 1rem;
  max-width: 500px;
  margin-top: 18px;
  padding: 16px;
  resize: unset;
  width: 100%;
  &:focus {
    border-color: $brand--info;
  }
}

// !FORMS -------------------------------------------------------------
@mixin form-label {
  display: flex;
  font-size: 0.9rem;
  font-weight: 600;
  padding-top: 24px;
}

@mixin fieldset {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@mixin fieldset-col {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@mixin inner-fieldset-wrapper {
  display: flex;
  flex-direction: column;
  width: 45%;
}

@mixin input {
  border-color: $nutrien-grey--inactive-outline;
  border-radius: 3px;
  border-style: solid;
  border-width: 0.5px;
  font-size: 14px;
  padding: 8px;
  touch-action: manipulation;
  width: 100%;
}

@mixin form-control {
  margin-top: 24px;
}

@mixin form-error {
  color: $brand--danger;
  font-size: 13px;
  font-weight: 20;
}

@mixin input-error {
  @include form-error;
  border-color: $brand--danger;
}

// !HEADERS -------------------------------------------------------------
@mixin cart-legal-header {
  display: flex;
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 12px;
}

@mixin cart-headline {
  font-size: 1.3rem;
  font-weight: 600;
  padding: 12px 0;

  @media (min-width: 720px) {
    font-size: 1.8rem;
  }
}

@mixin cart-sub-headline {
  font-size: 1.2rem;
  font-weight: 600;
}

// !WRAPPERS -------------------------------------------------------------
@mixin cart-container {
  padding: 24px 0 0;

  @media (min-width: 950px) {
    display: flex;
    justify-content: space-between;
  }
}

@mixin cart-wrapper {
  background-color: $nutrien-white;
  padding: 12px 12px 36px 12px;
}

@mixin empty-cart-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 0 84px;
  text-align: center;

  @media (min-width: 720px) {
    padding: 36px 0 84px;
  }
}

@mixin outer-table-wrap {
  padding-top: 36px;
}

@mixin inner-cart-container-bottom {
  display: flex;
  flex-direction: column;
  @media print {
    display: block;
  }
}

@mixin detail-section-wrapper {
  border-bottom: 1px solid $nutrien-grey--inactive-outline;
  padding: 24px 0;
}

@mixin button-wrapper {
  display: flex;
  justify-content: flex-end;
  @media print {
    display: block;
  }
}

@mixin outer-cart-page-wrapper {
  padding-bottom: 200px;
}

@mixin cart-footer {
  background-color: #eee;
  border-top: 1px solid $nutrien-grey--inactive-outline;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  left: 0;
  padding: 12px;
  position: fixed;
  right: 0;
  width: 100%;
}

// !BUTTONS -------------------------------------------------------------
@mixin cart-button {
  align-self: flex-end;
  height: 45px;
}

@mixin cart-button--secondary {
  align-self: flex-end;
  height: 45px;
  margin-bottom: 12px;

  @media (min-width: 950px) {
    display: none;
  }
}

@mixin cart-back {
  margin-left: -8px;
}

// !ICONS -------------------------------------------------------------
@mixin info-icon {
  color: $brand--info;
  height: 30px;
  margin: -5px 12px 0 0;
  width: 30px;
}

@mixin close-icon {
  margin: 16px;
  position: absolute;
  right: 0;
  top: 0;
  &:hover {
    cursor: pointer;
  }
}
