.mirror-user-link {
  margin: 0px 8px;
  font-size: 14px;
}

@media only screen and (max-width: 576px) {
  .mirror-user-link {
    margin: 0px 16px;    
  }
}
