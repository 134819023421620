@import '../../styles/index.scss';

// ! BUTTONS -------------------------------------------------------------
.cart-page__button {
  @include cart-button;
  display: none;
  width: 200px;

  @media (min-width: 750px) {
    display: block;
  }
}
