.form-card-checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide browser's default checkbox */
.form-card-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.form-card-checkbox input:focus {
  opacity: 100;
  top: 1px;
  left: 1px;
}

/* Create a custom checkbox */
.form-card-checkbox span {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #ffffff;
  border: solid 1px #cccccc;
}

.form-card-checkbox a {
  color: #50a200;
}

/* On mouse-over, add a grey background color */
.form-card-checkbox:hover input ~ span {
  background-color: #cccccc;
}

.form-card-checkbox input:disabled ~ span {
  background-color: #cccccc;
}

/* When the checkbox is checked, add a green background */
.form-card-checkbox input:checked ~ span {
  background-color: #50a200;
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.form-card-checkbox > span:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.form-card-checkbox input:checked ~ span:after {
  display: block;
}

/* Style the checkmark/indicator */
.form-card-checkbox > span:after {
  left: 5px;
  top: 2px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2.5px 2.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#marketingOptIn {
  margin-top: 8px;
}

.form-card-checkbox span.error {
  border: 1px solid #f13b39;
  margin-top: 0;
}
