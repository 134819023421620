@import "../../styles/_index.scss";

.receipt-item__wrapper {
  @include cart-table-row;
}

.receipt-item__table-data--full {
  @include table-data-full;
}

.receipt-item__header {
  @include mobile-header;
}

.receipt-item__product-text {
  @include highlight-text;
  color: #222;
}

.receipt-item__table-data {
  @include table-data-standard;
}

.receipt-item__header--mobile {
  @include mobile-header;
}

.receipt-item__tabla-data--last {
  @include table-data-last;
}

.receipt-item__numeric-data {
  @include numeric-data;
  color: #222;
}

.receipt-item__price-unavailable {
  @include unavailable;
}
