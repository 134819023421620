.notifications {
  color: #434343;
}

.notifications__no-notifications {
  color: #cccccc;
  padding: 1em;
  text-align-last: center;
}

.notifications-list {
  list-style: none;
  padding: 0;
}
