.warning-modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(0, 0, 0, 0.37);
  z-index: 99999;
  color: #828282;
}

.warning-modal {
  position: relative;
  left: 50%;
  top: 60px;
  transform: translate(-50%, 0);
  background: white;
  width: 400px;
  height: 200px;
  color: #828282;
  font-size: 0.8em;
  padding: 1.5em;
}

.warning-modal > h4 {
  color: #464646;
  margin: 0 0 0.75em 0;
  font-weight: 600;
}

.warning-modal__timer {
  color: #464646;
  font-size: 2em;
  font-weight: 600;
  position: fixed;
  bottom: 0.7em;
}

.warning-modal-button {
  position: absolute;
  bottom: 1.5em;
  right: 1.5em;
  width: 88px;
  height: 38px;
  border-radius: 2px;
  color: white;
  font-weight: 600;
  background-color: #4c9e00;
  cursor: pointer;
}

.warning-modal-button:focus {
  outline: none;
}
