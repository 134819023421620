@import "~nutrien-style-guide/src/lib/colors/_colors.scss";

.support-fab__icon {
  color: #838587;
  margin-right: 0;
  margin-left: 16px;
}

.support-fab__container {
  position: fixed;
  z-index: 99999;
  display: block;
  bottom: 35px;
  right: 35px;
  padding: 0;
}

.support-fab__button.support-fab__button {
  background: $brand--info;
  padding: 5px 15px 0;
  border: none;
  border-radius: 50%;
  height: 60px;
  position: absolute;
  z-index: 2;
  width: 60px;
  right: 0;
  bottom: 0;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  &:focus,
  &:active {
    box-shadow: none;
    outline: none;
  }
}

.support-fab__menu-list {
  min-width: 360px;
  position: absolute;
  z-index: 1;
  right: 15px;
  bottom: -20px;
  background: $nutrien-white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
}

.support-fab__button-icon {
  color: $nutrien-white;
  filter: FlipH;
  height: 32px;
  -moz-transform: scaleX(-1);
  -ms-filter: FlipH;
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  width: 32px;
  -webkit-transform: scaleX(-1);
}

@media (max-width: 768px) {
  .support-fab__menu-list {
    min-width: 280px;
    position: absolute;
    padding-top: inherit;
    z-index: 1;
    right: 15px;
    bottom: -20px;
    background: $nutrien-white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  }

  .support-fab__container {
    bottom: 18px;
    right: 18px;
  }

  .support-fab__button {
    height: 40px;
    width: 40px;
    padding: 5px 0 0 0;
  }

  .support-fab__button-icon {
    height: 20px;
    width: 20px;
  }
}
