@import '../../styles/_index.scss';

.shopping-cart-table__table {
  @include cart-table;
  margin-top: 36px;
  table-layout: fixed;
}

.shopping-cart-table__body {
  @include cart-table-body;
}

.shopping-cart-table__row {
  @include cart-table-row;
}

.shopping-cart-table__header {
  @include cart-table-header;
}

.shopping-cart-table__header--total {
  @include cart-table-header;

  font-weight: 600 !important;
  text-align: right;
}

.shopping-cart-table__close-header {
  clip: rect(0 0 0 0);
  border: none;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.empty-cart__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 0 84px 0;
  text-align: center;

  @media (min-width: 720px) {
    padding: 36px 0 84px 0;
  }
}

.empty-cart__header {
  align-self: center;
  font-weight: 400;
  margin-bottom: 0;
  width: 225px;

  @media (min-width: 720px) {
    width: auto;
  }
}

.empty-cart__message {
  align-self: center;
  margin: 0;
  padding: 12px 0 24px 0;
  width: 250px;

  @media (min-width: 720px) {
    width: auto;
  }
}

.empty-cart__button {
  align-self: center;
  height: 45px;
  width: 80%;

  @media (min-width: 520px) {
    width: 250px;
  }
}

.cart__button--secondary {
  @include cart-button--secondary;

  margin: 24px 0 12px 0;
  width: 100%;
}

.cart__warning {
  font-size: 0.9rem;
  margin: 12px 0;
}

.cart__icon {
  color: #604700;
}

.cart__warning-item {
  font-weight: 600;
  margin-left: 4px;
}

.cart__notification {
  padding-top: 12px;
}

.cart__container {
  @include cart-container;

  @media (min-width: 950px) {
    display: flex;
    justify-content: flex-end;
  }
}

.cart__inner-cart-container--bottom {
  @include inner-cart-container-bottom;
}

.cart__pricing-warning {
  @include sub-total;

  padding: 12px;
  background-color: $alert--warning-1;
  font-weight: 500;
  font-size: 0.8rem;
  max-width: 300px;
}

.cart__totals-wrapper {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  width: 240px;
}

.cart__totals-row--bold {
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  justify-content: space-between;
  padding: 8px 24px 0 0;
}

.cart__totals-row-header {
  display: inline-block;
  padding-right: 4px;
  text-align: right;
  width: 75px;
}

.cart__totals-row {
  display: flex;
  font-size: 0.8rem;
  justify-content: space-between;
  padding: 8px 24px 0 0;
}

.cart__button--primary {
  @include cart-button;
}

.cart__wrapper {
  @include cart-wrapper;
}

.cart__wrapper-employee {
  @include cart-wrapper;

  padding-top: 70px;
}

.cart__back-to-results-wrapper {
  align-items: center;
  color: $nutrien-green;
  display: inline-flex;
  font-size: 12px;
  font-weight: 400;
  padding: 0 0 8px 0;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.cart__back-to-results-icon {
  transform: rotate(90deg);
  width: 24px;
}

.cart__header--container {
  @media (min-width: 950px) {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }
}

.cart__headline {
  @include cart-headline;
}

.cart__purchase-order-wrapper {
  padding-top: 24px;

  @media (min-width: 750px) {
    padding-top: 0;
  }
}
