.ecomm-open__grid {
  padding: 0;

  @media screen and (min-width: 900px) {
    padding: 24px;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .ecomm-open__text-p-top {
    font-size: 16px;
    line-height: 26px;
    color: #222222;
    max-width: 735px;
  }

  .ecomm-open__text-p-link {
    font-size: 16px;
    line-height: 26px;
  }

  .ecomm-open__text-p-bottom {
    font-size: 16px;
    line-height: 26px;
    max-width: 735px;
  }

  .ecomm-open__text-container {
    padding: 0;
    height: auto;

    h2 {
      line-height: 26px;
      font-size: 22px;
      max-width: 735px;
    }

    @media screen and (max-width: 840px) {
      h2 {
        font-size: 20px;
        line-height: 22px;
      }
      h4 {
        font-size: 14px;
      }
    }

    .ecomm-open__text-row {
      @media screen and (max-width: 840px) {
        margin-bottom: 0;

        p {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 12px;
        }
      }
    }

    .ecomm-open__action-row {
      position: relative;
      bottom: 0;

      h4 {
        margin: 0;
      }

      button {
        margin-top: 8px;
        width: 100%;

        @media screen and (min-width: 840px) {
          width: 300px;
        }
      }
    }
  }
}
