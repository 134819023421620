@import "~nutrien-style-guide/src/lib/colors/_colors.scss";
@import "../../styles/index.scss";

// !TEXT----------------------------------------------------------------
.order-memo-instructions {
  @include text-area;
  height: 150px;

  @media (min-width: 700px) {
    height: 100px;
  }
}

.instructions-header__detail {
  color: $nutrien-gray-3;
  font-size: 14px;
  margin-left: 12px;
}

// !HEADERS-------------------------------------------------------------
.instructions-header__headline {
  @include cart-sub-headline;
  margin-bottom: 0px;
}

// !WRAPPERS------------------------------------------------------------
.instructions-header {
  align-items: baseline;
  display: flex;
}
