@import "~nutrien-style-guide/src/lib/colors/_colors.scss";

.selected-date__button {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.selected-date__date-text {
  margin-right: 1rem;
  color: $nutrien-green;
}
