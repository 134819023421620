@import '~nutrien-style-guide/src/lib/colors/_colors.scss';
@import '../../../../../../src/shared/styles/index.scss';

$breakWidth: 1045px;

@mixin horizontal-banner-flag($background) {
  background-color: $background;
  font-weight: 600;
  color: #ffffff;
  padding: 8px 24px;
  align-self: flex-start;
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 20px solid $background;
    border-right: 20px solid rgba(0, 0, 0, 0);
    border-bottom: 20px solid $background;
    margin: -8px -10px 0 23px;
  }
}

%product-info__details {
  padding: 24px;
  width: 100%;

  @media (min-width: $breakWidth) {
    width: 50%;
  }
}

.inner-product-info-container {
  @media (min-width: $breakWidth) {
    width: 313px;
  }
}

.product-info__white-background {
  @mixin page-header {
    flex: 1;
    font-family: 'Open Sans', 'sans-serif';
    font-size: 1.8rem;
    font-weight: 200;
    margin: 24px;
  }

  @mixin no-margin-page-header {
    @include page-header;
    margin: 0;
  }

  background-color: $nutrien-white;

  .product-info__complementary-products-container {
    padding: 24px;
  }

  .product-info__product-label-missing {
    align-items: center;
    display: flex;
    font-size: 13px;
  }

  .product-info__product-label-missing-icon {
    padding-right: 8px;
    width: 55px;
  }

  .product-info__detail-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    & > :first-child {
      order: '1';
    }

    @media (min-width: $breakWidth) {
      width: 100%;
      & > :first-child {
        padding-right: 40px;
        order: 0;
      }
      & > :last-child {
        padding-left: 40px;
      }
    }
  }

  .product-info__detail-container {
    background-color: $nutrien-white;
    display: flex;
    flex-direction: column;

    @media (min-width: $breakWidth) {
      flex-direction: row;
    }
  }

  .product-info__header-container {
    padding: 24px 24px 0px 24px;
    width: 100%;
  }

  .product-info__inner-header-container {
    border-bottom: 1px solid $nutrien-table-border !important;
    padding-bottom: 24px;
  }

  .product-info__detail-container-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .product-info-grid__usa-sap-notification {
      margin: 0 24px;

      .product-info-grid__usa-sap-link {
        text-align: end;
        display: block;
        margin-top: 5px;
      }
    }

    @media (min-width: $breakWidth) {
      width: 77%;
    }
  }

  .product-info__detail-column {
    @extend %product-info__details;
  }

  .product-info__detail-column--right {
    @extend %product-info__details;

    @media (min-width: $breakWidth) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
    }
  }

  .product-info__image-column {
    &.has-contract {
      padding: 24px;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    width: 100%;
    @media (min-width: $breakWidth) {
      &.hasContract {
        padding: 24px;
      }
      padding-top: 64px;
      width: 33%;
    }
  }

  .product-info__contracted-banner {
    @include horizontal-banner-flag(#0093e5);
    margin-left: -40px;
    margin-bottom: 24px;
    @media (min-width: $breakWidth) {
      margin-left: 0px;
      margin-bottom: 0px;
    }
  }

  .product-info__product-description-title {
    @include bold-label;
    padding-left: 0;
    margin: 0;
    font-size: 1.1rem;
    margin-bottom: 14px;
  }

  .product-info__product-description {
    color: $nutrien-dark-grey-2;
    font-family: 'Open Sans', 'sans-serif';
    font-size: 14px;
    font-weight: 600;
    line-height: inherit;
    margin: 0;
  }

  .product-info__product-label-pdf-header {
    @include bold-label;
    font-size: 1.1rem;
    margin: 16px 0;
    padding-left: 0;
  }

  .product-info__product-label-pdf-icon {
    padding-right: 8px;
  }

  .product-info__product-label-pdf-link {
    display: flex;
    font-size: 13px;
  }

  .product-info__product-manufacturer {
    color: $nutrien-green;
    padding-bottom: 5px;
  }

  .product-info__product-manufacturer--missing {
    color: $nutrien-grey-3;
  }

  .product-info__product-name-header {
    @include no-margin-page-header;
    font-weight: 600;
    padding: 12px 0 0 0;
    text-transform: uppercase;
  }

  .product-info__product-options {
    display: flex;
    align-items: center;

    .product-info__total-input {
      margin-left: 21px;
      width: 100%;

      .product-info__total-input--input {
        display: flex;
        align-items: center;

        .product-info__total-input--input-units {
          padding-left: 9px;
        }
      }

      .product-info__total-input--input-label {
        color: #222;
        display: block;
        font-size: 0.9rem;
        font-weight: 600;
      }
    }
  }

  .product-info__variants-wrapper {
    width: 200px;

    @media (max-width: 1300px) {
      width: 100%;
    }
  }

  .product-detail__pack-size-label {
    font-size: 0.9rem;
  }

  .product-info__add-to-cart-button {
    margin: 0;
    width: 100%;
    margin: 32px 0 0 0;
  }

  .product-info__product-prices-loading-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .product-info__product-prices-loading {
    max-height: 12px;
  }

  .product-info__safety-blurb {
    color: #000;
    font-style: italic;
    font-weight: 600;
    line-height: inherit;
    @media (max-width: $breakWidth) {
      width: 75%;
      display: none;
    }
  }

  .product-info__safety-blurb--mobile {
    color: #000;
    margin: 12px 0;
    font-style: italic;
    font-weight: 600;
    line-height: inherit;
    @media (min-width: $breakWidth) {
      display: none;
    }
  }

  .product-info__common-name {
    color: $nutrien-grey-3;
  }
}
