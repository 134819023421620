.banner-images__image {
  min-width: 100%;
  min-height: 210px;
}

.outer-banner-image-wrapper {
  top: 0;
  left: 0;
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 24px 0 60px;
  position: absolute;
  right: 0;
  transform: translatey(5%);
  width: 100%;

  @media screen and (min-width: 500px) {
    transform: translatey(25%);
  }

  @media screen and (min-width: 768px) {
    margin-top: 0;
    padding: 0 24px 0 74px;
    transform: translatey(50%);
  }
}

.banner-images__container {
  left: 0;
  overflow: hidden;
  margin-top: 4em;
  position: absolute;
  right: 0;
  top: 3em;

  @media screen and (min-width: 1280px) {
    margin-top: 0;
  }
}

.banner-images__container__unsupported-browser {
  top: 10rem;
}
