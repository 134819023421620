@import "~nutrien-style-guide/src/lib/colors/_colors.scss";

// !WRAPPERS-------------------------------------------------------------
.products-modal {
  padding: 24px;
  max-width: 650px;
  width: 200px;

  @media (min-width: 400px) {
    width: 300px;
  }

  @media (min-width: 700px) {
    padding: 48px 48px 0 48px;
    width: auto;
  }
}

.product-modal__outer-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.products-modal__button-wrapper {
  border-top: none !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 32px;
  width: 219px;

  @media (min-width: 700px) {
    padding: 0 48px 48px 48px !important;
  }
}

.products-modal__checkbox-wrapper {
  display: flex;
  justify-content: left;
}

.products-modal__checkbox-wrapper__blurb {
  display: flex;
  padding-top: 12px;
  color: #000;
  font-weight: 400;
  line-height: 1.35;
}

.products-modal__content {
  display: flex;
  flex-direction: column;

  @media (min-width: 700px) {
    flex-direction: row;
  }
}

.products-modal__content--inner {
  padding-left: 24px;
}

.products-modal__logo-wrapper {
  display: flex;
  justify-content: center;
  min-width: 125px;
  width: 100%;

  @media (min-width: 700px) {
    justify-content: flex-start;
  }
}

.products-modal__footer {
  border-top: none !important;
  padding: 0 24px 24px 0 !important;
  @media (min-width: 700px) {
    padding: 0 48px 48px 0 !important;
  }
}

.products-modal__dialog {
  .mdc-dialog__surface.mdc-dialog__surface {
    max-width: 700px;
  }
}

// !TEXT-------------------------------------------------------------
.products-modal__header {
  font-size: 1.3rem;
  font-weight: 600;
}

.products-modal__blurb {
  color: #000;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.35;
  margin: 0;
  padding: 0;
}

// !IMAGES-------------------------------------------------------------
.products-modal__logo {
  width: 135px;

  @media (min-width: 700px) {
    width: 100%;
  }
}
