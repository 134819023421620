@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

.variant-options__shopping-cart-icon {
  margin-left: 2px;
  padding: 3px;
}

.variant-options__prices-loader {
  max-width: 150px;
  padding: 11px 0 11px 0;
}

.variant-options__variant-options {
  width: 100%;
}

.variant-options__add-to-cart-button {
  margin: 20px 0px !important;
  min-height: 40px;
  width: 100%;

  &:hover {
    background-color: #6fc202;
  }
}

.dropdown__option-name {
  color: #000;

  &:hover {
    color: #000;
  }
}
