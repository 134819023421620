@import "~nutrien-style-guide/src/lib/colors/_colors.scss";

.mobile-cart-stepper__stepper {
  background-color: #eee !important;
  border-top: 1px solid $nutrien-grey--inactive-outline;
  width: 100%;
  display: flex;

  @media (min-width: 750px) {
    display: none !important;
  }
}
