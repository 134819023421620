
.api-error-message {
  margin: 2em auto;
  width: 270px;
  color: #828282;
  text-align: center;
}

.api-error-message__icon {
  font-size: 1.4em;
  margin: 0.5em;
  color: #d24242;
}

.api-error-message__message {
  font-size: 1.2;
}
