.cps-account-information {
  width: 100%;
}

.cps-account-label-large {
  color: #004538;
  font-size: 24px;
  margin-bottom: 25px;
}

.cps-account-label-small {
  color: #828282;
  font-size: 12px;
  margin-left: 5px;
}

.cps-account-label {
  color: #464646;
  display: block;
  width: 401px;
  height: 19px;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 5px;
}

.cps-account-label span {
  margin-left: 5px;
}

.cps-account-notes-label {
  margin-top: 30px;
  width: 400px;
  height: 18px;
  font-size: 14px;
  font-weight: 600;
}

.cps-add-funds-notes {
  border-radius: 2px;
  border: 1px solid #d7d7d7;
  margin-bottom: 30px;
  height: 136px;
  width: 401px;
  resize: none;
  padding: 5px;
  font-size: 14px;
}

.pre-pay-info-message-container {
  display: flex;
  max-width: 400px;
  padding-top: 10px;
}

.pre-pay-info-message-text {
  margin-left: 10px;
}

.selected-account-container {
  width: 100%;
  border-top: 1px solid #ccc;
  margin-top: 30px;
  padding-top: 20px;
}

@media (max-width: 480px) {
  .selected-account-container textarea {
    width: 100%;
  }
}
