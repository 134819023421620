.ecomm-hub-ad__container {
  @media screen and (min-width: 1024px) {
    padding: 24px 24px 16px;
    display: flex;
    justify-content: space-between;
  }

  .ecomm-hub-ad__grid {
    padding: 0;

    h2 {
      line-height: 32px;
      font-size: 28px;
    }

    @media screen and (min-width: 720px) and (max-width: 1023px) {
      padding: 8px;
    }

    .ecomm-hub-ad__title-cell {
      margin-bottom: 32px;

      @media screen and (min-width: 720px) {
        margin-bottom: 16px;
      }
    }

    .ecomm-hub-ad__button-container {
      display: flex;
      align-items: center;
      flex-direction: column;

      @media screen and (min-width: 1024px) {
        flex-direction: row;
        align-items: flex-start;
      }

      .ecomm-hub-ad__badge-image {
        width: 200px;
        margin-bottom: 6px;
      }

      .ecomm-hub-ad__first-button,
      .ecomm-hub-ad__second-button {
        height: 52px;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        width: 100%;

        @media screen and (min-width: 1024px) {
          width: auto;
        }
      }

      .ecomm-hub-ad__first-button {
        margin-bottom: 16px;

        @media screen and (min-width: 1024px) {
          margin-bottom: 0;
        }
      }

      .ecomm-hub-ad__second-button {
        margin-bottom: 8px;

        @media screen and (min-width: 1024px) {
          margin-left: 18px;
          margin-bottom: 0;
        }
      }
    }

    .ecomm-hub-ad__text-p-link {
      font-size: 16px;
      line-height: 26px;
    }

    .ecomm-hub-ad__text-grid {
      padding: 0;
      height: auto;

      .ecomm-hub-ad__platforms-image-container {
        margin: 0 16px 16px;
      }

      .ecomm-hub-ad__text-cell {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 20px;

        @media screen and (min-width: 1024px) {
          margin-bottom: 8px;
        }

        .ecomm-hub-ad__text-p-top {
          font-size: 16px;
          line-height: 26px;
          color: #222222;
          margin-bottom: 0;

          @media screen and (min-width: 1024px) {
            font-size: 20px;
          }

          a {
            font-size: 16px;
            line-height: 26px;
            text-decoration: underline;

            @media screen and (min-width: 1024px) {
              font-size: 20px;
            }
          }

          @media screen and (min-width: 720px) and (max-width: 1023px) {
            width: 60%;
          }
        }
      }
    }
  }

  .ecomm-hub-ad__platform-image {
    width: 100%;
    object-fit: contain;

    @media screen and (min-width: 720px) {
      width: 40%;
      margin: 0;
    }

    @media screen and (min-width: 1024px) {
      padding-left: 20px;
    }
  }
}

.ecomm-hub-app-modal {
  .mdc-dialog__surface {
    border-radius: 10px;
    min-width: 680px;
    position: relative;
  }

  &__download-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__content-container {
    display: flex;
    padding: 34px 50px 34px 34px;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__qr-codes-container {
    margin-top: 20px;
    display: flex;
  }

  &__qr-code {
    margin-bottom: 30px;
  }

  &__close-container {
    position: absolute;
    margin: 12px 12px 0;
    right: 0;
  }

  &__close-icon {
    margin: 0;
    position: static;
    fill: #464646;
  }

  &__badge-image {
    width: 200px;
  }

  &__hub-mobile {
    height: 300px;
  }

  &__apple-links,
  &__google-links {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__apple-links {
    margin-right: 12px;
  }
}
