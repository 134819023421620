.product-labels_table{
  width: 100%;
}

.product-labels__container {
  overflow: hidden;
}

.product-labels__item-name-label,
.product-labels__order-date-label{
  color: #828282;
  font-size: 12px;
  margin: 0 0 5px;
  font-weight: 400;
}

.product-labels__item-name-data,
.product-labels__order-date-data{
  margin: 0;
  font-weight: 600;
  font-size: 1em;
  color: #000;
}

.product-labels__title{
  color:#004538;
    font-weight: 400;
    font-size: 32px;
}

.product-labels__item{
  border-bottom: 1.6px solid #d7d7d7;
}

.product-labels__item:last-child {
  border-bottom: none;
}

.product-labels__item > td{
  padding: 1em 10px;
}


.product-labels__item > td > a{
  text-decoration: none;
}

.product-labels__pipe{
  font-weight: 400;
  color: #828282;
}

.product-labels__pagination{
  margin: 1em 0;
}

.product-label__breadcrumb-hr{
  margin: 1em 0;
}

@media screen and (max-width: 768px){

  .product-label__breadcrumb-hr{
    margin: initial;
  }

  .product-labels__title{
    font-size: 30px;
    font-weight: 400;
  }

  .product-labels__item{
      display: flex;
      flex-wrap: wrap;
      width:100%;
  }

  .product-labels__item > td{
    padding: 1em 0;
  }

  .product-labels__pagination{
    display:none;
  }

  .product-labels__item > td{
      display: block;
      width: 100%;
  }

}
