@import "~nutrien-style-guide/src/lib/colors/_colors.scss";

.login__container--browser-not-supported {
  margin-top: 400px;
}

.login__sign-up-link {
  font-size: 20px;
}

.login__two-panel-form-card {
  @media only screen and (min-width: 720px) {
    width: auto;
    max-width: 1000px;
  }

  hr {
    margin: 0;
  }
}

.login__content-container {
  display: flex;
  flex-wrap: wrap;

  &-section {
    max-width: 100%;
    width: 500px;

    &-title {
      font-size: 1.2em;
      font-weight: 600;
      color: $nutrien-dark-grey-2;
      margin-bottom: 28px;
    }

    &-text {
      color: $nutrien-dark-grey-2;
      margin: -11px 0 10px;
    }
  }

  &-desktop-hr {
    display: none;
  }

  &-mobile-hr {
    width: 100%;
    margin: 28px 0 0;
  }

  @media only screen and (min-width: 720px) {
    flex-wrap: nowrap;

    &-desktop-hr {
      display: block;
      height: auto;
      margin: 0 2em;
      border: 1px solid $nutrien-grey--inactive-outline;
    }

    &-mobile-hr {
      display: none;
    }
  }
}
