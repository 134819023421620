@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

@mixin green-outline {
  border: solid 1px $nutrien-green;
}

@mixin red-outline {
  border: solid 1px $brand--danger;
}

.total-count__input {
  border-radius: 4px;
  border: 1px solid #ccc;
  display: block;
  height: 39px;
  margin: 2px 0 3px;
  padding: 0px 10px;
  text-align: left;
  width: 100%;
  min-width: 60px;

  &:active {
    @include green-outline;
  }
  &:focus {
    @include green-outline;
  }

  @media (max-width: 330px) {
    padding: 0px;
  }
}

.total-count__input--error {
  @include red-outline;

  &:active {
    @include red-outline;
  }
  &:focus {
    @include red-outline;
  }
}
