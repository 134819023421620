@import '~nutrien-style-guide/src/lib/colors/_colors.scss';
.transaction-message {
  padding: 0 20px;
}

.row-link {
  width: 100%;
  color: black;
  font-weight: 300;
}

.row-link:hover {
  color: black;
  text-decoration: none;
}

.end-of-activity {
  display: block;
  text-align: center;
  width: 100%;
  padding: 20px;
}

.account-title-selector {
  color: #222222;
  font-weight: normal;
}

.account__account-selector-caret {
  color: #222222;
  height: 30px;
  margin-left: 1px;
}

.account__account-selector__clickable {
  cursor: pointer;
}

.account__account-selector {
  font-size: 2em;
  font-weight: 600;
  color: #222222;
  margin: 0;
}

.account-selector-container {
  position: relative;
}

.account-selector-dropdown {
  background: #fff;
  padding: 0;
  overflow: hidden;
  border: solid 1px #dedede;
  border-radius: 4px;
}

.account__account-selector-dropdown-container {
  position: absolute;
  left: -20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 200ms ease-in;
  margin: 0 10px;
}

.account__account-selector-dropdown-container__active {
  z-index: 200;
  max-height: 30em;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.25);
}

.account-selector-dropdown-dismisser {
  position: absolute;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
}

.account-selector-dropdown-dismisser__active {
  display: block;
}

.account-selector-link {
  margin: 0;
  cursor: pointer;
  background: #fff;
  transition: background 0.5s;
  padding: 10px 10px;
  font-size: 2em;
  color: #222222;
  border-bottom: 1px solid #ddd;
}

.account-selector-link:hover {
  background: rgb(212, 228, 220);
  transition: background 0.5s;
}

.account-tab-selector {
  border-bottom: 1px solid #ccc;
  margin-bottom: 24px;
}

.accountTabs__item:hover {
  background-color: rgba(76, 158, 0, 0.12);
  color: $nutrien-green;
}

.accountTabs__item.Mui-selected {
  color: $nutrien-green;
}

.account__account-label {
  margin: 10px;
  font-weight: 600;
  display: none;
}

.account__account-label > p {
  color: #175549;
}

.account-hr-container {
  margin: 17px 20px 12px 20px;
}
.account-hr-container > hr {
  height: 0.7px;
}

.account-account-selector-caret {
  transition: transform 0.25s;
  text-align: right;
}

.account-account-selector-caret__active {
  transform: rotate(180deg);
  transition-timing-function: ease-in-out;
}

@media only screen and (max-width: 768px) {
  .account__account-label {
    display: block;
  }

  .account-title-selector {
    margin: 0;
    border: 1.5px solid #ccc;
    border-radius: 3px;
    padding: 1px 0;
  }

  .account__account-selector__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }

  .account__account-selector {
    font-size: 16px;
    padding: 0 16px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .account-selector-link {
    font-size: 0.8em;
    padding: 10px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .account__account-selector-caret {
    height: 8px;
  }

  .account-selector-dropdown {
    border: 1px solid #ccc;
  }

  .account__account-selector-dropdown-container {
    left: 0;
    width: 100%;
    margin: 2px 0;
  }

  .account__account-selector-dropdown-option {
    width: 100%;
  }
}
