.ecomm-promo__grid {
  padding: 0;

  @media screen and (min-width: 900px) {
    padding: 24px;
  }

  img {
    width: 100%;
  }

  .ecomm-promo__text-container {
    padding: 0;
    height: auto;

    h2 {
      line-height: 26px;
    }

    @media screen and (min-width: 840px) and (max-width: 1000px) {
      h2 {
        font-size: 20px;
        line-height: 22px;
      }
      h4 {
        font-size: 14px;
      }
    }

    .ecomm-promo__text-row {
      margin-bottom: 32px;

      p {
        font-size: 18px;
        margin: 0;
        line-height: 26px;
      }

      @media screen and (min-width: 840px) and (max-width: 1150px) {
        margin-bottom: 0;

        p {
          font-size: 14px;
          line-height: 18px;
        }
      }

      @media screen and (min-width: 1280px) {
        margin-bottom: 72px;
      }
    }

    .ecomm-promo__action-row {
      position: relative;
      bottom: 0;

      h4 {
        margin: 0;
      }

      button {
        margin-top: 8px;
        width: 100%;

        @media screen and (min-width: 840px) {
          width: 300px;
        }
      }
    }
  }
}
