.button {
  width: 100%;
  border-radius: 5px;
  padding: 8px 15px;
  margin: 0.5em 0;
  text-align: center;
  color: #fff;
  background: #4c9e00;
  border: solid 1px #4c9e00;
  cursor: pointer;
  font-weight: 600;
  font-size: 1em;
  line-height: 1.75em;
}

.button:disabled:hover {
  cursor: not-allowed;
}

.button:hover,
.button:active,
.button:focus {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.button:active {
  border: 2px solid #00b455;
  color: #00b455;
  background: #fff;
}

.button-container {
  width: 90%;
  margin: 0px 5%;
}

.button--short {
  font-size: 14px;
  padding: 5px 10px;
  width: 155px;
}

.button--reverse-color {
  color: #00b455;
  background: #fff;
}

.button--reverse-color:active {
  color: #fff;
  background: #4c9e00;
}

.button--long {
  width: 100%;
  padding: 5px 15px;
  margin: 1em 0;
}

.button--half {
  width: 47%;
  font-size: 1.1em;
}

.button--skip {
  color: #00b455;
  background: white;
  font-weight: normal;
}

@media only screen and (max-width: 900px) {
  .button-container {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 0.5em 1em;
  }

  .button-container--reverse {
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
  }

  .button--half {
    max-width: 500px;
    flex-basis: 100%;
    width: 100%;
    display: block;
    margin: 1.25em auto 0;
  }
}
