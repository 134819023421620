$nutrien-green: #4c9e00;

.delete-scheduled {
  .mdc-dialog__surface {
    border-radius: 1px;
    max-width: 700px;
    width: 700px;
  }

  &__button-container {
    display: flex;
  }

  &__close-button {
    background: none;
    border: none;
  }

  &__title-container {
    display: flex;
    justify-content: space-between;
    position: relative;
    bottom: 1em;
  }
}
