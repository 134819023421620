.common-password-modal {
  &:before {
    content: "";
    background-color: #000;
    opacity: 0.7;
    position: fixed;
    width: 100%;
    height: 100%;
  }

  top: 50px;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 100;
}
