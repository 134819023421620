.amount-entered {
  width: 190px;
  height: 40px;
  border-radius: 2px;
  border: solid 1px #cccccc;
  background-color: #ffffff;
}

.error {
  border: 1px solid #f13b39;
}

.amount-entered.error {
  margin-bottom: 10px;
}

.amount-label {
  display: block;
  width: 190px;
  height: 16px;
  font-size: 14px;
  font-weight: 600;
  color: #222222;
  margin-bottom: 12px;
}
