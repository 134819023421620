@import "~nutrien-style-guide/src/lib/colors/_colors.scss";

.invoicelist-filter-button {
  background: none;
  border: 1px solid $nutrien-table-border;
  border-radius: 2px;
  padding: 4px 4px 4px 8px;
  margin-top: 10px;
  color: $nutrien-gray-2;
  display: block;
  width: 100%;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__icon {
    display: block;
    fill: $nutrien-table-border;
    text-align: right;
  }

  &__value {
    display: block;
    flex-grow: 1;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
