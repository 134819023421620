@import "~nutrien-style-guide/src/lib/colors/_colors.scss";

.terms-conditions-modal__modal-container {
  background-color: $nutrien-background;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 435px;
  left: 0;
  margin: auto;
  padding: 0 20px;
  position: absolute;
  right: 0;
  top: 0;
  width: 800px;
  z-index: 200;

  @media (max-width: 825px) {
    width: 95%;
    height: 450px;
  }
}

.terms-conditions-modal__modal-header {
  border-bottom: 1px solid $nutrien-grey--inactive-outline;
  flex: 1;
  padding-top: 20px;
  padding-bottom: 10px;
  display: inline-flex;
  width: 100%;

  @media (max-width: 560px) {
    flex: 2;
  }

  .terms-conditions-modal__modal-header-h2 {
    color: $nutrien-dark-grey-2;
    display: inline-flex;
    font-size: 24px;
    font-weight: 600;
    justify-self: flex-start;
    width: 95%;
  }

  .terms-conditions-modal__button--close {
    align-items: flex-start;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 10px;

    & :focus {
      outline: none;
    }
  }
}

.terms-conditions-modal__modal-body {
  border-bottom: 1px solid $nutrien-grey--inactive-outline;
  flex: 3;
  margin-bottom: 30px;
  overflow-y: scroll;

  & > p {
    padding-top: 10px;
  }

  & p {
    color: $nutrien-grey-2;
    font-size: 13px;
    line-height: 1.5;
    padding-bottom: 10px;
  }
}

.terms-conditions-modal__modal-footer {
  align-items: center;
  display: inline-flex;
  flex: 1;
  justify-content: center;
  width: 100%;
}

.terms-conditions-modal__overlay {
  background-color: black;
  bottom: 0;
  left: 0;
  opacity: 0.2;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100;
}

.terms-conditions-modal__modal-container--hide {
  display: none;
}
