@import "~nutrien-style-guide/src/lib/colors/_colors.scss";
@import "../../../styles/index.scss";

// !WRAPPERS-------------------------------------------------------------
.services-blurb-wrapper {
  padding-top: 3px;
}

// !TEXT-----------------------------------------------------------------
.services-wrapper__headline {
  @include cart-sub-headline;
  color: #000;
}
.services-name {
  color: $nutrien-grey-3;
  font-weight: normal;
}
.services__headline--secondary {
  font-size: 0.9rem;
  font-weight: 600;
}

.services__headline--detail {
  color: $nutrien-dark-grey-2;
  display: inline-block;
  font-size: 0.9rem;
  font-weight: 600;
}

.services-blurb {
  color: $nutrien-dark-grey-2;
  font-weight: 600;
  font-size: 0.95rem;
  line-height: 1.3rem;
  margin: 0;
  max-width: 600px;
  padding: 0;
}
.print-section-wrapper {
  .services-wrapper {
    h2 {
      margin: 0 0 8px 0;
    }
  }
}
