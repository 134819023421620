@import "~nutrien-style-guide/src/lib/colors/_colors.scss";

.login-form {
  &__notification-container {
    margin-bottom: 15px;

    & svg {
      margin-top: 3px;
    }
  }

  &__content-section {
    max-width: 100%;
    width: 500px;

    &-title {
      font-size: 1.2em;
      font-weight: 600;
      color: #222;
      margin-bottom: 28px;
    }
  }

  &__link-container {
    width: 100%;
    text-align: right;
    margin-bottom: 30px;

    &-link {
      font-size: 13px;
      font-weight: 400;
    }
  }

  &__error {
    color: $brand--danger;
    font-size: 13px;
    font-weight: 200;
  }
}
