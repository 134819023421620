@import "~nutrien-style-guide/src/lib/colors/_colors.scss";
@import "../../../../Cart/styles/index.scss";
// !LOCAL VARS-------------------------------------------------------------
$break-small: 1000px;

// !TABLE-------------------------------------------------------------
.order-table {
  @include cart-table;
}

.order-table__body {
  @include cart-table-body;
}

.order-table__row {
  @include cart-table-row;
}

.order-table__header {
  @include cart-table-header;

  font-weight: 600 !important;

  @media (min-width: $break-small) {
    padding: 12px 24px 12px 12px !important;
  }

  @media print {
    padding: 12px 24px 12px 0px !important;
  }
}

.order-table__header--last {
  @include table-header-last;

  font-weight: 600 !important;
}
