@import "~nutrien-style-guide/src/lib/colors/_colors.scss";

.invoice-id-filter__input {
  width: 100%;
  height: 34px;
  border: 1px solid $nutrien-table-border;
  border-radius: 2px;
  margin-top: 10px;
  padding: 4px;
}
