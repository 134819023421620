@import "~nutrien-style-guide/src/lib/colors/_colors.scss";

.licenses-card__wrapper {
  color: $nutrien-dark-grey-2;
  padding-bottom: 14px;
  position: relative;
  height: 100%;
}

.licenses-card__center-content {
  width: 100%;
  text-align: center;
}

.licenses-card__icon {
  font-size: 68px;
  height: 68px;
  width: 68px;
  color: $nutrien-green;
  &:hover {
    color: #6fc202;
  }
}

.licenses-card__license-count-wrapper {
  padding: 30px 0;
}

.licenses-card__license-count-content {
  width: 100%;
  text-align: center;
}

.licenses-card__license-count-text {
  font-size: 28px;
  font-weight: 600;
  margin: 0;
  vertical-align: middle;
  line-height: 1em;
  color: #4f4f4f;
}

.licenses-card__license-count-description {
  font-size: 14px;
  color: #828282;
}

.licenses-card__view-all-link {
  position: absolute;
  bottom: 0;
  left: 0;
}
