.terms-of-use h2 {
  margin: 0 0 16px;
}

.terms-of-use h3 {
  margin: 0 0 16px;
}

.terms-of-use table {
  display: block;
  max-width: 100%;
  overflow-x: scroll;
}

.terms-of-use tr {
  border-bottom: solid 1px #cccccc;
}

.terms-of-use th {
  min-width: 100px;
}

.terms-of-use th,
.terms-of-use td {
  padding: 8px;
}

.terms-of-use__container {
  margin-bottom: 32px;
}
