.support-info__section {
  padding: 20px 20px 0 20px;
  white-space: nowrap;
}

.support-info__section
.support-info__section__icon {
  float: left;
  margin-top: -3px;
  font-size: 18px;
}

.support-info__section__text {
  display: inline-block;
  vertical-align: text-bottom;
}

.support-info__section__text p {
  margin-bottom: 8px;
  white-space: normal;
}
