.employee .account-menu-toggle {
  height: 100%;
  box-sizing: border-box;
  align-items: center;
}

.employee #responsive-hamburger-menu {
  display: block;
  max-height: 100%;
}

.employee .responsive-menu__button:before,
.employee .responsive-menu__button:after,
.employee .responsive-menu__button span {
  width: 30px;
}

/* media queries meant to overwrite properties in Header.scss which do not work well with the Employee implementation*/
@media only screen and (max-width: 900px) {
  .employee .menu {
    background-color: #ffffff; /* Old browsers */
    flex: initial;
    flex-direction: row-reverse;
    height: initial;
    left: initial;
    max-height: initial;
    overflow-y: hidden;
    position: relative;
    top: initial;
    transition: 0.25s max-height ease-in-out;
    width: 300px;
  }

  .employee .menu > * {
    display: flex;
    justify-content: initial;
    flex-direction: row;
  }

  .employee #responsive-hamburger-menu {
    margin-right: 0;
  }

  .employee .menu.open-menu {
    height: 2em;
  }

  .employee .responsive-menu__button-container {
    right: initial;
  }
}
