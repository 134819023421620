.page-header {
  &__container {
    padding-top: 0;
    padding-bottom: 24px;
  }
  &__title {
    margin-bottom: 0;
    margin-left: -1px;
  }
  &__margin-top {
    margin-top: 24px;
  }
  &__breadcrumbs {
    padding-bottom: 24px;
  }
}

@media only screen and (min-width: 769px) {
  .page-header {
    &__container {
      padding-bottom: 32px;

      .account-title-selector {
        margin-left: -2px;
      }
    }
  }
}
