@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

.schedule-payment-container {
  width: 100%;

  .schedule-payment-section {
    display: flex;
    flex-direction: column;
    padding: 1.5625rem 0 1.5625rem 0;
    border-top: 0.0625rem solid $nutrien-grey--inactive-outline;
    margin-top: 1.875rem;

    h2 {
      color: #004538;
      font-size: 1.5rem;
      margin-bottom: 0;
    }

    .schedule-payment-blackout-notification {
      margin: 20px 0 10px;
    }

    .schedule-payment-content {
      display: flex;
      margin-top: 0.9375rem;

      .schedule-input-container {
        display: flex;
        flex-direction: column;
        margin-right: 10rem;
      }

      @media (max-width: 720px) {
        flex-direction: column;
      }
    }

    .recurring-payment-checkbox-label {
      display: inline-flex;
      align-items: center;
      padding-top: 1rem;
    }
  }
}
