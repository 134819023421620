@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

.default-button {
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
}

// !WRAPPERS------------------------------------------------------------
.dialog-chip-wrapper {
  padding: 16px 24px 24px;
}
.dialog-button-wrapper {
  border-top: none;
  display: flex;
  padding: 24px;
}
.dialog-wrapper--outer {
  display: flex;

  @media (min-width: 590px) {
    display: none !important;
  }
}
.dialog-wrapper--outer {
  display: flex;

  @media (min-width: 590px) {
    display: none !important;
  }
}
.dialog-wrapper--outer {
  display: flex;

  @media (min-width: 590px) {
    display: none !important;
  }
}
.dialog-wrapper {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (min-width: 590px) {
    background-color: $nutrien-light-grey-2;
  }
}
.filter-wrapper {
  background-color: #fff;
  padding-bottom: 24px;

  @media (min-width: 590px) {
    background-color: #f7f7f7;
    min-width: 300px;
    max-width: 310px;
  }
}
.filter-wrapper--desktop {
  display: none;

  @media (min-width: 590px) {
    display: block;
  }
}

.filter-header {
  display: flex;
  justify-content: space-between;
  padding: 12px 24px 0px;
}

.dialog-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 24px 12px 0;
}

// !BUTTONS------------------------------------------------------------
.filter-header__button--clear {
  @extend .default-button;
  color: $nutrien-green;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0;
}
.dialog-wrapper__button--cancel {
  margin: 0;
}
.dialog-wrapper__button--apply {
  margin: 0 0 0 8px !important;
}
.dialog-header__clear-button {
  background-color: transparent;
  border: none;
  color: $nutrien-green;
  cursor: pointer;
  outline: 0;
}

// !OTHER------------------------------------------------------------
.filter-chip {
  margin: 8px 8px 0px 0px;
  margin-right: 8px;

  &.oversized {
    max-width: 260px;

    .MuiChip-label {
      padding: 0;
      margin: 0 12px;
      overflow-x: hidden;
    }
  }
}
