.open-items-page__pagination {
  margin: 20px 0;
}

.end-of-open-items {
  margin: 1rem 0;
  display: flex;
  justify-content: space-around;
}

.no-account-open-items {
  font-size: 1rem;
}

.account__filter-title {
  display: flex;
}

.filter-line-items {
  margin-left: auto;
  margin-right: 20px;
  border-radius: 4px;
  border: 1.5px solid #ccc;
  color: #175549;
  font-size: 15px;
  font-weight: 600;
  padding: 3px 5px;
}

@media screen and (max-width: 1000px) {
  .account__filter-title {
    padding: 30px 0 10px 0;
  }
}

@media screen and (max-width: 768px) {
  .account__filter-title {
    flex-direction: column;
  }
}
