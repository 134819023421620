@media screen and (max-width: 768px) {
  .date-range-picker__container {
    .MuiFormControl-root {
      .MuiInputBase-root {
        .MuiInputBase-input {
          font-size: 16px;
        }
      }
    }
  }
}
