@import "~nutrien-style-guide/src/lib/colors/_colors.scss";

.purchases-card__wrapper {
  position: relative;
  height: 520px !important;
  width: 100%;
}

.purchases-card__title {
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  padding: 0;
  margin: 0 0 10px 0;
  color: $nutrien-dark-grey-2;
  &:hover {
    color: $nutrien-dark-grey-2;
  }
}

.purchases-card__content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 300;
  color: $nutrien-grey-2;
}
.purchases-card__content--centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.purchases-card__icon-wrapper {
  border: 0;
  background-color: #ffffff;
  color: $nutrien-grey-2;
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0 20px 0;
  svg {
    margin-bottom: -10px;
  }
}
.purchases-card__history-icon--oversized {
  width: 94.5px;
  height: 81px;
  text-align: center;
}
.purchases-card__shelf {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.purchases-card__shelf-name {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  img {
    width: 60px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .purchases-card__shelf-name--chemical {
    width: 30px;
  }
}

.purchases-card__shelf-purchase {
  font-size: 36px;
  font-weight: 600;
  color: #595959;
  padding: 0 0 20px 5px;
}

.purchases-card__view-all-link {
  font-size: 14px;
  padding: 16px 0;
  font-weight: 600;
  font-style: normal;
  color: $nutrien-green;
  &:hover {
    filter: brightness(85%);
  }
}
