@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

.mini-cart-wrapper {
  left: 0;
  margin: 0 auto;
  pointer-events: none;
  position: fixed;
  right: 25px;
  top: 76px;
  z-index: 200;
  display: flex;
  justify-content: flex-end;
  height: auto;

  &.active {
    overflow: visible;
  }
  @media (max-width: 840px) {
    margin-top: 60px;
  }

  @media (max-width: 480px) {
    margin-top: 40px;
    width: 100%;
    height: calc(100vh - 116px);

    &.active {
      overflow-y: scroll;
    }
  }
}

.mini-cart {
  background-color: $nutrien-white;
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
    0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  margin-top: 0;
  padding: 24px;
  pointer-events: auto;
  width: 350px;

  transform: translateY(calc(-100% - 180px));
  // is needed for IE 11
  -ms-transform: translateY(-100%) translateY(-180px);
  transition: transform 0.3s ease-in-out;

  @media (max-width: 480px) {
    width: 100%;
    height: 100%;
    padding: 20px;
    overflow-y: scroll;
  }

  &.active {
    transform: translateY(0);
  }
}

.mini-cart__header {
  border-bottom: 1px solid $nutrien-table-border;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.mini-cart__image-wrapper {
  align-items: center;
  background-color: $nutrien-white;
  display: flex;
  height: 140px;
  justify-content: center;
}

// !TEXT-------------------------------------------------------------

.mini-cart__headline {
  font-weight: 600;
  padding-right: 5px;
  font-size: 24px;
}

.mini-cart__secondary {
  color: $nutrien-grey-2;
  font-size: 0.7rem;
}

// !BUTTONS-------------------------------------------------------------
.mini-cart__button {
  height: 0.8em;
  width: 0.8em;

  &:hover {
    cursor: pointer;
  }
}

.mini-cart__icon {
  margin: 0 8px 0 0;
}
