.page-404__header-container{
    width: 100%;
    display: block;
    float: left;
}

.page-404__header-content{
    background:rgba(255, 140, 0, 0.658);
    float: left;
    color:#fff;
    padding: 0px 10px;
    font-size: 3.5em;
    line-height: 1.8em;
    font-weight: 600;
}

.page-404__header-spacer{
    margin-top: 3em;
}

.page-404__content{
    float: left;
    width: 100%;
}

.page-404__back-button{
    background:rgba(255, 140, 0, 0.658);
    float: left;
    color:#fff;
    padding: 5px 15px;
    margin-bottom: 3em;
    cursor: pointer;
}

.page-404__content-title{
    color:#074A3E;
}

.page-404__content-container{
    margin: 20px 20px;
    width: 100%;
}

.page-404__bottom-link{
    margin-left: 10px;
}

.page-404__link-arrow{
    height: .5em;
}

.page-404__content-back-button{
    color: #4c9e00;
    cursor: pointer;
    margin: 10px 0;
    font-weight: 600;
}

@media screen and (max-width: 768px){

    .page-404__header-content{
        line-height: 1.6em;
        font-size: 2.3em;
    }

    .page-404__back-button{
        margin-top: 20px;
        font-size: .9em;
    }
}


@media screen and (max-width: 400px){

    .page-404__header-content{
        line-height: 2em;
        font-size: 1.5em;
    }
}
