.role-authorization__ul {
  list-style: none;
}

.role-authorization__ul li::before {
  color: #464646;
  content: "•";
  font-weight: bold;
  margin-right: 10px;
  width: 1em;
}
