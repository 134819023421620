@import '~nutrien-style-guide/src/lib/colors/_colors.scss';

.checkout-item__row {
  padding: 3px 0;
  display: flex;
}

.checkout-item__row-header {
  color: $nutrien-grey-3;
  display: inline-block;
  font-size: 0.8rem;
  width: 108px;
}

.checkout-item__data {
  font-size: 0.9rem;
  font-weight: 600;
}

.checkout-item__no-price {
  height: 40px;
  font-size: 0.8rem;
}

.checkout-item__total-price-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.checkout-item__divider {
  border-top: 1px solid $nutrien-grey--inactive-outline !important;
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
}

.checkout-item__text--highlight {
  display: block;
  font-weight: 600;
  padding-bottom: 8px;
}
.checkout-item__no-price-container {
  height: 40px;
  font-size: 0.8rem;
  @media (max-width: 321px) {
    padding-left: 24px;
  }
}
