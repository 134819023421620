@import "~nutrien-style-guide/src/lib/colors/_colors.scss";
@import "../../../styles/index.scss";

// !TEXT-------------------------------------------------------------
.consultant-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.consultant__subheader {
  display: inline-block;
  font-size: 0.5rem;
  margin-right: 12px;
  color: $nutrien-gray-3;
}

.consultant__data {
  font-size: 0.5rem;
  font-weight: 600;
}

.consultant__row {
  font-weight: 600;
  display: flex;
}
