@import "../../../../Cart/styles/_index.scss";

// !LOCAL VARS-------------------------------------------------------------
$break-small: 1000px;
$break-xsmall: 600px;

.order-item__wrapper {
  @include cart-table-row;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  margin-top: 12px;

  @media (min-width: $break-small) {
    border-radius: 0;
    box-shadow: none;
    margin-top: 0;
  }

  @media print {
    border-radius: 0;
    box-shadow: none;
    margin-top: 0;
  }
}

.order-item__table-data--full {
  @include table-data-full;
}

.order-item__header {
  @include mobile-header;
}

.order-item__product-text {
  @include highlight-text;
  color: $nutrien-dark-gray-2;
}

.order-item__table-data {
  @include table-data-standard;

  width: 50%;

  @media (min-width: $break-xsmall) {
    width: 33.33%;
  }

  @media (min-width: $break-small) {
    padding: 12px 24px 12px 12px !important;
    width: 25%;
  }

  @media print {
    padding: 12px 24px 12px 0px !important;
  }
}

.order-item__header--mobile {
  @include mobile-header;
}

.order-item__tabla-data--last {
  @include table-data-last;
}

.order-item__numeric-data {
  @include numeric-data;
  color: $nutrien-dark-gray-2;
}

.order-item__price-unavailable {
  @include unavailable;
}
