@import "~nutrien-style-guide/src/lib/colors/_colors.scss";

.mini-cart__content {
  display: flex;
  flex-direction: column;
  opacity: 0;
  padding-top: 24px;

  transition: opacity 0.3s ease-in-out;

  &.active {
    opacity: 1;
  }
}

.mini-cart__no-price-container {
  border-bottom: solid 1px #979797;
  margin: 0;
  padding: 12px 0px 16px 32px;
}

.mini-cart__no-price-message {
  width: 216px;
  font-size: 14px;
  line-height: normal;
  color: #222222;
}

.mini-cart__content__inner-wrapper {
  display: flex;
}

.mini-cart__blurb {
  color: #000;
  font-size: 1rem;
  font-weight: 600;
  width: 210px;
}

// !TEXT-------------------------------------------------------------

.mini-cart__highlight {
  font-weight: 600;
}

@media screen and (max-width: 480px) {
  .mini-cart__content {
    padding-top: 16px;
  }
  .mini-cart__blurb {
    font-size: 14px;
  }
  .mini-cart__no-price-container {
    padding: 0px 0px 16px 32px;
  }
  .mini-cart__no-price-message {
    width: 220px;
    font-size: 12px;
  }
}
