@import "~nutrien-style-guide/src/lib/colors/_colors.scss";
@import "../../styles/index.scss";

// !WRAPPERS------------------------------------------------------------
.conditions-content-wrapper {
  position: relative;
}

.conditions-actions-wrapper {
  display: flex;
  flex-direction: column;
  padding: 24px 20px;

  @media (min-width: 800px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.conditions-checkbox-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;

  @media (min-width: 800px) {
    justify-content: flex-start;
    width: 50%;
  }
}

.conditions-button-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;

  @media (min-width: 800px) {
    justify-content: flex-end;
    width: 50%;
  }
}

.conditions-content-modal {
  z-index: 300;
  @media (min-width: 800px) {
    .mdc-dialog__surface.mdc-dialog__surface {
      max-width: 740px;
    }
  }
}

// !TEXT------------------------------------------------------------
.conditions-content__headline {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 12px;
  width: 100%;
}

.conditions-content__alert {
  background-color: $alert--warning-1;
  color: #212529;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3;
  padding: 15px;
  margin: 0 0 15px;
}

.conditions-content__tout {
  border-bottom: 1px solid $nutrien-table-border;
  color: #212529;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3;
  margin: 0 0 15px;
  padding-bottom: 16px;
}

.conditions-content__blurb {
  color: #212529;
  font-size: 0.8rem;
  font-weight: 600;
  font-style: italic;
  line-height: 1.3;
  margin: 0px;
  padding: 12px 0px 16px 0px;
}

.conditions-content__contact {
  font-size: 1rem;
  margin: 0;
}

.conditions-checkbox {
  width: auto;
}

.conditions-checkbox__text {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 800;
  padding: 0 12px;
}

// !ICONS------------------------------------------------------------
.conditions-content__close {
  @include close-icon;

  margin: 0;
}

// !BUTTONS------------------------------------------------------------
.conditions-button {
  width: 125px;
}
