@import "~nutrien-style-guide/src/lib/colors/_colors.scss";

.payments-tab-mobile {
  &__table-container {
    display: none;
    padding-bottom: 1.5rem;
  }
}

@media (max-width: 950px) {
  .payments-tab-mobile {
    &__table-container {
      display: block;
    }

    &__no-payments {
      border: 0.0625rem solid $nutrien-grey-60;
      border-top: 0;
      display: flex;
      width: 100%;
      min-height: 9.375rem;
      align-items: center;
      justify-content: center;
      padding: 1rem;
    }
  }

  .payments-table-mobile {
    &__entry {
      padding: 1.5rem 0;
      position: relative;
      border-bottom: 0.0625rem solid $nutrien-gray-60;
    }

    &__header {
      font-weight: 600;
      margin-right: 0.625rem;
      display: inline-block;
      width: 8.125rem;
    }

    &__delete-button {
      position: absolute;
      top: 0.3125rem;
      right: 0.3125rem;
      font-weight: 600;
      background: none;
      border: none;
    }
  }
}
