@import '../../styles/index.scss';

// !WRAPPERS -------------------------------------------------------------
.logistics-page__wrapper {
  @include outer-cart-page-wrapper;
}

// ! FOOTER -------------------------------------------------------------
.logistics-page__footer {
  @include cart-footer;
}
