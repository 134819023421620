@import "~nutrien-style-guide/src/lib/colors/_colors.scss";

.partners {
  &__hr {
    margin: 20px 0px;
  }
  &__header {
    margin-bottom: 2rem;
  }
  &__subheader {
    color: $nutrien-dark-grey-1;
    font-weight: 400;
    margin-bottom: 1em;
  }
  &__content-container {
    display: flex;
    flex-wrap: wrap;
    &__left {
      padding-right: 2em;
    }
    &__right {
      padding: 2em 0;
    }
    &__left,
    &__right {
      width: 50%;
      max-width: 500px;

      h3 {
        font-size: 1.75rem;
        line-height: 2.1875rem;
      }

      @media (max-width: 768px) {
        width: 100%;
        max-width: 100%;
        padding: 0;
      }
    }
  }
  &__banner-image {
    width: 100%;
    margin-bottom: 1em;
  }
  &__download-button-container {
    margin: 2em 0;
    & img {
      width: 140px;
      &:first-of-type {
        margin-right: 1.6rem;
      }
    }
  }
}
